app.controller('SelfServiceFormCtrl', function($scope, Collection, $http, SuccessMessage, ErrorMessage, InfoMessage, $timeout, SidePanel, API_ENDPOINT, Session){

  var lookId = $scope.$parent.$parent.look._id;
  var Look = Collection('looks').Member(lookId);

  $scope.downloading = false;
  $scope.mediaDownloadUrl = `${API_ENDPOINT}/looks/${$scope.$parent.$parent.look._id}/reportMediaDownload?all=1`;

  $scope.canDownloadAllMedia = _.intersection(Session.user.roles, ['qa', 'csr', 'god']).length > 0;


  Look.get().then(function(look){
    $scope.lookObj = look.data;
    var selfServiceForm = _.get($scope.lookObj, 'forms.selfService', {});
    _.set($scope.lookObj, 'forms.selfService', selfServiceForm);
    $scope.isConvertible = _.get($scope, 'lookObj.schema.selfService.conversion') ? true : false;
    $timeout(function(){
      $scope.$broadcast('render');
    });
  });

  var uploadFiles = function (callback) {
    var uploadCount = 0;
    var uploadDone = 0;
    InfoMessage.open('Uploading...');
    $scope.$on('uploadStarted', function () {
      uploadCount++;
      InfoMessage.open('Uploaded ' + uploadDone + '/' + uploadCount + '...');
    })
    $scope.$on('uploadDone', function () {
      uploadDone++;
      if (uploadCount == uploadDone) callback();
    })

    $scope.$broadcast('upload');

    if (uploadCount == 0) callback();
  }

  $scope.downloadAllMedia = function () {
    $scope.downloading = true;
    $http.get($scope.mediaDownloadUrl).then(result => {
      SuccessMessage.open(result.data.message);
    }).catch(err => {
      console.log(err);
      ErrorMessage.open(err.data.message);
    }).finally(() => {
      $scope.downloading = false;
    });
  }

  $scope.saveSelfService = function () {
    uploadFiles(function () {
      Look.Field('forms.selfService').update($scope.lookObj.forms.selfService).success(function () {
        SuccessMessage.open('Successfully updated Look.')
      }).error(function (data) {
        data.message = data.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(data.message);
      });
    })
  };

  $scope.openSelfServiceConversionPrompt = function() {
    SidePanel.open('Send to Looker', 'SelfServiceConversionCtrl', 'partial/Look/SelfServiceConversion/template.html');
  }
});
