(function () {
  'use strict'

  angular
    .module('OMSAdmin')
    .factory('TasksList', function ($http) {
      var tasks = [];
      return {
        get: function (lookId) {
          return $http.get(`${API_ENDPOINT}/tasks?entity_look_id=${encodeURIComponent(lookId)}&fields=state,status,type,description,createdBy,updatedBy,updatedAt,groupQueue,assignedParty,assignedTo,dueTime,createdAt,history.status`).then(function(response){
            
            tasks = response.data;
            return response;
          });
        },
        post: function (task) {
          return $http.post(`${API_ENDPOINT}/tasks`, task).then(function(response){
            tasks.push(response.data);
            return response;
          });
        },
      }
    })
})();
