(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .directive('loader', function() {
      return {
        restrict: 'E',
        scope: {
          ready: "="
        },
        templateUrl: "/common/directives/templates/Loader.html",
      }
    })

})();
