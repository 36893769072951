app.service('SchemaHelper', function(){

  this.pluckGroup = function(collection, obj) {
    var itemPos = collection.indexOf(obj);
    if (itemPos >= 0) {
      collection.splice(itemPos, 1)
    }
    return obj;
  };
  this.pluckType = function(collection, type, formType) {
    var objIndex;
    var obj;
    angular.forEach(collection, function(field, index){
      if(field.forms && field.forms[formType] && field.forms[formType].type === type) {
        obj = field;
        objIndex = index;
      }
    });
    if (objIndex !== undefined) {
      collection.splice(objIndex, 1);
    }
    return obj;
  };

});
