app
.service('Session', function (ACTIVE_VIEWERS_ENDPOINT, ErrorMessage) {
  this.create = function (sessionData) {
    this.id = sessionData.sessionId;
    this.token = sessionData.token;
    this.user = sessionData.user;
    this.socket = new Socket(ACTIVE_VIEWERS_ENDPOINT + "/socket", {params: {name: `${this.user.firstName} ${this.user.lastName}`, _id: this.user._id }})
  };
  this.destroy = function () {
    this.id = null;
    this.token = null;
    this.user = null;
  };
})
