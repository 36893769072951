function dateInTimezone(value, timezone) {
  var dateInLookTimezone = moment().tz(timezone);
  dateInLookTimezone.set('year', value.getFullYear());
  dateInLookTimezone.set('month', value.getMonth());
  dateInLookTimezone.set('date', value.getDate());
  dateInLookTimezone.set('hour', value.getHours());
  dateInLookTimezone.set('minute', value.getMinutes());
  dateInLookTimezone.set('second', 0);
  dateInLookTimezone.set('millisecond', 0);
  return dateInLookTimezone.toDate();
}

/**
 * wglDateTime
 * FOR BROWSERS WITH DATETIME-LOCAL SUPPORT
 * Transforms the datetime from the look timezone to a local date but displayed in the timezone of the look
 * A CSR should see the datetime in the look's timezone, however, datetime-local only accepts and displays a local date
 * Angular timezone model-options is in a newer version of Angular
 */
app.directive('wglDateTime', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, el, attrs, modelCtrl) {
      // conversion "view -> model"
      modelCtrl.$parsers.push(function (value) {
        return dateInTimezone(value, scope.timezoneBase);
      })

      // conversion "model -> view"
      modelCtrl.$formatters.push(function formatter(value) {
        if(!value || !scope.timezoneBase) return;
        var dateStringInLookTimezone = moment(value).tz(scope.timezoneBase).format("MM/DD/YYYY, hh:mm A");

        return moment(dateStringInLookTimezone).toDate();
      })
    }
  }
})
/**
 * wglDateTimeFallback
 * FOR BROWSERS WITHOUT DATETIME-LOCAL SUPPORT
 * Transforms the datetime from the look timezone to a string and displayed in the timezone of the look
 * A CSR should see the datetime in the look's timezone, however, datetime-local only accepts and displays a local date
 * Angular timezone model-options is in a newer version of Angular
 */
app.directive('wglDateTimeFallback', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elt, attrs, modelCtrl) {

      // conversion "view -> model"
      modelCtrl.$parsers.push(function (value) {
        if (!moment(value, "MM/DD/YYYY, HH:mm A").isValid()) return;
        var valueAsDate = moment(value, "MM/DD/YYYY, HH:mm A").toDate();
        return dateInTimezone(valueAsDate, scope.timezoneBase)
      })

      // conversion "model -> view"
      modelCtrl.$formatters.push(function formatter(value) {
        if(!value || !scope.timezoneBase) return;
        var dateStringInLookTimezone = moment(value).tz(scope.timezoneBase).format("MM/DD/YYYY, hh:mm A");

        return moment(dateStringInLookTimezone).format("MM/DD/YYYY, hh:mm A");
      })
    }
  }
})
app.directive('wglScheduleTime', function() {
  return {
    require: 'ngModel',
    restrict: 'A',
    replace: true,
    scope: {
      label: '=',
      timezoneBase: '=',
      timezoneFull: '=',
      timezoneShort: '=',
      date: '=ngModel'
    },
    templateUrl: '/app/Look/Schedule/ScheduleTimeTemplate.html',
    link: function (scope, element, attrs, modelCtrl) {
      if (!modelCtrl) return;
      var test = document.createElement('input');
      test.type = 'datetime-local';
      scope.needsFallback = (test.type === 'text');

      modelCtrl.$render = function () {
        if (!modelCtrl.$modelValue || !scope.timezoneBase) return;

        scope.dateTime = {
          date: modelCtrl.$modelValue
        };
      };

      scope.onChange = function() {
        scope.date = scope.dateTime.date;
      }
    }
  };
});
