app.service('Uploads', function ($http) {

  var put = function(path, data) {

    var config = {
      method: 'PUT',
      url: path,
      data: data[0],
      withCredentials: false,
      headers: {
        'Content-Type': data[0].type
      },
      transformRequest: function(data, headers) {
        headers()['authorization'] = undefined;
        return data;
      }
    };
    return $http(config).then(function(response) {
      return response;
    }).catch(function(error) {
      return error;
    });
  };

  return {
    put: put
  };
});
