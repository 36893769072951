app.directive('batchOrder', function (BatchOrder, API_ENDPOINT, Collection, $routeParams) {
  return {
    restrict: 'E',
    templateUrl: '/app/Order/BatchOrder.html',
    scope: {
      look: '=look'
    },
    link: function (scope, element, attrs) {
      scope.batchFile = {
        src: "",
        log: {
          records: []
        },
        timeouts: [],
        validationMessage: null,
        statusMessage: "",
        isProcessing: false,
        setProcessing: function(value) {
          this.isProcessing = value;
          scope.$applyAsync(); // Trigger the digest cycle
        }
      };

      resetBatchOrder();

      scope.$on('orderLookChange', function(event, look) {
        scope.look = look;
        resetBatchOrder();
      });

      scope.generateCSV = function() {
        var reqFieldIds = scope.fields.required.map( function(field) { return field.id });
        var csvContent = "data:text/csv;charset=utf-8," + reqFieldIds.join(",");
        var encodedUri = encodeURI(csvContent);
        BatchOrder.saveAs(encodedUri, scope.look.schema.type + '-template.csv');
      }

      scope.runBatchOrder = function() {
        scope.batchFile.validationMessage = null;
        scope.batchFile.setProcessing(true);

        BatchOrder.parseBatchUpload(scope.batchFile.src)
          .then(BatchOrder.buildLookData)
          .then(issueBatchRequests);
      }

      scope.cancelBatchOrder = function() {
        scope.batchFile.timeouts.forEach( function(timeoutId) {
          clearTimeout(timeoutId);
        });
        scope.batchFile.statusMessage = "Cancelled!";
        scope.batchFile.setProcessing(false);
      }

      scope.$watch('batchFile.src', function(newFile, oldFile) {
        runValidations(newFile);
      });

      // PRIVATE FUNCTIONS

      // Reset the required/optional field and re-run validations
      function resetBatchOrder() {
        scope.fields = BatchOrder.buildRequiredFields(scope.look.schema.fields);
        scope.deliveryOptionsField =
          scope.fields.required.filter(function(field) {
            return _.get(field, 'forms.client.type') === 'delivery-options';
          })[0];

        runValidations(scope.batchFile.src);
      }

      // Run validations to check the uploaded file
      function runValidations(file) {
        scope.batchFile.setProcessing(true);
        const deliveryOptions=scope.look.schema.deliveryOptions && scope.look.schema.deliveryOptions.options ? scope.look.schema.deliveryOptions.options: null;
          BatchOrder.validateFile(file, scope.fields.required, deliveryOptions)
          .then(function(message) {
            scope.batchFile.validationMessage = message;
            scope.batchFile.setProcessing(false);
          });
        
      }

      // Iterate through look rows and issue POST /look requests for each one
      function issueBatchRequests(lookArray) {

        var yesNo = confirm("You are about to attempt to create " + lookArray.length + " look(s). Do you wish to proceed?");

        if (yesNo) {
          scope.batchFile.log.records = [];
          scope.batchFile.statusMessage = "Please Wait...";
          var completedRequests = 0;
          var timeoutInterval = 5000;
          var timeoutIncrementor = 0;
          var batchSize = 10;

          // Split lookArray into array of (batchSize)-length arrays of looks
          var groupedLookArray =
            _.toArray(
              _.groupBy(lookArray, function(a, b){
                return Math.floor(b/batchSize);
              })
            );

          scope.batchFile.timeouts = [];

          // Issue POST /looks requests in batches.
          // (batchSize) requests every (timeoutInterval) seconds.
          groupedLookArray.forEach(function(groupedLooks) {
            scope.batchFile.timeouts.push(
              setTimeout( function() {
                groupedLooks.forEach(function(lookData) {
                  var postBody = {
                    schema: {
                      _id: scope.look.schema._id,
                      type: scope.look.schema.type
                    },
                    forms: {
                      client: lookData
                    }
                  };

                  function logError(error) {
                    scope.batchFile.log.records.push({
                      status:   "fail",
                      index:    lookArray.indexOf(lookData),
                      message:  error.message
                    });
                  }

                  function completeRequest() {
                    completedRequests++;

                    // Make sure all requests are complete
                    if(completedRequests === lookArray.length) {
                      scope.batchFile.setProcessing(false);
                      scope.batchFile.statusMessage = "Done!";
                    }
                  }

                  Collection('users').Member($routeParams.user).Collection('looks').create(postBody, {
                    headers: {
                      'Content-Type': 'application/vnd.wegolook.look.data.v1+json'
                    }
                  })
                  .success(function(data){
                    if( BatchOrder.validateBatchOrderErrors(data) ) {
                      scope.batchFile.log.records.push({
                        status: "success",
                        index:  lookArray.indexOf(lookData),
                        id:     data._id
                      });
                    }
                  })
                  .error(logError)
                  .finally(completeRequest);
                });
              }, timeoutIncrementor)
            );

            timeoutIncrementor += timeoutInterval;
          });
        } else {
          scope.batchFile.setProcessing(false);
        }
      }
    }
  };
});

// Directive to set values for file uploads
app.directive("fileread", [function () {
  return {
    scope: {
      fileread: "="
    },
    link: function (scope, element, attributes) {
      element.bind("change", function (changeEvent) {
        scope.$apply(function () {
          scope.fileread = changeEvent.target.files[0];
        });
      });
    }
  }
}]);
