app.service('LepReasons', function () {

  var lateReasons = [
    {id: 'LR-01', reason: 'Incorrect or Missing Information', dueTo: ['Client']},
    {id: 'LR-02', reason: 'Scheduling Difficulty', dueTo: ['On-Site Contact', 'Looker', 'External']},
    {id: 'LR-03', reason: 'Pre-Scheduled Beyond Due Date', dueTo: ['Client']},
    {id: 'LR-04', reason: 'Inclement Weather', dueTo: ['External']},
    {id: 'LR-05', reason: 'Delay For Requesting Documents', dueTo: ['Client', 'External']},
    {id: 'LR-06', reason: 'Return Trip Required', dueTo: ['Looker','Client', 'WeGoLook']},
    {id: 'LR-07', reason: 'Asset Not at Location', dueTo: ['On-Site Contact', 'Client']},
    {id: 'LR-08', reason: 'Technical Issues', dueTo: ['WeGoLook']},
    {id: 'LR-09', reason: 'Dispatching Difficulty', dueTo: ['WeGoLook']},
    {id: 'LR-10', reason: 'Public Health Risk', dueTo: ['External']},
    {id: 'LR-11', reason: 'CAT event', dueTo: ['External']}
  ];

  var holdReasons = [
    {id: 'OHR-01', reason: 'Awaiting Requested Documents', dueTo: ['Client', 'External']},
    {id: 'OHR-02', reason: 'Unreachable Contact', dueTo: [ 'On-Site Contact']},
    {id: 'OHR-03', reason: 'Requests Cancellation', dueTo: ['On-Site Contact', 'WeGoLook']},
    {id: 'OHR-04', reason: 'Scheduling Difficulties', dueTo: [ 'On-Site Contact']},
    {id: 'OHR-05', reason: 'Asset Not at Location', dueTo: ['Client', 'On-Site Contact']},
    {id: 'OHR-06', reason: 'Incorrect or Missing Information', dueTo: ['Client']},
    {id: 'OHR-07', reason: 'Public Health Risk', dueTo: ['External']},
    {id: 'OHR-08', reason: 'CAT event', dueTo: ['External']}
  ];

  var cancelReasons = [
    {id: 'CR-01', reason: 'On-Site Contact Unresponsive', dueTo: ['On-Site Contact']},
    {id: 'CR-02', reason: 'Incorrect or Missing Order Information', dueTo: ['Client', 'On-Site Contact', 'External']},
    {id: 'CR-03', reason: 'On-Site Contact Refused to Schedule', dueTo: ['On-Site Contact']},
    {id: 'CR-04', reason: 'Incorrect Look Type Selected', dueTo: ['Client']},
    {id: 'CR-05', reason: 'Duplicate Look/Order', dueTo: ['Client', 'WeGoLook']},
    {id: 'CR-06', reason: 'Appointment Missed', dueTo: ['On-Site Contact', 'Looker']},
    {id: 'CR-07', reason: 'Request Beyond Scope of Services', dueTo: ['Client']},
    {id: 'CR-08', reason: 'Ordered by Mistake', dueTo: ['Client', 'WeGoLook']},
    {id: 'CR-09', reason: 'Service No Longer Needed', dueTo: ['Client', 'On-Site Contact']},
    {id: 'CR-10', reason: 'Turnaround Delay', dueTo: ['Looker', 'WeGoLook', 'On-Site Contact', 'External']},
    {id: 'CR-11', reason: 'Denied Access', dueTo: ['On-Site Contact', 'External']},
    {id: 'CR-12', reason: 'Inclement Weather', dueTo: ['External']},
    {id: 'CR-13', reason: 'Test Order', dueTo: ['WeGoLook']}
  ];

  var unassignReasons = [
    {id: 'UR-01', reason: 'Asset Access Issues', dueTo: ['External']},
    {id: 'UR-03', reason: 'Failed MVR Check', dueTo: ['External']},
    {id: 'UR-04', reason: 'Incorrect or Missing Information', dueTo: ['Client', 'On-Site Contact, WeGoLook']},
    {id: 'UR-05', reason: 'Inclement Weather', dueTo: ['External']},
    {id: 'UR-06', reason: 'No longer wants to perform Look', dueTo: ['Looker']},
    {id: 'UR-07', reason: 'No Showed Appointment', dueTo: ['Looker', 'On-Site Contact']},
    {id: 'UR-09', reason: 'Public Health Risk', dueTo: ['External']},
    {id: 'UR-11', reason: 'Requesting additional fees ', dueTo: ['Looker']},
    {id: 'UR-12', reason: 'Requested New Looker', dueTo: ['On-Site Contact', 'Client']},
    {id: 'UR-13', reason: 'Return Trip Required (Unassigning & Reassigning)', dueTo: ['Looker', 'On-Site Contact', 'External', 'Client', 'WeGoLook']},
    {id: 'UR-14', reason: 'Return Trip Required (assigned Looker unwilling/unavailable) ', dueTo: ['Looker', 'On-Site Contact', 'External', 'Client', 'WeGoLook']},
    {id: 'UR-15', reason: 'Scheduling Difficulty', dueTo: ['On-Site Contact']},
    {id: 'UR-16', reason: 'Technical/App Difficulties', dueTo: ['WeGoLook']},
    {id: 'UR-17', reason: 'Unassigning WeGoLook Profile', dueTo: [ 'WeGoLook']},
    {id: 'UR-18', reason: 'Unresponsive', dueTo: ['Looker', 'On-Site Contact']},
  ];
  
  var dueTo = [
    {id: "client", value: "Client"},
    {id: "onsitecontact", value: "On-Site Contact"},
    {id: "looker", value: "Looker"},
    {id: "wegolook", value: "WeGoLook"},
    {id: "external", value: "External"},
  ]
  var selfServiceConversionReasons = [
    {id: "client", reason: "Client"},
    {id: "onsitecontact", reason: "On-Site Contact"},
    {id: "looker", reason: "Looker"},
    {id: "wegolook", reason: "WeGoLook"},
    {id: "external", reason: "External"},
  ]

  return {
    late: lateReasons,
    hold: holdReasons,
    cancel: cancelReasons,
    unassign: unassignReasons,
    dueTo: dueTo,
    selfServiceConversionReasons: selfServiceConversionReasons
  };
});
