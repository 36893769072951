app.controller('LookersCtrl', function($scope, Users, $location){
  $scope.results = [];
  $scope.filter = {};
  angular.extend($scope.filter, $location.search());

  $scope.search = function(){
    $location.search($scope.filter);
    var filterClone = angular.copy($scope.filter);
    filterClone.role = 'looker';
    filterClone.limit = '20';
    Users.search(filterClone, function(data){
      $scope.results = data;
    });
  };

  $scope.search();
})
