app.controller('LookerFormCtrl', function ($scope, Collection, $location, $http,  $interval, SuccessMessage, ErrorMessage, InfoMessage, $timeout, $window, API_ENDPOINT, Session) {

  var lookId = $scope.$parent.$parent.look._id;
  var Look = Collection('looks').Member(lookId);
  $scope.downloading = false;
  $scope.mediaDownloadUrl = `${API_ENDPOINT}/looks/${$scope.$parent.$parent.look._id}/reportMediaDownload?all=1`;

  $scope.canDownloadAllMedia = _.intersection(Session.user.roles, ['qa', 'csr', 'god']).length > 0;

  Look.get().then(function (look) {
    $scope.lookObj = look.data;
    var lookerForm = _.get($scope.lookObj, 'forms.looker', {});
    _.set($scope.lookObj, 'forms.looker', lookerForm);
    $timeout(function () {
      $scope.$broadcast('render');
    });
  });
  $scope.rebuildReport = function(){
    $http.post(`${API_ENDPOINT}/looks/${$scope.$parent.$parent.look._id}/reports/rebuild`).success(function(){
      SuccessMessage.open("PDF Reports rebuilt and re-delivered.");
    }).error(function (data){
      ErrorMessage.open(`${data.error}: ${data.message}`);
    })
  }
    $scope.showDrafts = function () {
    $window.open('#' + $location.url() + '/drafts', '_blank');
  }
   $scope.IsVisible = false;
            $scope.ShowHide = function () {
                $scope.IsVisible = $scope.IsVisible ? false : true;
            }
    $scope.isFinalReviewer = function () {
    return Session.user.roles.indexOf("final reviewer") !== -1;
  }

  $scope.showPopUp = function () {
    $scope.IsVisible = true;
  }
  $scope.isFRC = $scope.look.status === "Final Review Complete" ? true : false;
  $scope.removeLookerData = function () {
    $http.delete(`${API_ENDPOINT}/looks/${$scope.$parent.$parent.look._id}/forms/looker`).success(function () {
      SuccessMessage.open("Looker Data Removed.");
      $interval(function () {
        window.location.reload();
      }, 2000);
      $scope.IsVisible = false;
    }).error(function (data) {
      ErrorMessage.open(`${data.error}: ${data.message}`);
    })
  }

  var uploadFiles = function (callback) {
    var uploadCount = 0;
    var uploadDone = 0;
    InfoMessage.open('Uploading...');
    $scope.$on('uploadStarted', function () {
      uploadCount++;
      InfoMessage.open('Uploaded ' + uploadDone + '/' + uploadCount + '...');
    })
    $scope.$on('uploadDone', function () {
      uploadDone++;
      if (uploadCount == uploadDone) callback();
    })

    $scope.$broadcast('upload');

    if (uploadCount == 0) callback();
  }
  $scope.downloadAllMedia = function () {
    $scope.downloading = true;
    $http.get($scope.mediaDownloadUrl).then(result => {
      SuccessMessage.open(result.data.message);
    }).catch(err => {
      console.log(err);
      ErrorMessage.open(err.data.message);
    }).finally(() => {
      $scope.downloading = false;
    });
  }


  $scope.saveLooker = function () {
    uploadFiles(function () {
      Look.Field('forms.looker').update($scope.lookObj.forms.looker).success(function () {
        SuccessMessage.open('Successfully updated Look.')
      }).error(function (data) {
        data.message = data.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(data.message);
      });
    });
  };
});
