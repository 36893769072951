app.service('TemplateHelper', function () {
  function getPriorityColor(dueDate){
    if(!dueDate || angular.isUndefined(dueDate.year) || angular.isUndefined(dueDate.month) || angular.isUndefined(dueDate.date)) return {};

    const now       = moment().tz('America/Chicago').startOf('day');
    const reportDue = moment.tz(dueDate, 'America/Chicago');

    if (reportDue.isBetween(now - 1, moment(now).add(1, 'days') + 1)) {
      // if due date is today or tomorrow, inclusive by 1 second
      return 'low';
    } else if (reportDue.isBefore(now)) {
      // If due date is before today
      return 'high';
    }

    return;
  }

  return {
    getPriorityColor: getPriorityColor
  };
});
