app.controller('CISCtrl', function($scope, $http, ErrorMessage, SuccessMessage, PhotoEditor){
    $scope.jobId = '';
    $scope.results = [];
    $scope.isLoading = false;
    $scope.openImgKey = '';

    $scope.search = function(){
      $scope.results = [];
      $scope.isLoading = true;
      $http.get(`/cisPhotos/${$scope.jobId}`).then(function (response) {
        const res = response.data.result;
        if (res) {
          res.forEach((obj) => {
            $scope.getSignedUrl(obj.Key);
          });
        }
        $scope.isLoading = false;
      })
      .catch(function(error){
          $scope.isLoading = false;
          return ErrorMessage.open('Something went wrong!');
      });
    };

    $scope.getSignedUrl = function(key, type = 1) {
      $http
      .get(`/signedImgUrl?objKey=${key}&type=${type}`).then(function (response) {
        const res = response.data.result;
        if (type === 1) {
          $scope.results.push({
            url: res,
            key: key
          });
        } else {
          PhotoEditor.openEditor(res, $scope.onSave);
        }
        $scope.isLoading = false;
      })
      .catch(function(error){
        return ErrorMessage.open('Something went wrong!');
      });
    }

    $scope.openEditor = function(objKey) { 
      $scope.openImgKey = objKey.replace('600w/', '');
      $scope.isLoading = true;
      $scope.getSignedUrl($scope.openImgKey, 2);
    }

    $scope.onSave = function (files) {
      let formData = new FormData();
      formData.append('objKey', $scope.openImgKey);
      formData.append('files', files, 'image.png');
      let config = {
        url: '/uploadImg',
        headers: {"Content-Type": undefined },
        data: formData,
        method: 'POST'
      }
      $http(config).then(function (response) {
        const res = response.data.result;
        if (res) {
          $scope.openImgKey = '';
          $scope.search();
          SuccessMessage.open(`Image updated successfully.`);
        }
      })
      .catch(function(error){
          return ErrorMessage.open('A problem occurred while uploading the image.');
      });
    }

})
  