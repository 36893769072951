(function(){
  'use strict';
 
  angular
      .module('OMSAdmin')
      .directive('formatSchedule', function() {
 
          return {
              restrict: 'E',
              scope: {
                  'schedule': '='
              },
              templateUrl: "/common/directives/templates/FormattedSchedule.html",
              link: function(scope, element, attr) {

                  scope.schedule.reason.category = scope.schedule.reason.category;
                  scope.schedule.reason.dueTo = scope.schedule.reason.dueTo;
                  scope.schedule.reason.lookerDisplay = scope.schedule.reason.lookerDisplay;
                  scope.schedule.reason.explanation = scope.schedule.reason.explanation;
 
              }
          }  
      })
 
 })();
