app.directive('wglCsrLookRow', function(TemplateHelper, JobNumber) {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      look: '='
    },
    templateUrl: '/app/CSR/CSRLookRowTemplate.html',
    link: function (scope, element, attrs) {
      scope.jobNumber = JobNumber.format(scope.look.jobNumber);
      scope.companyName = _.get(scope.look, 'company.name', '');
      scope.schemaName = _.get(scope.look, 'schema.name', '');
      scope.city = _.get(scope.look, 'normalizedLookAddress.city', '');
      scope.state = _.get(scope.look, 'normalizedLookAddress.state', '');
      scope.orderDate = _.get(scope.look, 'forms.client.createdAt', '');
      scope.note = note(scope.look);
      scope.deliveryOptions = deliveryOptions(scope.look);
      scope.wglDue = dueDate('report', scope.look);
      scope.lookerDue = dueDate('looker', scope.look);
      scope.solicitationHighlight = solicitationHighlight(scope.look);
      scope.priorityColor = TemplateHelper.getPriorityColor(_.get(scope.look, 'dueDates.report', ''));
      scope.verificationClass = "";
      if (scope.look.selfService) {
        scope.fullName = _.get(scope.look, 'actors.selfService.name', 'Self Service');
        scope.scheduledTime = 'Self Service';
      } else {
        scope.firstName = _.get(scope.look, 'actors.looker.firstName', '');
        scope.lastName = _.get(scope.look, 'actors.looker.lastName', '');
        scope.fullName = _.trim(`${scope.firstName} ${scope.lastName}`);
        scope.scheduledTime = scheduledTime(scope.look);
      }

      scope.checkSnooze = function(look) {
        const snooze = _.get(look, "snooze");
        return snooze && moment(snooze.expireAt).diff(moment()) > 0
      }

      function note(look) {
        return _.last(look.notes);
      }

      function deliveryOptions(look) {
        var isRush = look.keywords && look.keywords.indexOf('rushdelivery') !== -1;

        if (isRush) {
          return 'express';
        }
        return 'standard';
      }

      function dueDate(property, look) {
        const dateObj = _.get(look, `dueDates.${property}`);

        if (!dateObj) {
          return '';
        }

        return moment(_.get(look, `dueDates.${property}`)).toDate();
      };


      function scheduledTime(look) {
        var startTime = _.get(look, 'schedule.time.start');
        var endTime = _.get(look, 'schedule.time.end');
        var timezone = _.get(look, 'schedule.time.timezone')
        || _.get(look, 'timezone.timeZoneId')
        || 'America/Chicago';
        return !startTime
          ? 'Not Scheduled'
          : moment(startTime).tz(timezone).format('MM/DD h:mm a z') + ' - ' + moment(endTime).tz(timezone).format('MM/DD h:mm a z');
        return 'today - today';
      };

      function solicitationHighlight(look) {
        if (look.status == 'Unassigned' && look.solicitations) {
          return 'solicited';
        }
      }

      var verificationStatus = _.get(scope.look, 'verification.status');
      var orderPrepStatus = _.get(scope.look, 'orderPrep.status')
      if ((verificationStatus === 'Skipped' || verificationStatus === 'Complete') && (orderPrepStatus === 'Skipped' || orderPrepStatus === 'Complete')) {
        scope.verificationClass = 'verified';
      } else if (verificationStatus === 'Requested Change' || verificationStatus === 'Pending' || orderPrepStatus === 'Required' ) {
        scope.verificationClass = 'action-required';
      }

    }
  };
});
