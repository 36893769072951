(function () {

  // UTILITY FUNCTIONS
  var addRequired = function (label, input, scope) {
    var form = scope.field.forms[scope.form];
    if (angular.isDefined(form.validations) && form.validations.required) {
      input && input.attr('required', '');
      label && label.append(angular.element('<span>').addClass('required-field').text('*'));
    }
    if (angular.isDefined(form.validations) && angular.isDefined(form.validations.regex) && form.validations.regex) {
      scope.regex = new RegExp(form.validations.regex.pattern, form.validations.regex.options);
      input.attr('ng-pattern', 'regex');
    }
    if (angular.isDefined(form.validations) && angular.isDefined(form.validations.length) && form.validations.length) {
      scope.refLength = form.validations.length;

      var minExists = angular.isDefined(scope.refLength.min);
      var maxExists = angular.isDefined(scope.refLength.max);

      if (minExists) {
        input.attr('ng-minlength', scope.refLength.min)
      }
      if (maxExists) {
        input.attr('ng-maxlength', scope.refLength.max)
      }
    }

    if (!scope.formData && angular.isDefined(form.defaultValue)) {
      scope.formData = form.defaultValue;
    }
  };

  var addLabel = function () {
    return angular.element("<label>").text("{{field.name}}").addClass('field-name');
  };

  var addHeader = function (name) {
    return angular.element("<div>").text(name).addClass('group-header');
  };

  var addFieldDirective = function (type, field, data, clientData) {
    var dataAttrs = {
      'form': 'form',
      'look-obj': 'lookObj',
      'field': field,
      'form-data': data
    };
    if (clientData) dataAttrs['client-data'] = clientData;

    var newField = angular.element('<div>')
      .attr('schema-render-field-' + type, '')
      .attr(dataAttrs);

    var newFieldWrapper = angular.element('<div>')
      .addClass(['Schema-field', type].join(" "))
      .append(newField)

    if(!/order/g.test(window.location.hash)){
      var toggles = angular.element('<div>')
        .attr(dataAttrs);

      newFieldWrapper.append(toggles);
    };


    return newFieldWrapper;
  };

  var addGroupDirective = function (group, data, clientData, multiple) {
    var newGroup = angular.element('<div>').addClass(multiple ? 'Schema-multiple-group' : 'Schema-group');
    newGroup.attr(multiple ? 'schema-render-multiple-group' : 'schema-render-group', '');
    newGroup.attr({
      'form': 'form',
      'look-obj': 'lookObj',
      'group': group,
      'form-data': data
    });
    if (clientData) newGroup.attr('client-data', clientData);
    return newGroup;
  };

  var fieldScope = {
    lookObj: '=',
    field: '=',
    form: '=',
    formData: '=',
    clientData: '=?'
  };

  var urlForImageVersion = function (version, url, userId) {
    const requestExpiresAt = moment().add(7, 'days').format();
    const data = {
      url: url,
      version: version,
      expireDate: requestExpiresAt,
      userId: userId
    }

    var encrytedParams = CryptoJS.AES.encrypt(JSON.stringify(data), IMAGE_URL_ENCRYPT_KEY).toString();

    return `${API_ENDPOINT}/media?metaData=${encrytedParams}`;
  }

  // SCHEMA BASED DIRECTIVES
  app.directive('schemaRender', function ($compile, Looks) {

    var rejectSchemaField = function (fields, type) {
      return _.map(_.reject(fields, function (field) {
        if (field.fields) {
          return false;
        }
        return typeof field.forms[type] == 'undefined';
      }), function (field) {
        if (field.fields) {
          field.fields = rejectSchemaField(field.fields, type);
        }
        return field;
      });
    };
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        lookData: '=',
        fields: '=',
        form: '@'
      },
      link: function (scope, element, attrs) {

        var renderSchema = function(event, fields){

          var docFrag = angular.element('<div>').addClass("Schema");
          scope.fields = rejectSchemaField(fields ? fields : scope.fields, scope.form);
          angular.forEach(scope.fields, function (field, index) {
            if (field.type == "group") {
              if (!field.fields.length) return;
              var newGroup = addGroupDirective("fields[" + index + "]",
                "lookData['" + field.name.replace("'", "\\'") + "']",
                "lookObj.forms['client']['" + field.name.replace("'", "\\'") + "']", field.multiple)
              var newGroupHeader = addHeader("{{fields[" + index + "].name}}");
              docFrag.append(newGroupHeader, newGroup);
            }else if(field.id === 'due_date'){
              var newGroupHeader = addHeader("{{fields[" + index + "].name}}");
              docFrag.append(newGroupHeader, newGroup);

              docFrag.append(addFieldDirective(field.forms[scope.form].type, "fields[" + index + "]",
              "lookData['" + field.name.replace("'", "\\'") + "']",
              "lookObj.forms['client']['" + field.name.replace("'", "\\'") + "']"));

            } else {
              docFrag.append(addFieldDirective(field.forms[scope.form].type, "fields[" + index + "]",
                "lookData['" + field.name.replace("'", "\\'") + "']",
                "lookObj.forms['client']['" + field.name.replace("'", "\\'") + "']"));
            }
          });
          $compile(docFrag)(scope);
          element.html(docFrag);
        };

        renderSchema();

        scope.$on('render', renderSchema);

        // }, true)
      }
    }
  });


  app.directive('schemaRenderGroup', function ($compile) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?'
      },
      link: function (scope, element, attrs) {

        var docFrag = angular.element('<div>').addClass(["Schema-group-container", scope.group.groupType].join(" "));
        docFrag.attr('schema-render-group-' + scope.group.groupType, '').attr({
          'look-obj': 'lookObj',
          group: 'group',
          form: 'form',
          'form-data': 'formData',
          'client-data': 'clientData'
        });
        angular.forEach(scope.group.fields, function (field, index) {
          if (field.type == "group") {
            if (!field.fields.length) return;
            var newGroup = addGroupDirective('group.fields[' + index + ']',
              'formData["' + field.name.replace("'", "\\'") + '"]',
              'clientData["' + field.name.replace("'", "\\'") + '"]', field.multiple);
            var newGroupHeader = addHeader('{{group.fields[' + index + '].name}}');
            docFrag.append(newGroupHeader, newGroup);
          } else if (field.forms[scope.form].type === 'titled-photo') {
            // iterates through all formData grabbing the title of any that have a container property that matches the field.name
            // this works because the container name is the same as the field.name
            var allMatchingFormDataNames = _.uniq(_.map(_.flatten(_.filter(scope.formData, function(foundField) {
                return _.some(foundField, 'container', field.name);
              })), function(v) {
                return v.title;
              }));

            angular.forEach(allMatchingFormDataNames, function (formDataName, dataIndex) {
              docFrag.append(addFieldDirective(field.forms[scope.form].type, 'group.fields[' + index + ']',
                'formData["' + formDataName.replace("'", "\\'") + '"]',
                'clientData["' + formDataName.replace("'", "\\'") + '"]'));
            });

          } else {
            docFrag.append(addFieldDirective(field.forms[scope.form].type, 'group.fields[' + index + ']',
              'formData["' + field.name.replace("'", "\\'") + '"]',
              'clientData["' + field.name.replace("'", "\\'") + '"]'));
          }
        });
        $compile(docFrag)(scope);
        element.append(docFrag);
      }
    };
  });

  app.directive('schemaRenderMultipleGroup', function ($compile) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?'
      },
      link: function (scope, element, attrs) {
        var docFrag = angular.element("<div>").addClass(["Schema-group-container", scope.group.groupType].join(" "));

        var repeaterContainer = angular.element("<div>").attr({
          'sv-root': '',
          'sv-part': "formData"
        });
        var repeater = angular.element("<div>").addClass("Schema-multiple-item").attr({
          'ng-repeat': 'item in formData',
          'sv-element': ''
        });

        docFrag.attr('schema-render-group-' + scope.group.groupType, '').attr({
          look: 'look',
          group: 'group',
          form: 'form',
          'form-data': 'formData',
          'client-data': 'clientData'
        });
        var addButton = angular.element('<button>').text('Add').addClass('expand Schema-multiple-group-add').attr('ng-click', 'addData()').appendTo(docFrag);

        var deleteButton = angular.element('<a>').text('x').addClass('close-button').attr('ng-click', 'deleteData($index)').appendTo(repeater);
        angular.forEach(scope.group.fields, function (field, index) {
          if (field.type == "group") {
            var newGroup = addGroupDirective('group.fields[$index]',
              "item['" + field.name.replace("'", "\\'") + "']",
              null, field.multiple);
            var newGroupHeader = addHeader("{{group.fields[$index]['" + field.name.replace("'", "\\'") + "'].name}}");
            repeater.append(newGroupHeader, newGroup);
          } else {
            repeater.append(addFieldDirective(field.forms[scope.form].type, 'group.fields[' + index + ']', "item['" + field.name.replace("'", "\\'") + "']"));
          }
        });

        scope.addData = function () {
          if (!angular.isArray(scope.formData)) scope.formData = [];
          scope.formData.unshift({});
        };
        scope.deleteData = function ($index) {
          if (confirm('Are you sure you want to delete this?')) scope.formData.splice($index, 1);
        };

        docFrag.append(repeaterContainer.append(repeater));
        $compile(docFrag)(scope);
        element.append(docFrag);
      }
    };
  });

  app.directive('schemaRenderGroupLookAddress', function (ErrorMessage, SuccessMessage, $compile) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?'
      },
      link: function (scope, element, attrs) {
        var geocoder = new google.maps.Geocoder();
        var debouncedGeocode = _.debounce(geocoder.geocode, 1500);

        const docFrag = angular.element('<div>').addClass('geojson-point');

        docFrag.append('<div><strong>Assignment Location:</strong>  <a href="https://www.google.com/maps/place/?q=place_id:{{place_id}}">{{formated_address}}</a></div>');
        docFrag.append();
        $compile(docFrag)(scope);
        element.append(docFrag);

        scope.$watch('formData', function (newVal) {
          var coordinates, street, city, state, zip, addressBox;
          for (var i = 0; i < scope.group.fields.length; i++) {
            var fieldName = scope.group.fields[i].name;
            var fieldType = scope.group.fields[i].forms.client.type;
            switch (fieldType) {
            case 'city':
              city = fieldName;
              break;
            case 'state':
              state = fieldName;
              break;
            case 'zip':
              zip = fieldName;
              break;
            case 'street':
              street = fieldName;
              break;
            case 'geojson-point':
              coordinates = fieldName;
              break;
            case 'address-box':
              addressBox = fieldName;
              break;
            }
          }

          if (scope.formData) {    
            var address;
            if(addressBox !== undefined) {
              // single address-box field
              address = scope.formData[addressBox];
            } else {
              address = [
                scope.formData[street],
                scope.formData[city],
                scope.formData[state],
                scope.formData[zip],
              ].join(' ');
            }
            debouncedGeocode({
              address: address

            }, function (results, status) {
              if (status == google.maps.GeocoderStatus.OK && results && results.length) {
                scope.formData[coordinates] = {
                  type: 'Point',
                  coordinates: [results[0].geometry.location.lng(), results[0].geometry.location.lat()]
                }               
                scope.formated_address = results[0].formatted_address ;
                
                scope.place_id = results[0].place_id;

                
                SuccessMessage.open('Address found. Please click Update to save.');
              } else {
                ErrorMessage.open('Address not found. Previous coordinates will be retained.');
              }
            });

          }
        }, true);
      }
    };
  });

  app.directive('googleplace', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, model) {
        var options = {
          types: ['geocode'],
          fields: ['address_components', 'formatted_address', 'geometry']
        };

        scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

        google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
          scope.$apply(function() {
              model.$setViewValue(element.val());
          });
        });
      }
    };
  });

  app.directive('schemaRenderFieldAddressBox', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
         var input = angular.element("<input googleplace></input>");
        input.attr({
          "ng-model": "formData",
          'type': 'text',
          'name': scope.field.name
        });

        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope)

        element.append(label, input);

        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });
  
  app.directive('schemaRenderFieldGeojsonPoint', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        scope.formData.coordinates = [ scope.formData.coordinates[0], scope.formData.coordinates[1]];
        var label = addLabel();
        var textarea = angular.element("<input>In Order: [LONGITUDE, LATITUDE]</input>");
        textarea.attr({
          "ng-model": "formData.coordinates",
          "name": scope.field.name,
          "type": "text",
          "disabled": "true"
        });
        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, textarea, scope)

        element.append(label, textarea);

        $compile(label)(scope);
        $compile(textarea)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldPhoto', function ($compile, $http, $timeout, $upload, $timeout, Uploads, PhotoEditor, Session) {

    return {
      restrict: 'A',
      scope: fieldScope,
      template: '<button class="expand tiny button-space bottom-space" ng-click="openEditor(this);">Edit</button>',
      link: function (scope, element, attrs) {

        scope.onSave = (dataFile) => {
          var url = API_ENDPOINT + '/looks/' + scope.lookObj._id + '/forms/' + scope.form + '/uploads';
          $http.post(url, { fileName: PhotoEditor.getFileName(scope.formData) }).then(function(postResponse) {
            Uploads.put(postResponse.data.signedUrl, [dataFile])
            .then(function(response) {
              scope.formData = postResponse.data.baseUrl;
              scope.compressed = null;
              scope.refreshImage(10000);
            })
            .catch(function(error) {
              return ErrorMessage.open('A problem occurred while saving photo.');
            });
          }, function(error) {
            return ErrorMessage.open('A problem occurred while preparing upload.');
          });
        }

        scope.openEditor = (event) => {
          PhotoEditor.openEditor(scope.formData, scope.onSave, Session.user._id)
        }

        var label = addLabel();

        var input = angular.element("<input>");
        input.addClass(scope.field.forms[scope.form].type);
        input.attr({
          "type": "file",
          "ng-file-select": "",
          "accept": "image/*",
          "ng-model": "dataFile",
          'name': scope.field.name
        });

        var fileContainer = angular.element("<div>").addClass('fileContainer');
        fileContainer.attr('ng-class', '{ fileEmpty: !formData && !dataFile,  filePending: !!dataFile }');

        input.on('dragenter', function () {
          element.addClass('Dragging');
        });

        input.on('drop', function () {
          element.removeClass('Dragging');
        });

        input.on('dragleave', function () {
          element.removeClass('Dragging');
        });
        input.on('change', function(){
          scope.$apply();
          $timeout(function(){
            if(scope.dataFile) scope.uploadPhoto();
          }, 100);
        });
        scope.uploadPhoto = function(){
          var url = API_ENDPOINT + '/looks/' + scope.lookObj._id + '/forms/' + scope.form + '/uploads';
          var postData = { fileName: scope.dataFile[0].name };
          $http.post(url, postData).then(function(postResponse) {
            Uploads.put(postResponse.data.signedUrl, scope.dataFile)
            .then(function(response) {
              scope.formData = postResponse.data.baseUrl;
              scope.dataFile = null;
              scope.compressed = null;
              scope.refreshImage(10000);
            }).catch(function(error) {
              return ErrorMessage.open('A problem occurred while saving photo.');
            });
          }, function(error) {
            return ErrorMessage.open('A problem occurred while preparing upload.');
          });
        };
        scope.refreshImage = function (delay) {
          if (delay) {
            scope.compressed = 'img/spin.gif';
            $timeout(function () {
              scope.compressed = (urlForImageVersion('600w', scope.formData, Session.user._id) || urlForImageVersion('600w', scope.clientData, Session.user._id));
            }, delay);
          } else {
            scope.compressed = (urlForImageVersion('600w', scope.formData, Session.user._id) || urlForImageVersion('600w', scope.clientData, Session.user._id));
          }
        };

        if (scope.clientData || scope.formData) {
          scope.refreshImage();
        }

        var img = angular.element("<a>");
        img.on('dragenter', function () {
          element.addClass('Dragging');
        });
        img.addClass("imageDisplay").attr({
          'ng-show': 'compressed && !dataFile',
          'ng-click': 'openEditor($event);',
          'style': 'background-image:url({{compressed}});'
        });

        addRequired(label, input, scope);

        fileContainer.append(input);
        element.before(label, img, fileContainer);
        $compile(label)(scope);
        $compile(img)(scope);
        $compile(fileContainer)(scope);

      }
    };
  });

  app.directive('schemaRenderFieldChoice', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs, ngModel) {
        var label = addLabel();
        var select = angular.element("<select>");
        var firstValue = scope.field.forms[scope.form].options[0];
        select.attr({
          'name': scope.field.name,
          "ng-model": "formData",
          "ng-options": (typeof firstValue == 'string') ? "s as s for s in field.forms['" + scope.form + "'].options" : "option.key as option.value for option in field.forms['" + scope.form + "'].options"
        });

        select.bind("keyup", function () {
          if (select.val()[0] != "?") {
            var options = scope.field.forms[scope.form].options;
            var currentSelection = options[select.val()];
            scope.formData = currentSelection;
            scope.$apply();
          }
        });

        addRequired(label, select, scope)

        element.append(label, select);
        $compile(label)(scope);
        $compile(select)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldBoolean', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs, ngModel) {

        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          'name': scope.field.name,
          'type': 'checkbox',
          "ng-model": "formData"
        }).addClass(scope.field.forms[scope.form].type);

        addRequired(label, input, scope)

        if (angular.isUndefined(scope.formData)) {
          scope.formData = scope.field.forms.client.defaultValue || false;
        }

        label.prepend(input);
        element.append(label);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldMultiLine', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var textarea = angular.element("<textarea>");
        textarea.attr({
          "ng-model": "formData",
          'name': scope.field.name
        });

        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, textarea, scope)

        element.append(label, textarea);
        $compile(label)(scope);
        $compile(textarea)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldPhotoDisplay', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attr) {
        var label = addLabel();
        var img = angular.element("<img>").attr('src', "{{clientData}}");

        element.append(label, img);
        $compile(label)(scope);
        $compile(img)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldFileDisplay', function ($compile, Session) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var link = angular.element("<a>").text("{{clientData?'View File':'No file uploaded'}}");
        if (scope.clientData) {
          scope.transformedClientData = urlForImageVersion('', scope.clientData, Session.user._id);

          link.attr({
            'href': `${transformedClientData}`,
            'target': '_blank'
          })
        }

        element.append(label, link);
        $compile(label)(scope);
        $compile(link)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldMultiLineDisplay', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = angular.element("<label>").text("{{field.name}}").addClass('field-name');
        var input = angular.element("<div>").addClass(scope.field.forms[scope.form].type);
        input.text("{{clientData || 'Not Provided'}}").attr('ng-class', '{noData: !clientData}');

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });

  app.directive('schemaRenderFieldNumber', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          "ng-model": "formData",
          'type': 'number',
          'name': scope.field.name
        });

        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });
  app.directive('schemaRenderFieldEmail', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          "ng-model": "formData",
          'type': 'email',
          'name': scope.field.name
        });

        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });
  app.directive('schemaRenderFieldPhone', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          "ng-model": "formData",
          'type': 'tel',
          'name': scope.field.name
        });

        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  });
  app.directive('schemaRenderFieldPhoneDisplay', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var link = angular.element("<a>").text("{{clientData || 'Not Provided'}}").attr('ng-class', '{noData: !clientData}');
        if (scope.clientData) link.attr('href', "tel:{{clientData}}");

        element.append(label, link);
        $compile(label)(scope);
        $compile(link)(scope);
      }
    };
  });
  app.directive('schemaRenderFieldDeliveryOptions', function (Collection, $compile, $http, $routeParams, $templateCache) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        $http.get('/common/directives/templates/DeliveryOptions.html', {
          cache: $templateCache
        }).success(function (template) {
          var templateEl = angular.element(template);
          scope.setOption = function (value) {
            scope.formData = value;
            scope.hours = value;
          };

          scope.firstPriceObject = function(option) {
            return option.price[Object.keys(option.price)[0]];
          };

          scope.isNumber = angular.isNumber;

          scope.hours = scope.formData;

          element.addClass("columns medium-12");

          $compile(templateEl)(scope);
          angular.element(element).html(templateEl);
        });
      }
    }
  });
  app.directive('schemaRenderFieldEmailDisplay', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var link = angular.element("<a>").text("{{clientData || 'Not Provided'}}").attr('ng-class', '{noData: !clientData}');
        if (scope.clientData) link.attr('href', "mailto:{{clientData}}");

        element.append(label, link);
        $compile(label)(scope);
        $compile(link)(scope);
      }
    };
  });
  app.directive('schemaRenderFieldState', function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var select = angular.element("<select>");
        select.attr({
          'name': scope.field.name,
          'ng-model': 'formData',
          "ng-options": "state.key as state.value for state in field.forms['" + scope.form + "'].options | orderBy:'key'"
        });

        select.bind("keyup", function () {
          if (select.val()[0] != "?") {
            var optionsArray = scope.field.forms[scope.form].options;
            var currentSelection = optionsArray[select.val()];
            scope.formData = currentSelection.key;
            scope.$apply();
          }
        });

        addRequired(label, select, scope);

        element.append(label, select);
        $compile(label)(scope);
        $compile(select)(scope);
      }
    };
  });

  const photoWithNote = function ($timeout, $upload, $http, ErrorMessage, Uploads, PhotoEditor, Session) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?',
        field: '='
      },
      templateUrl: "/common/directives/templates/PhotoWithNote.html",
      link: function(scope, element, attr) {

        scope.type = scope.field.forms[scope.form].type;
        scope.activeImage = 0;

        var form = scope.field.forms[scope.form];
        if (angular.isDefined(form.validations) && form.validations.required) {
          scope.isRequired = true;
          var input = angular.element(element[0].getElementsByClassName('photoWithNote')[0]);

          if (input) {
            input.attr('required', '');
          }
        }

        if (!scope.formData) {
          scope.formData = [];
        }

        if (scope.formData && scope.formData[0] && scope.formData[0].photo) {
          scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
        }

        if (scope.formData.length > 0) {
          scope.galleryImages = scope.formData.map(function(item, id) {
            const transformedItem = {
              photo: urlForImageVersion('600w', item.photo, Session.user._id),
              id: id
            }
            transformedItem.note = item.note;

            return transformedItem;
          });
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
        } else {
          scope.galleryImages = [];
        }

        scope.onSave = (dataFile) => {
          var url = API_ENDPOINT + '/looks/' + scope.lookObj._id + '/forms/' + scope.form + '/uploads';
          $http.post(url, { fileName: PhotoEditor.getFileName(scope.formData[0].photo) }).then(function(postResponse) {
            Uploads.put(postResponse.data.signedUrl, [dataFile])
            .then(function(response) {
              scope.formData[scope.activeImage].photo = postResponse.data.baseUrl;
              scope.compressed = null;
              scope.refreshImages(10000);
            })
            .catch(function(error) {
              return ErrorMessage.open('A problem occurred while saving photo.');
            });
          }, function(error) {
            return ErrorMessage.open('A problem occurred while preparing upload.');
          });
        }

        scope.openEditor = () => {
            PhotoEditor.openEditor(scope.formData[scope.activeImage].photo, scope.onSave, Session.user._id)
        }

        scope.handleChange = function() {
          scope.galleryImages[scope.activeImage].note = scope.currentImageText;
          scope.formData[scope.activeImage].note = scope.currentImageText;
        }

        scope.gallery = false;

        scope.openGallery = function(){
          scope.activeImage = 0;
          scope.gallery = true;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
        };

        scope.closeGallery = function() {
          scope.gallery = false;
        };

        scope.previousImage = function() {
          scope.activeImage--;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
        };

        scope.nextImage = function() {
          scope.activeImage++;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
        };

        scope.photoUploading = false;

        scope.addImage = function(event) {
          uploadPhoto(event.target.files);
        };

        function uploadPhoto(files) {
          const url = `${API_ENDPOINT}/looks/${scope.lookObj._id}/forms/${scope.form}/uploads`;
          const postData = {
            fileName: files[0].name
          };
          scope.photoUploading = true;
          $http.post(url, postData)
            .then(function(postResponse) {
              Uploads.put(postResponse.data.signedUrl, files)
              .then(function(putResponse) {
                scope.formData.push({photo: postResponse.data.baseUrl});
                scope.refreshImages(3000, function() {
                  if (scope.gallery) {
                    scope.activeImage++;
                    scope.currentImageText = scope.galleryImages[scope.activeImage].note;
                  }
                  scope.photoUploading = false;
                });
              })
              .catch(function(err){
                return ErrorMessage.open("A problem occurred while saving photo.");
              });
            })
            .catch(function(err) {
              return ErrorMessage.open("A problem occured while preparing upload.");
            });
        };

        scope.refreshImages = function (delay, cb) {
          if (delay) {
            scope.compressed = "img/spin.gif";
            if (!scope.galleryImages[scope.activeImage]) {
              scope.galleryImages[scope.activeImage] = {photo: "img/spin.gif"};
            } else {
              scope.galleryImages[scope.activeImage].photo = "img/spin.gif";
            }
            $timeout(function() {
              scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
              scope.galleryImages = scope.formData.map(function(item, id) {

                const transformedItem = {
                  photo: urlForImageVersion('600w', item.photo, Session.user._id),
                  id: id
                }
                transformedItem.note = scope.galleryImages[id] ? scope.galleryImages[id].note : item.note;

                return transformedItem;
              });
              if (cb){cb()};
            }, delay);
          } else {
            scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
          }
        };
      }
    };
  }

  const titledPhoto = function ($timeout, $upload, $http, ErrorMessage, Uploads, PhotoEditor, Session) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?',
        field: '='
      },
      templateUrl: "/common/directives/templates/TitledPhoto.html",
      link: function(scope, element, attr) {

        scope.type = scope.field.forms[scope.form].type;
        scope.activeImage = 0;
        scope.isDisabled = true;

        var form = scope.field.forms[scope.form];
        if (angular.isDefined(form.validations) && form.validations.required) {
          scope.isRequired = true;
          scope.isDisabled = true;
          var input = angular.element(element[0].getElementsByClassName('photoWithNote')[0]);
          input.attr('required', '');
        }

        if (!scope.formData) {
          scope.formData = [];
        }

        if (scope.formData && scope.formData[0] && scope.formData[0].photo) {
          scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
        }

        if (scope.formData.length > 0) {
          scope.galleryImages = scope.formData.map(function(item, id) {
            return {
              photo: urlForImageVersion('600w', item.photo, Session.user._id),
              id: id,
              note: item.note,
              title: item.title
            };
          });
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
          scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;
        } else {
          scope.galleryImages = [];
        }

        scope.onSave = (dataFile) => {
          var url = API_ENDPOINT + '/looks/' + scope.lookObj._id + '/forms/' + scope.form + '/uploads';
          $http.post(url, { fileName: PhotoEditor.getFileName(scope.formData[0].photo) }).then(function(postResponse) {
            Uploads.put(postResponse.data.signedUrl, [dataFile])
            .then(function(response) {
              scope.formData[scope.activeImage].photo = postResponse.data.baseUrl;
              scope.compressed = null;
              scope.refreshImages(10000);
            })
            .catch(function(error) {
              return ErrorMessage.open('A problem occurred while saving photo.');
            });
          }, function(error) {
            return ErrorMessage.open('A problem occurred while preparing upload.');
          });
        }

        scope.openEditor = (event) => {
          PhotoEditor.openEditor(scope.formData[scope.activeImage].photo, scope.onSave, Session.user._id)
        }

        scope.handleChange = function() {
          scope.galleryImages[scope.activeImage].note = scope.currentImageText;
          scope.formData[scope.activeImage].note = scope.currentImageText;
          scope.galleryImages[scope.activeImage].title = scope.currentImageTitle;
          scope.formData[scope.activeImage].title = scope.currentImageTitle;
        }

        scope.removePhoto = function() {
          if (confirm("Are you sure you want to delete this photo?")) {
            scope.galleryImages.splice(scope.activeImage, 1);
            scope.formData.splice(scope.activeImage, 1);
            if (scope.activeImage > 0) {
              scope.activeImage--;
              scope.currentImageText = scope.galleryImages[scope.activeImage].note;
              scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;
            }
            if (scope.formData.length < 1) {
              scope.compressed = undefined;
              scope.gallery = false;
            }
          }
        };

        scope.gallery = false;

        scope.openGallery = function(){
          scope.activeImage = 0;
          scope.gallery = true;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
          scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;
        };

        scope.closeGallery = function() {
          scope.gallery = false;
        };

        scope.previousImage = function() {
          scope.activeImage--;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
          scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;
        };

        scope.nextImage = function() {
          scope.activeImage++;
          scope.currentImageText = scope.galleryImages[scope.activeImage].note;
          scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;

        };

        scope.photoUploading = false;

        scope.addImage = function(event) {
          uploadPhoto(event.target.files);
        };

        function uploadPhoto(files) {
          const url = `${API_ENDPOINT}/looks/${scope.lookObj._id}/forms/${scope.form}/uploads`;
          const postData = {
            fileName: files[0].name
          };
          scope.photoUploading = true;
          $http.post(url, postData)
            .then(function(postResponse) {
              Uploads.put(postResponse.data.signedUrl, files)
              .then(function(putResponse) {
                scope.formData.push({
                  photo: postResponse.data.baseUrl,
                  title: scope.galleryImages[0].title,
                  container: scope.galleryImages[0].container
                });
                scope.refreshImages(3000, function() {
                  if (scope.gallery) {
                    scope.activeImage++;
                    scope.currentImageText = scope.galleryImages[scope.activeImage].note;
                    scope.currentImageTitle = scope.galleryImages[scope.activeImage].title;
                  }
                  scope.photoUploading = false;
                });
              })
              .catch(function(err){
                return ErrorMessage.open("A problem occurred while saving photo.");
              });
            })
            .catch(function(err) {
              return ErrorMessage.open("A problem occured while preparing upload.");
            });
        };

        scope.refreshImages = function (delay, cb) {
          if (delay) {
            scope.compressed = "img/spin.gif";
            if (!scope.galleryImages[scope.activeImage]) {
              scope.galleryImages[scope.activeImage] = {photo: "img/spin.gif"};
            } else {
              scope.galleryImages[scope.activeImage].photo = "img/spin.gif";
            }
            $timeout(function() {
              scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
              scope.galleryImages = scope.formData.map(function(item, id) {

                return {
                  photo: urlForImageVersion('600w', item.photo, Session.user._id),
                  id: id,
                  note: scope.galleryImages[id] ? scope.galleryImages[id].note : item.note,
                  title: scope.galleryImages[id] ? scope.galleryImages[id].title : item.title
                }
              });
              if (cb){cb()};
            }, delay);
          } else {
            scope.compressed = (urlForImageVersion('600w', scope.formData[0].photo, Session.user._id));
          }
        };
      }
    };
  }

  app.directive('schemaRenderFieldPhotoWithNote', photoWithNote);
  app.directive('schemaRenderFieldTitledPhoto', titledPhoto);

  var ReferenceNumber = function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          "ng-model": "formData",
          'type': 'text',
          'name': scope.field.name
        });
        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);

        var selfServiceLink = _.get(scope, 'lookObj.selfService.link.initial', false);

        if (selfServiceLink) {
          const docFrag = angular.element('<div>').addClass('dynamic-link');

          docFrag.append('<div><strong>Dynamic Link:</strong> {{lookObj.selfService.link.initial}}</div>');


          $compile(docFrag)(scope);
          element.append(docFrag);
        }
      }
    };
  };

  var dateTime=function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        const readonly = (scope.field.id === 'due_date');

        input.attr({
          "ng-model": "formData",
          'type': 'date',
          'name': scope.field.name
        });
        let date = new Date();
        if(scope.formData){
          date=new Date(scope.formData);
        }
        date.setUTCHours(0,0,0,0);
        scope.formData=date;
        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);
      }
    };
  };


  var DateTimeDisplay = function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var div = angular.element("<div>");
        scope.clientData = scope.clientData ? new Date(scope.clientData) : null;
        div.attr('ng-class', '{noData: !clientData}');
        div.text("{{clientData | date:'yyyy-MM-dd':'UTC'}}");
        element.append(label, div);
        $compile(label)(scope);
        $compile(div)(scope);
      }
    };
  };


  var DateTimeDisplay = function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var div = angular.element("<div>");
        scope.clientData=scope.clientData?new Date(scope.clientData):null;
        div.attr('ng-class', '{noData: !clientData}');
        div.text("{{clientData | date:'yyyy-MM-dd':'UTC'}}");
        element.append(label, div);
        $compile(label)(scope);
        $compile(div)(scope);
      }
    };
  };

  var SingleLine = function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        input.attr({
          "ng-model": "formData",
          'type': 'text',
          'name': scope.field.name
        });
        if (scope.field.forms[scope.form].placeholder) {
          input.attr('placeholder', scope.field.forms[scope.form].placeholder)
        }
        addRequired(label, input, scope);

        element.append(label, input);
        $compile(label)(scope);
        $compile(input)(scope);

      }
    };
  };

  var SingleLineDisplay = function ($compile) {
    return {
      restrict: 'A',
      scope: fieldScope,
      link: function (scope, element, attrs) {
        var label = addLabel();
        var div = angular.element("<div>");
        div.attr('ng-class', '{noData: !clientData}');
        div.text("{{clientData || 'Not Provided'}}");

        element.append(label, div);
        $compile(label)(scope);
        $compile(div)(scope);
      }
    };
  };

  var FileField = function ($compile, $upload, $timeout, $http, Uploads, SuccessMessage, ErrorMessage, Session) {
    return {
      restrict: 'A',
      scope:{
         lookObj: '=',
    field: '=',
    form: '=',
    formData: '=',
    clientData: '=?',
    rotation: '=?'
      },

      link: function (scope, element, attrs) {
        var label = addLabel();
        var input = angular.element("<input>");
        var dropDown = angular.element("<select class='rotate'><option value=''>ROTATE VIDEO &#9662;<option value='DEGREES_0'>0 DEGREE</option><option value='DEGREES_90'>90 DEGREE</option><option value='DEGREES_180'>180 DEGREE</option><option value='DEGREES_270'>270 DEGREE</option></select>").attr({
          'ng-show': 'formData && !dataFile'
        });
        var button = angular.element("<button>").text('GO ').addClass('rotate-button').attr({
          'ng-show': 'formData && !dataFile'
        });
        var fileDiv = angular.element("<div>").addClass('file-name').text('{{ (!dataFile[0])?"":dataFile[0].name }}');
        input.addClass(scope.field.forms[scope.form].type);
        input.attr({
          "type": "file",
          "ng-file-select": "",
          "accept": scope.field.forms[scope.form].validations.fileTypes,
          "ng-model": "dataFile",
          'name': scope.field.name
        });

        dropDown.attr("ng-model", "rotation");
         scope.$watch('rotation', function () { 
           if(scope.rotation!= undefined){
              
              var data = {
                rotation: scope.rotation,
                inputUrl: scope.formData
              }; 
              button.on('click', function(){
              $http.post('/createJob', JSON.stringify(data)).then(function (response) {
          if(response.data.newKey){
            scope.formData = response.data.newKey
            SuccessMessage.open(`Video rotated to ${scope.rotation}. It may take a few seconds for video rotation to complete` )
          }
              }).catch(function(error){
                return ErrorMessage.open('Something went wrong!');
           });
           });
           }
         });

       
        input.on('dragenter', function () {
          element.addClass('Dragging');
        });
        input.on('dragleave', function () {
          element.removeClass('Dragging');
        });

        input.on('drop', function () {
          element.removeClass('Dragging');

        });

        var fileContainer = angular.element("<div>").addClass('fileContainer');
        fileContainer.attr('ng-class', '{ fileEmpty: !formData && !dataFile,  filePending: !!dataFile }');
        scope.transformedFormData = urlForImageVersion('', scope.formData, Session.user._id);
        var link = angular.element("<a>").attr({
          'ng-href': "{{formData?transformedFormData:''}}",
          'ng-show': 'formData && !dataFile',
          'target': '_blank'
        }).text("{{formData?'View File':'No file uploaded'}}");

        link.on('dragenter', function () {
          element.addClass('Dragging');
        });

        addRequired(label, input, scope);

        var uploadListener = scope.$on('upload', function (event, data) {
          if (!scope.dataFile && !angular.isArray(scope.dataFile)) return;
          scope.$emit('uploadStarted');

          var url = API_ENDPOINT + '/looks/' + scope.lookObj._id + '/forms/' + scope.form + '/uploads';
          var postData = { fileName: scope.dataFile[0].name };
          $http.post(url, postData).then(function(postResponse) {
            Uploads.put(postResponse.data.signedUrl, scope.dataFile)
            .then(function(response) {
              scope.formData = postResponse.data.baseUrl;
              scope.dataFile = null;
              $timeout(function () {
                scope.$emit('uploadDone');
              });
            }).catch(function(error) {
              return ErrorMessage.open('A problem occurred while saving photo.');
            });
          }, function(error) {
            return ErrorMessage.open('A problem occurred while preparing upload.');
          });
        });
        fileContainer.append(input);
        element.append(label, link, fileContainer, fileDiv, dropDown, button);
        $compile(label)(scope);
        $compile(link)(scope);
        $compile(fileContainer)(scope);
        $compile(fileDiv)(scope);
        $compile(dropDown)(scope);
         $compile(button)(scope);

        scope.$on('$destroy', function () {
          uploadListener();
        });
      }
    };
  };

  app.directive('schemaFieldHide', function ($compile) {
    return {
      restrict: 'A',
      scope: {
        lookObj: '=',
        group: '=',
        form: '=',
        formData: '=',
        clientData: '=?'
      },
      link: function (scope, element, attrs) {

        var docFrag = angular.element('<div>').addClass(["Schema-group-container", scope.group.groupType].join(" "));
        docFrag.attr('schema-render-group-' + scope.group.groupType, '').attr({
          'look-obj': 'lookObj',
          group: 'group',
          form: 'form',
          'form-data': 'formData',
          'client-data': 'clientData'
        });
        angular.forEach(scope.group.fields, function (field, index) {
          if (field.type == "group") {
            if (!field.fields.length) return;
            var newGroup = addGroupDirective('group.fields[' + index + ']',
              'formData["' + field.name.replace("'", "\\'") + '"]',
              'clientData["' + field.name.replace("'", "\\'") + '"]', field.multiple);
            var newGroupHeader = addHeader('{{group.fields[' + index + '].name}}');
            docFrag.append(newGroupHeader, newGroup);
          } else {
            docFrag.append(addFieldDirective(field.forms[scope.form].type, 'group.fields[' + index + ']',
              'formData["' + field.name.replace("'", "\\'") + '"]',
              'clientData["' + field.name.replace("'", "\\'") + '"]'));
          }
        });
        $compile(docFrag)(scope);
        element.append(docFrag);
      }
    };
  });

  app.directive('schemaRenderFieldFile', FileField);
  app.directive('schemaRenderFieldDateTimeDisplay',DateTimeDisplay)
  app.directive('schemaRenderFieldVideo', FileField);

  app.directive('schemaRenderFieldSingleLine', SingleLine);
  app.directive('schemaRenderFieldDateTime',dateTime)
  app.directive('schemaRenderFieldVin', SingleLine);
  app.directive('schemaRenderFieldReferenceNumber', ReferenceNumber);
  app.directive('schemaRenderFieldProperName', SingleLine);
  app.directive('schemaRenderFieldCity', SingleLine);
  app.directive('schemaRenderFieldStreet', SingleLine);
  app.directive('schemaRenderFieldZip', SingleLine);

  app.directive('schemaRenderFieldNumberDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldText', SingleLineDisplay);
  app.directive('schemaRenderFieldSingleLineDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldVinDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldReferenceNumberDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldProperNameDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldCityDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldStreetDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldStateDisplay', SingleLineDisplay);
  app.directive('schemaRenderFieldZipDisplay', SingleLineDisplay);

})();
