app.controller('NavigationCtrl', function($scope, $route, $location, $http){
  /* $route is resolved asynchronously */
  $scope.$on('$routeChangeSuccess', function() {
    $scope.hideNav = _.get($route, 'current.$$route.data.hideNav');
  });

  $scope.routeIncludes = function(route) {
      return $location.path().indexOf(route) != -1;
  };

});
