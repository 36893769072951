app.controller('ConversionInformationCtrl', function($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage, $location){

  var Look = Collection('looks').Member($routeParams.lookId);

  Look.get().success(function(look){
    $scope.ConversionInformation = {
      type: look.conversion.name,
      lookerFee: look.conversion.lookerFee / 100,
      lookPrice: look.conversion.price / 100,
      reason: look.conversion.reason,
      dueTo: look.conversion.dueTo,
      explanation: look.conversion.explanation || 'No explanation given.',
      userName: `${look.conversion.user.firstName} ${look.conversion.user.lastName}`,
      userEmail: look.conversion.user.email,
      conversionDate: look.conversion.createdAt,
      conversionToJobNumber: look.conversion.to.jobNumber,
      conversionToLookId: look.conversion.to._id
    };
    $scope.goToConvertedLook = function() {
      $location.path('/csr/' + $scope.ConversionInformation.conversionToLookId);
    };
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
  });;
});
