app.controller('LateReasonCtrl', function($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage, LepReasons){
  $scope.categories = _.sortBy(LepReasons.late, 'reason');

  var Look = Collection('looks').Member($routeParams.lookId);

  Look.get().success(function(look){
    if(!look.lateReason) {
      return;
    }
    $scope.lateReason = {
      category: look.lateReason.category,
      dueTo: _.get(look, 'lateReason.dueTo') || '',
      selected: _.find($scope.categories, 'reason', look.lateReason.category),
      explanation: _.get(look, 'lateReason.explanation') || ''
    };
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
  });;

  $scope.saveLateReason = function(){
    if(!$scope.lateReason.selected) return ErrorMessage.open('Please give a reason for the look being late.');
    if(!$scope.lateReason.dueTo) return ErrorMessage.open('Please select a due to for the late reason.');
    $scope.lateReason.category = $scope.lateReason.selected.reason;
    Look.Field('lateReason').update($scope.lateReason).success(function(response){
      SuccessMessage.open('Saved the the late reason.');
      SidePanel.close();
    }).error(function(response){
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
    });
  };

});
