(function() {
    'use strict'

    angular
        .module("OMSAdmin")
        .factory("CompaniesList", companiesFactory)

        function companiesFactory($http) {
            return {
                get: function(options) {
                    if (options.fields) {
                        return $http.get(API_ENDPOINT + "/companies?limit=5000&fields=" + options.fields);
                    }
                    return $http.get(API_ENDPOINT + "/companies?limit=5000");
                }
            }
        }

})();
