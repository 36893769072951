app.controller('ScheduleCtrl', function ($scope, Collection, SidePanel, ErrorMessage, SuccessMessage, $routeParams, MomentHelper) {
  var Look = Collection('looks').Member($routeParams.lookId);
  var ScheduleReasons = Collection('scheduleReasons');

  $scope.reason = {
    dueTo: undefined,
    category: undefined,
    explanation: undefined
  };

  /** Form Submission */
  function getLookerDueDate(lookerDate) {
    var lookerDueDate = new Date();

    lookerDueDate.setMonth(lookerDate.month);
    lookerDueDate.setYear(lookerDate.year);
    lookerDueDate.setDate(lookerDate.date);

    return lookerDueDate.toISOString();
  }

  function getReasonID(reasons, category, dueTo) {
    return _.result(_.find(reasons, {'category': category, 'dueTo': dueTo }), '_id');
  }

  var combineDateAndTime = function combineDateAndTime(time, timezone) {
    var combined = new Date(time);

    return moment(combined).tz(timezone).format()
  };

  function isValidStartTime(scheduleStartTime) {
    var isValid = MomentHelper.isDate(scheduleStartTime);

    if(!isValid) {
      ErrorMessage.open('Start time must be valid, e.g. 9:45 pm.');
    }
    return isValid;
  }

  function isScheduleTimeBeforeLookerDueDate(scheduleStartTime, lookerDueDate) {
    var isBefore = scheduleStartTime.slice(0,10) === lookerDueDate.slice(0,10) || scheduleStartTime < lookerDueDate;

    if(!isBefore) {
      ErrorMessage.open('Schedule Time must be set before Looker Due Date.');
    }
    return isBefore;
  }

  function hasCategoryAndDueTo(category, dueTo) {
    if(!category || !dueTo) {
      ErrorMessage.open('A Category and Due To must be provided.');
    }

    return !!category && !!dueTo;
  }

  function isReadyToSchedule(scheduleStartTime, lookerDueDate) {
    return isScheduleTimeBeforeLookerDueDate(scheduleStartTime, lookerDueDate);
  }

  var schedule = function(look) {
    return function schedule() {
      var lookerDue = getLookerDueDate(look.dueDates.looker);
      var base = $scope.timezone.base;
      var start = combineDateAndTime($scope.scheduleTime.start, $scope.timezone.base);

      if (!isReadyToSchedule(start, lookerDue)) {
        return;
      }

      var payload = {
        time: {
          start: start,
          timezone: base
        }
      }

      Look.Collection('schedules').create(_.omit(payload, _.isEmpty())).success(function (data) {
        SuccessMessage.open('Updated the Schedule time.');
        SidePanel.close();
      }).error(function (response) {
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
      });
    }
  };

  var reschedule = function(look) {
    return function reschedule() {
      var lookerDue = getLookerDueDate(look.dueDates.looker);
      var base = $scope.timezone.base;
      var start = combineDateAndTime($scope.scheduleTime.start, $scope.timezone.base);

      if (!isReadyToSchedule(start, lookerDue) || !hasCategoryAndDueTo($scope.reason.category, $scope.reason.dueTo)) {
        return;
      }

      var payload = {
        reason: {
          _id: getReasonID($scope.reasons, $scope.reason.category, $scope.reason.dueTo),
          explanation: $scope.reason.explanation
        },
        time: {
          start: start,
          timezone: $scope.timezone.base
        }
      }

      Look.Collection('schedules').create(_.omit(payload, _.isEmpty())).success(function (data) {
        SuccessMessage.open('Updated the Schedule time.');
        SidePanel.close();
      }).error(function (response) {
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
      });
    }
  };

  var remove = function remove() {

    if (!hasCategoryAndDueTo($scope.reason.category, $scope.reason.dueTo)) {
      return;
    }

    var payload = {
      reason: {
        _id: getReasonID($scope.reasons, $scope.reason.category, $scope.reason.dueTo),
        explanation: $scope.reason.explanation
      }
    };

    Look.Collection('schedules').create(_.omit(payload, _.isEmpty())).success(function (data) {
      SuccessMessage.open('Removed Schedule time.');
      SidePanel.close();
    }).error(function (response) {
      response.message = response.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(response.message);
    });
  };

  /** Reason Handler */
  var getCategories = function getCategories(reasons) {
    return _.map(_.uniq(reasons, 'category'), function(reason) { return reason.category });
  }

  var getDues = function getDues(reasons, category) {
    return _.pluck(_.filter(reasons, { 'category': category }), 'dueTo');
  }

  $scope.changeSelectedItem = function() {
    $scope.reason.dueTo = undefined;
    $scope.dues = getDues($scope.reasons, $scope.reason.category);
  }

  var createNewScheduleStart = function createNewScheduleStart() {
    var start = moment().startOf('hour');
    start.add(1, 'hours');

    return {
      start: start.toDate()
    };
  };

  var getTimezone = function getTimezone(look, scheduleTime) {
    var base = MomentHelper.getTimezone(look)
    var short = MomentHelper.getTimezoneAbbr(base, new Date(scheduleTime.start));
    var full = MomentHelper.getTimezoneFull(short);

    return {
      base: base,
      short: short,
      full: full
    };
  };

  /** Async */
  Look.get().success(function (look) {
    var scheduleTime = _.get(look, 'schedule.time')  || createNewScheduleStart();
    var timezone = getTimezone(look, scheduleTime);

    $scope.timezone = timezone;
    $scope.scheduleDate = new Date(scheduleTime.start);
    $scope.scheduleTime = {};
    $scope.scheduleTime.start = moment(scheduleTime.start).tz(timezone.base).toDate();
    $scope.isScheduled = _.get(look, 'schedule.time') ;
    $scope.schedule = schedule(look);
    $scope.reschedule = reschedule(look);
    $scope.remove = remove;
  }).error(function (response) {
    response.message = response.message || 'Problem Loading Look, Please Try Again...';
    ErrorMessage.open(response.message);
    SidePanel.close();
  });

  ScheduleReasons.get().success(function(reasons) {
    $scope.reasons = reasons;
    $scope.categories = getCategories(reasons);
  }).error(function(response) {
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
    SidePanel.close();
  });
});
