app.controller('UnassignCtrl', function ($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage, LepReasons) {

  var Look = Collection('looks').Member($routeParams.lookId);
  Look.get().success(function (look) {
    $scope.look = look;
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
  });

  $scope.unassignReasons = _.sortBy(LepReasons.unassign, 'reason');
  $scope.dueTo = _.sortBy(LepReasons.dueTo, 'value');
  $scope.unassignObj = {};

 $scope.changedValue = function(item){
   $scope.unassignObj.dueTo = item.value;
   $scope.filterOptions = $scope.unassignReasons.map((item) =>{
    return {...item, dueTo: item.dueTo.filter((due)=> due.includes($scope.unassignObj.dueTo))};
  })

  $scope.unassignReasonsFiltered = $scope.filterOptions.filter((item) =>{
    return item.dueTo.length>0;
  })
 }

  $scope.unassignLooker = function () {
    if (!$scope.unassignObj.selected) {
      return ErrorMessage.open('Please give a reason for unassigning the looker.');
    }

    if (!$scope.unassignObj.dueTo) {
      return ErrorMessage.open('Please select a due to for unassigning reason.');
    }

    if (confirm("Are you sure that you want to unassign " + $scope.look.actors.looker.firstName + " " + $scope.look.actors.looker.lastName + " from this look?")) {
      var unassignClone = angular.copy($scope.unassignObj);

      unassignClone.look_id = $scope.look._id;
      unassignClone.reason = $scope.unassignObj.selected.reason;
      Look.Collection('assignments').create(unassignClone).success(function(){
        Look.Field('actors.looker').delete().success(function () {
          SuccessMessage.open('Looker has been unassigned.');
          Collection('users').Member($scope.look.actors.looker._id).Field('unassignedLooksHistory').update(unassignClone).success(function () {
            SuccessMessage.open('Looker History updated.');
            SidePanel.close();
          });
        });
      }).error(function (data) {
        data.message = data.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(data.message);
      });
    }
  };
});
