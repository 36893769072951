(function(){
  'use strict';
 
  angular
      .module('OMSAdmin')
      .directive('formatTransaction', function() {
 
          return {
              restrict: 'E',
              scope: {
                  'transaction': '='
              },
              templateUrl: "/common/directives/templates/FormattedTransaction.html",
              link: function(scope, element, attr) {

                  scope.transaction.action = scope.transaction.action;
                  scope.transaction.value = scope.transaction.value;
              }
          }  
      })
 
 })();
