(function () {
  'use strict'

  angular
    .module("OMSAdmin")
    .factory("PhotoEditor", photoEditorFactory)

  function photoEditorFactory() {

    var editor;
    var img = new Image();
    img.crossOrigin = 'anonymous';
    var container = document.getElementById('editor');
    var mask = document.getElementsByClassName('photo-editor')[0].getElementsByClassName('mask')[0];

    const englishLanguageObject = {
      "pesdk": {
        "adjustments": {
          "title": {
            "name": "Adjust"
          },
          "text": {
            "brightness": "Brightness",
            "saturation": "Saturation",
            "contrast": "Contrast",
            "gamma": "Gamma",
            "clarity": "Clarity",
            "exposure": "Exposure",
            "shadows": "Shadows",
            "highlights": "Highlights"
          }
        },
        "brush": {
          "title": {
            "name": "Brush"
          },
          "text": {
            "size": "Size"
          }
        },
        "camera": {
          "title": {
            "headline": "Take a photo!"
          },
          "text": {
            "webcamUnavailable": "Unable to display webcam image (Error: ${error})"
          }
        },
        "common": {
          "title": {
            "error": "An error has occurred",
            "imageLoadFail": "Failed to load image"
          },
          "text": {
            "color": "Color",
            "loading": "Loading...",
            "imageLoadFail": "Failed to load the image at ${path}"
          },
          "button": {
            "back": "Back",
            "cancel": "Cancel"
          }
        },
        "editor": {
          "title": {
            "zoom": "Zoom",
            "renderingError": "Error while rendering",
            "imageResized_maxMegaPixels": "Image resized",
            "imageResized_maxDimensions": "Image resized"
          },
          "button": {
            "export": "Save",
            "backgroundImage": "Background Image",
            "new": "New",
            "undo": "Undo"
          },
          "text": {
            "invalidFileType": "The file type ${fileType} is not supported.",
            "exporting": "Saving...",
            "renderingError": "An error has occurred while rendering the image.",
            "resizing": "Resizing...",
            "imageResized_maxMegaPixels": "Your image exceeds the maximum size of ${maxMegaPixels} megapixels and has therefore been resized to ${width}x${height} pixels.",
            "imageResized_maxDimensions": "Due to hardware limitations your image has been resized to ${width}x${height} pixels."
          }
        },
        "filter": {
          "title": {
            "name": "Filters"
          },
          "text": {
            "intensity": "Intensity"
          },
          "asset": {
            "identity": "None",
            "imgly_lut_celsius": "Celsius",
            "imgly_lut_chest": "Chest",
            "imgly_lut_fixie": "Fixie",
            "imgly_lut_fridge": "Fridge",
            "imgly_lut_front": "Front",
            "imgly_lut_k2": "K2",
            "imgly_lut_mellow": "Mellow",
            "imgly_lut_sin": "Sin",
            "imgly_lut_texas": "Texas",
            "imgly_lut_ad1920": "1920 A.D.",
            "imgly_lut_ancient": "Ancient",
            "imgly_lut_bleached": "Bleached",
            "imgly_lut_bleachedblue": "Bleached Blue",
            "imgly_lut_blues": "Blues",
            "imgly_lut_blueshadows": "Blue Shadows",
            "imgly_lut_breeze": "Breeze",
            "imgly_lut_bw": "B & W",
            "imgly_lut_classic": "Classic",
            "imgly_lut_colorful": "Colorful",
            "imgly_lut_cool": "Cool",
            "imgly_lut_cottoncandy": "Cotton Candy",
            "imgly_lut_creamy": "Creamy",
            "imgly_lut_eighties": "Eighties",
            "imgly_lut_elder": "Elder",
            "imgly_lut_evening": "Evening",
            "imgly_lut_fall": "Fall",
            "imgly_lut_food": "Food",
            "imgly_lut_glam": "Glam",
            "imgly_lut_gobblin": "Gobblin",
            "imgly_lut_highcarb": "High Carb",
            "imgly_lut_highcontrast": "High Contrast",
            "imgly_lut_k1": "K1",
            "imgly_lut_k6": "K6",
            "imgly_lut_kdynamic": "KDynamic",
            "imgly_lut_keen": "Keen",
            "imgly_lut_lenin": "Lenin",
            "imgly_lut_litho": "Litho",
            "imgly_lut_lomo100": "Lomo 100",
            "imgly_lut_lucid": "Lucid",
            "imgly_lut_neat": "Neat",
            "imgly_lut_nogreen": "No Green",
            "imgly_lut_orchid": "Orchid",
            "imgly_lut_pale": "Pale",
            "imgly_lut_pitched": "Pitched",
            "imgly_lut_plate": "Plate",
            "imgly_lut_pola669": "Pola 669",
            "imgly_lut_polasx": "Pola SX",
            "imgly_lut_pro400": "Pro 400",
            "imgly_lut_quozi": "Quozi",
            "imgly_lut_sepiahigh": "Sepia High",
            "imgly_lut_settled": "Settled",
            "imgly_lut_seventies": "Seventies",
            "imgly_lut_soft": "Soft",
            "imgly_lut_steel": "Steel",
            "imgly_lut_summer": "Summer",
            "imgly_lut_sunset": "Sunset",
            "imgly_lut_tender": "Tender",
            "imgly_lut_twilight": "Twilight",
            "imgly_lut_winter": "Winter",
            "imgly_lut_x400": "X400",
            "imgly_filters_analog": "Analog",
            "imgly_filters_bw": "Black & White",
            "imgly_filters_retro": "Retro",
            "imgly_filters_special": "Special",
            "imgly_filters_summer": "Summer",
            "imgly_filters_winter": "Winter",
            "all": "All"
          }
        },
        "focus": {
          "title": {
            "name": "Focus"
          },
          "text": {
            "blurRadius": "Blur radius"
          },
          "button": {
            "none": "None",
            "radial": "Radial",
            "mirrored": "Mirrored"
          }
        },
        "frame": {
          "title": {
            "name": "Frame"
          },
          "text": {
            "scale": "Scale"
          },
          "button": {
            "none": "None"
          },
          "asset": {
            "imgly_frame_dia": "Dia",
            "imgly_frame_art_decor": "Art Decor",
            "imgly_frame_black_passepartout": "Black Passepartout",
            "imgly_frame_lowpoly_shadow": "Low Poly",
            "imgly_frame_wood_passepartout": "Wood Passepartout"
          }
        },
        "library": {
          "title": {
            "searchResults": "Search results for \"${query}\"",
            "photoRollLoadFail": "Failed to load Photo Roll"
          },
          "text": {
            "photoRollLoadFail": "Failed to load photos for the photo roll: ${error}",
            "noSearchResults": "Sorry, but we couldn't find any photos for <strong>\"${query}\"</strong>."
          },
          "placeholder": {
            "search": "Search for photos"
          }
        },
        "splash": {
          "button": {
            "upload": "Upload your image"
          },
          "title": {
            "photoRoll": "Free stock footage",
            "webcam": "Webcam"
          },
          "text": {
            "photoRoll": "Select from thousands of Free Stock Photos",
            "upload": "Upload a picture from your library or just drag and drop",
            "webcam": "Take a picture with your webcam or phone"
          }
        },
        "sticker": {
          "title": {
            "name": "Sticker",
            "loadingStickersFailed": "Failed to load stickers"
          },
          "asset": {
            "all": "All",
            "imgly_sticker_emoticons": "Emoticons",
            "imgly_sticker_shapes": "Shapes"
          }
        },
        "text": {
          "title": {
            "name": "Text",
            "loadingFontsFailed": "Failed to load fonts"
          },
          "text": {
            "loadingFontsFailed": "Some fonts might not be available."
          },
          "button": {
            "size": "Size",
            "font": "Font",
            "alignment": "Alignment",
            "foreground": "Foreground",
            "background": "Background",
            "takeToFront": "To Front"
          },
          "placeholder": {
            "defaultText": "Double-click to edit"
          }
        },
        "transform": {
          "title": {
            "name": "Transform"
          },
          "button": {
            "none": "Original"
          },
          "text": {
            "rotation": "Rotation"
          },
          "asset": {
            "imgly_transform_common_custom": "Custom",
            "imgly_transform_common_square": "Square",
            "imgly_transform_common_4-3": "4:3",
            "imgly_transform_common_16-9": "16:9",
            "imgly_transform_facebook_ad": "FB Ad",
            "imgly_transform_facebook_post": "FB Post",
            "imgly_transform_facebook_cover": "FB Cover",
            "imgly_transform_facebook_profile": "FB Profile"
          }
        }
      }
    };

    const showEditor = () => {
      container.classList.add("show");
      mask.classList.add("active");
    }

    const hideEditor = () => {
      container.classList.remove("show");
      mask.classList.remove("active");
    }

    const getBase64ImageFromUrl = async function (imageUrl) {
      const res = await fetch(imageUrl);
      const blob = await res.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          resolve(reader.result);
        }, false);

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    function loadImageAsDataURL(src) {
      return new window.Promise((resolve, reject) => {
        const xhr = new window.XMLHttpRequest()
        xhr.onload = function () {
          const reader = new window.FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.onerror = function () {
            reject()
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = () => reject()
        xhr.open('GET', src)
        xhr.responseType = 'blob'
        xhr.send()
      })
    }

    mask.addEventListener("click", hideEditor);

    img.onload = function (e) {
      editor = new PhotoEditorSDK.UI.ReactUI({
        container: container,
        // Please replace this with your license: https://www.photoeditorsdk.com/dashboard/subscriptions
        license: PHOTO_EDITOR_SDK_LICENSE,
        assets: {
          // This should be the absolute path to your `assets` directory
          baseUrl: '/vendor/pesdk-html5-build-4.12.3/assets'
        },
        showHeader: true,
        showCloseButton: true,
        showTopBar: true,
        crossOrigin: 'anonymous',
        title: 'Photo Editor',
        extensions: {
          languages: {
            en: englishLanguageObject
          }
        },
        language: 'en',
        enableUpload: false,
        enableWebcam: false,
        responsive: true,
        editor: {
          image: img,
          enableZoom: true,
          forceCrop: false,
          displayResizeMessage: true,
          maxMegaPixels: {
            desktop: 20,
            mobile: 20,
          },
          controlsOrder: ['transform', 'brush', 'adjustments', 'focus'],
          export: {
            quality: 1,
            download: false,
            type: PhotoEditorSDK.RenderType.FILE,
            format: 'image/jpeg',
          },
          controlsOptions: {
            transform: {
              ratios: [
                {
                  identifier: 'imgly_transform_common_custom',
                  defaultName: 'Custom',
                  ratio: '*',
                  selected: true
                },
                {
                  identifier: 'imgly_transform_common_4-3',
                  defaultName: '4:3',
                  ratio: 4 / 3
                },
                {
                  identifier: 'my_custom_ratio_3-4', // A unique identifier for this ratio
                  defaultName: '3:4', // The default translation for this ratio
                  ratio: 3 / 4, // The image ratio (a floating point number)
                }
              ],
              replaceRatios: true
            },
            brush: {
              sizePresets: [
                0.1, 0.5, 1.0, 5.0, 20.0
              ]
            }
          }
        }
      });

      editor.on('close', () => {

        swal("Wait! You didn't save your work. Are you certain that you want to close this editor?", {
          buttons: {
            resume: true,
            cancel: "close",
            save: {
              text: "save"
            }
          },
        })
          .then((value) => {
            switch (value) {

              case "resume":
                break;

              case "save":
                editor.export().then(thing => {
                  hideEditor();
                  editor.off('export');
                });
                break;

              default:
                hideEditor();
                editor.off('export');
            }
          });

      });

      ///////// This hides the mirror buttons on Transform tab /////////
      editor.on('operation:created', (e) => {
        setTimeout(() => {
          var flipVertically = document.querySelector('img[src="/vendor/pesdk-html5-build-4.12.3/assets/ui/react/controls/transform/flip-v@2x.png"]');
          var flipHorizontally = document.querySelector('img[src="/vendor/pesdk-html5-build-4.12.3/assets/ui/react/controls/transform/flip-h@2x.png"]');

          if (flipVertically && flipHorizontally) {
            flipVertically.parentElement.style.display = 'none';
            flipHorizontally.parentElement.style.display = 'none';
          }
        });
      });

    }

    loadImageAsDataURL('/img/1b1b1b.png').then(function (dataURL) {
      img.src = dataURL
    })

    return {
      openEditor: function (imageUrl, onSave, userId) {
        const requestExpiresAt = moment().add(7, 'days').format();
        const data = {
          url: imageUrl,
          expireDate: requestExpiresAt,
          userId: userId
        }
    
        var encryptedParams = CryptoJS.AES.encrypt(JSON.stringify(data), IMAGE_URL_ENCRYPT_KEY).toString();
    
        const transformedImageUrl = `${API_ENDPOINT}/media?metaData=${encryptedParams}`;
        // Chrome and other browsers like firefox and safari treats image differently so need different loading url (remote/base64)
        if(navigator.userAgent.search("Firefox") > -1){
          loadImageAsDataURL(transformedImageUrl).then(function (dataURL) {
            img.src = dataURL  
          });
        } else {
          img.src = transformedImageUrl;
        }

        editor.onReady(function () {

          img.onload = function (e) {
            editor.setImage(img);
            showEditor();
          }

          editor.once('export', (image) => {

            const isJpeg = () => {
              const imgUrl =img.src;
              var extension = imgUrl.split('.').pop();
              return extension === 'jpg';
            }

            if (isJpeg(image)) {
                  const base64result = image.src.split(',')[1];
                  const blob = b64toBlob(base64result, 'image/jpeg');
                  onSave(blob);
                  hideEditor();
            } else {
              const base64result = image.src.split(',')[1];
              const blob = b64toBlob(base64result, 'image/png');
              onSave(blob);
              hideEditor();
            }

          });

        });

      },
      save: function () {
        editor.export(false).then(image => onSave(image));
      },

      getFileName: function (url) {
        const parts = url.split('/');
        return parts.pop() || parts.pop();
      }

    }

  }

})();
