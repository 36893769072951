(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .directive('arrow', function() {
      return {
        restrict: 'A',
        link: function(scope, element, attrs){ 
          element.bind("keydown", function(event){
            switch(event.keypress || event.which) {
              case 9:
              case 13:
              case 38:
              case 40:
                event.preventDefault();
                break;
            }
            switch(event.keypress || event.which) {
              case 16: 
                break;
              case 9:
                var tokens = $('.token');
                if(event.shiftKey) {
                  if (
                    tokens[0] === event.target 
                    || (_.isEmpty($('#'+ scope.autocompleteOption + '-autocomplete-input').val())
                    && _.isEmpty($('.token')))
                  ) {
                    $('#schedule').focus();
                  } else if (checkInputFocus(event)) {
                    $('.token:nth-last-child(2)').focus();
                  } else {
                    last();
                  }
                } else {
                  if (checkInputFocus(event)) {
                    if (
                      _.isEmpty($('#'+ scope.autocompleteOption + '-autocomplete-input').val())
                    ) {
                      $('#consumer-orders').focus();
                    } else {
                      $('.autocomplete-item:first-of-type').focus();
                    }
                  } else {
                    next();
                  }
                }
                break;
              case 38:
                if (checkInputFocus(event)) {
                  $('.token:nth-last-child(2)').focus();
                } else {
                  last();
                }
                break;
              case 40:
                if (checkInputFocus(event)) {
                  $('.autocomplete-item:first-of-type').focus();
                } else {
                  next();
                }
                break
              case 13:
                if (event.target.classList.contains('autocomplete-item')) {
                  executeAction();
                } else if (event.target.id = scope.autocompleteOption + '-autocomplete-input') {
                  scope.$parent.search(scope.$parent.filter);
                }
                break;
              case 8:
              case 46:
                if ( event.target.classList.contains('token')) {
                  executeAction();
                } else if (
                  _.isEmpty($('#'+ scope.autocompleteOption + '-autocomplete-input').val())
                ) {
                    $('.token:nth-last-child(2)').focus();
                }
                break;
              default: 
                  if (event.target.id !== scope.autocompleteOption + '-autocomplete-input') {
                    setInputFocus();
                  }
            }
          })

          function last() {
            var prevNode = element[0].previousElementSibling;
            var prev = angular.element(prevNode);
            if (prev[0]) {
              prev[0].focus();
            } else {
              if (element.context.classList.contains('autocomplete-item')) {
                setInputFocus();
              }
            }
          }
          
          function next() {
            var nextNode = element[0].nextElementSibling;
            var target = angular.element(nextNode);
            if (target[0] && (target[0].classList.contains('token') || target[0].classList.contains('autocomplete-item'))) {
              target[0].focus();
            } else {
                setInputFocus();
            }
          }

          function executeAction() {
            if (attrs.ngClick) {
              scope.$apply(attrs.ngClick);
            } else if (attrs.ngEnter) {
              scope.$apply(attrs.ngEnter);
            }
            setInputFocus();
          }

          function setInputFocus() {
            $('#' + scope.autocompleteOption + '-autocomplete-input').focus();
          }

          function checkInputFocus(event) {
            return event.target.id === scope.autocompleteOption + '-autocomplete-input'
          }
        }
      }
    })
})();
