app.service("Looks", function($http, API_ENDPOINT, $timeout, $rootScope) {
  var looksCache = {}; // Used to make drilled down quicker after search.
  var searchCache = {};

  this.search = function(options, callback) {
    $http.get(API_ENDPOINT + 'looks', {
      params: options
    }).success(callback);
  };
  this.get = function(id, callback) {
    $http.get(API_ENDPOINT + 'looks/' + id).success(callback);
  };

  this.status = {
    set: function(id, status, callback) {
      $http.put(API_ENDPOINT + 'looks/' + id + '/status', {
        "status": status
      }).success(callback);
    }
  };
  this.fees = {
    add: function(lookId, data, callback){
      $http.post(API_ENDPOINT + 'looks/' + lookId + '/lineItems', data).success(callback)
    },
    update: function(lookId, id, data, callback){
      $http.put(API_ENDPOINT + 'looks/' + lookId + '/lineItems/' + id, data).success(callback)
    },
    list: function(callback){
      $http.get(API_ENDPOINT + 'products').success(callback)
    }
  };
  this.notes = {
    list: function(lookId, callback) {
      $http.get(API_ENDPOINT + '/notes?look=' + lookId).success(callback)
    },
    add: function(id, note, callback) {
      $http.post(API_ENDPOINT + 'looks/' + id + '/notes', {
        body: note
      }).success(callback);
    }
  };

  this.looker = {
    nearby: function(geolocationObj, searchValue, minClaimed,  callback) {
      return $http.get(API_ENDPOINT + 'users', {
        params: {
          role: 'looker',
          latitude: geolocationObj.coordinates[1],
          longitude: geolocationObj.coordinates[0],
          minClaimed: minClaimed,
          q: searchValue
        }
      }).success(callback);
    },
    assign: function(id, user_id, callback) {
      return $http.put(API_ENDPOINT + 'looks/' + id + '/actors/looker', {
        '_id': user_id
      }).success(callback);
    },
    unassign: function(id, callback) {
      return $http.delete(API_ENDPOINT + 'looks/' + id + '/actors/looker').success(callback);
    }
  };

  this.forms = {
    
    dueDates: {
      saveReport: function(id, date, callback){
        $http.put(API_ENDPOINT + 'looks/' + id + '/dueDates/report', {date:date.getDate(), year: date.getFullYear(), month:date.getMonth()}).success(callback);
      },
      saveLookerDue: function(id, date, callback){
        $http.put(API_ENDPOINT + 'looks/' + id + '/dueDates/looker', {date:date.getDate(), year: date.getFullYear(), month:date.getMonth()}).success(callback);
      }
    },
    instructionsUpdate: function(id, data, callback){
      $http.put(API_ENDPOINT + 'looks/' + id + '/schema/instructions', {instructions: data}).success(callback);
    },
    clientUpdate: function(id, data, callback) {
      var uploadCount = 0;
      var uploadDone = 0;
      var completed = function() {
        $http.put(API_ENDPOINT + 'looks/' + id + '/forms/client', data).success(callback);
      };

      $rootScope.$on('uploadStarted', function() {
        uploadCount++;
      })
      $rootScope.$on('uploadDone', function() {
        uploadDone++;
        if (uploadCount == uploadDone) completed();
      })

      $rootScope.$broadcast('upload');

      if (uploadCount == 0) completed();
    },
    lookerUpdate: function(id, data, callback) {
      var uploadCount = 0;
      var uploadDone = 0;
      var completed = function() {
        $http.put(API_ENDPOINT + 'looks/' + id + '/forms/looker', data).success(callback);
      };

      $rootScope.$on('uploadStarted', function() {
        uploadCount++;
      })
      $rootScope.$on('uploadDone', function() {
        uploadDone++;
        if (uploadCount == uploadDone) completed();
      })

      $rootScope.$broadcast('upload');

      if (uploadCount == 0) completed();
    }
  };



  // REMOVE THESE FUNCTIONS
  this.nearbyLookers = function(lat, long, callback) {
    return $http.get(API_ENDPOINT + 'users', {
      params: {
        role: 'looker',
        latitude: lat,
        longitude: long
      }
    }).success(callback);
  };
  this.assignUser = function(id, user_id, callback) {
    return $http.put(API_ENDPOINT + 'looks/' + id + '/actors/looker', {
      '_id': user_id
    }).success(callback);
  };
  this.unassignUser = function(id, callback) {
    return $http.delete(API_ENDPOINT + 'looks/' + id + '/actors/looker').success(callback);
  };
  this.updateClient = function(id, data, callback) {
    return $http.post(API_ENDPOINT + 'looks/' + id + '/forms/client', data).success(callback);
  };
  this.updateLooker = function(id, data, callback) {
    return $http.post(API_ENDPOINT + 'looks/' + id + '/forms/looker', data).success(callback);
  };
})
