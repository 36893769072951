app.controller("LookCtrl", function ($scope, SchemaHelper, SidePanel, Collection, ErrorMessage, InfoMessage, MomentHelper, SuccessMessage, $routeParams, $route, $location, $rootScope, $timeout, socketService, Session, cond, JobNumber, $http, TasksList, ConversationsList, $interval, Users, NotesList) {

  var presences = {};

  const chan = socketService.createChannel(`look:${$routeParams.lookId}`);
  const socketError = socketService.err;
  if (socketError) {
    const err = "?Error: Error connecting to socket service. Please open a bug ticket."
    $scope.activeViewers = [{ name: err, _id: "error" }]
  }
  chan
    .join()
    .receive('ok', handleChannelJoinSuccess)
    .receive('error', handleChannelJoinFailure);


  chan.on("presence_state", function (state) {
    presences = Presence.syncState(presences, state);
    updateActiveViewers(presences);
  });

  chan.on("presence_diff", function (diff) {
    presences = Presence.syncDiff(presences, diff);
    updateActiveViewers(presences);
  })

  $scope.visibleToClients = false;
  $scope.$on("$routeChangeStart", function () {
    chan.leave();
  });

  $scope.deliveryOptions = function (look) {
    var isRush = look.keywords && look.keywords.indexOf('rushdelivery') !== -1;

    if (isRush) {
      return 'express';
    }
    return 'standard';
  }

  function updateActiveViewers(presences) {
    $scope.activeViewers = socketService.getUserList(presences)
    $scope.$apply();
  }

  function handleChannelJoinSuccess(resp) {
    console.log(`Connected successfully to channel ${chan.topic}`);
  }

  function handleChannelJoinFailure(resp) {
    alert("Unable to detect user presence. Please reload the page.");
  }

  $scope.tabScrollPositions = {
    client: 0,
    looker: 0,
    instructions: 0,
    reports: 0
  };

  $scope.rightSideNavTabScrollPositions = {
    notes: 0,
    assignments: 0,
    schedules: 0,
    transactions: 0,
    solicitations: 0,
    tasks: 0
  };

  $scope.selectTab = function (tab) {
    $scope.$scrollView = angular.element('.LookContent');
    $scope.tabScrollPositions[$scope.tabSelected] = $scope.$scrollView.scrollTop();
    $scope.tabSelected = tab;
    $timeout(function () {
      $scope.$scrollView.scrollTop($scope.tabScrollPositions[$scope.tabSelected])
    })
  }
  $scope.selectRightSideNavTabSelected = function (tab) {
    $scope.$scrollView = angular.element('.rightSideNav');
    $scope.rightSideNavTabScrollPositions[$scope.rightSideNavTabSelected] = $scope.$scrollView.scrollTop();
    $scope.rightSideNavTabSelected = tab;
    $timeout(function () {
      $scope.$scrollView.scrollTop($scope.rightSideNavTabScrollPositions[$scope.rightSideNavTabSelected])
    })
  }

  //
  //
  //  TODO: Breakout into tabs and side section
  //
  //
  var Look = Collection('looks').Member($routeParams.lookId);
  $scope.tabSelected = $location.path().indexOf('final') != -1 ? 'looker' : 'client';
  $scope.rightSideNavTabSelected = 'notes';
  $scope.look = {
    forms: {
      client: {

      },
      looker: {

      }
    },
    schema: {}
  };

  $scope.notifications = [];

  $scope.products = [];

  const getNotes = () => NotesList.get(encodeURIComponent($routeParams.lookId));
  const getTasks = () => TasksList.get(encodeURIComponent($routeParams.lookId));

  getNotes().then(result =>{
    $scope.notes = result.data
  })

  getTasks().then(result => {
    $scope.tasks = result.data;
  });
    $scope.Timezone = moment.tz.guess();
    $scope.dueTimeUpdated = moment().tz($scope.Timezone).toDate();
    $scope.updateDueTime = function(dueTimeUpdated) { 
    $scope.tasks.dueTime = dueTimeUpdated;
   }

   $scope.submitTime = function(task){
      const dueTimes = {
        dueTime: $scope.tasks.dueTime
        }
   $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, dueTimes).then(function(response){
        if(response.status !== 200) {
          ErrorMessage.open('Something went wrong.');
        } else {
         SuccessMessage.open('DueTime Updated.')
        }
      })
      .catch(err => {
        console.error(err);
        ErrorMessage.open('Something went wrong.');
      });
   };
   $scope.assign = function(user){
     const userDisplayName = `${user.firstName + ' ' + user.lastName}`
     const assignedParties = {
      assignedTo: [
        {
          _id: user._id,
        }
      ],
      status: "In Progress"
    };
    $http.patch(`${API_ENDPOINT}/tasks/${$scope.taskId}`, assignedParties).then(function (response) {
      if (response.status !== 200) {
        ErrorMessage.open('Something went wrong.');
      } else {
        SuccessMessage.open(`Task successfully assigned to: ${userDisplayName}`);
        $interval(function () {
          window.location.reload();
        }, 1500);
      }
    })
      .catch(err => {
        console.error(err);
        ErrorMessage.open('Something went wrong.');
      });
   }

  $scope.unassignTask = function (task, unassignUser) {
    const assignedParties = {
      assignedTo: [
        {
          _id: unassignUser._id,
          isActive: false
        }
      ]
    };
    $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, assignedParties).then(function (response) {
      if (response.status !== 200) {
        ErrorMessage.open('Something went wrong.');
      } else {
        SuccessMessage.open(`Task successfully unassigned`);
        window.location.reload();
      }
    })
      .catch(err => {
        console.error(err);
        ErrorMessage.open('Something went wrong.');
      });
  }

   $scope.results = [];
   $scope.filter = {};
   angular.extend($scope.filter, $location.search());
   $scope.search = function(){
     var filterClone = angular.copy($scope.filter);
     filterClone.role = 'csr';
     Users.search(filterClone, function(data){
       $scope.results = data;
     });
   };
   $scope.showPopUp = function (task) {
    $scope.taskId = task._id
    $scope.IsVisible = true;
    $scope.buttonName = 'Assign';
  }
  $scope.ShowHide = function () {
    $scope.IsVisible = $scope.IsVisible ? false : true;
}
    $scope.taskRowClass = function(task){
      if(task.authorisedRoles){
        $scope.taskRoleMatch = Session.user.roles.some(role=> task.authorisedRoles.indexOf(role) >= 0);
      }
   else{
        $scope.taskRoleMatch = true;
      }
      if (task.groupQueue) {
        if (task.groupQueue === 'Dispatch') {
          return 'dispatch';
        }
        if (task.groupQueue === 'Customer Service') {
          return 'customer';
        }
        if (task.groupQueue === 'Looker Support') {
          return 'looker';
        }
        if (task.groupQueue === 'Client Support') {
          return 'client';
        }
        if (task.groupQueue === 'Quality Assurance') {
          return 'qa';
        }
        return '';
      }
}


$scope.pendingStatus = function(task){
    task.status = 'Pending'
   const failedTask = {
   status: task.status
   };
   $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, failedTask).then(function(response){
       if(response.status !== 200) {
         ErrorMessage.open('Something went wrong.');
       } else {
        $scope.showStatusOptions = false;
        SuccessMessage.open('Task is now Pending.');
       
 }
  })
}
$scope.readyStatus = function(task){
  task.status = 'Ready'
 const failedTask = {
 status: task.status
 };
 $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, failedTask).then(function(response){
     if(response.status !== 200) {
       ErrorMessage.open('Something went wrong.');
     } else {
      $scope.showStatusOptions = false;
      SuccessMessage.open('Task is now Ready.');  
      $interval(function () {
        window.location.reload();
      }, 500);  
}
})
}

  Look.attach($scope, 'look', {
    transform: function (look) {
      if (look.timezone && look.timezone.timeZoneId) {
        var Timezone = look.timezone.timeZoneId;
        const updateClock = function () {
          var currentTime =  moment().tz(Timezone);
          $scope.clock = currentTime.format('hh:mm a z');
          var beforeTime = moment().tz(Timezone).set({'h':8, 'm':0, 's':0})
          var afterTime = moment().tz(Timezone).set({'h':20, 'm':0, 's':0})
          $scope.checkTime = currentTime.isBetween(beforeTime, afterTime);
        }
        $interval(updateClock, 60000);
        updateClock();
      }

      var isConsumer = look.company === null ? true : false;
      if (isConsumer) {
        if (look.payment) {
          var isPaymentCaptured = look.payment.status === "captured" || look.payment.status === "authorized" ? true : false;
        }
        if (!look.payment || !isPaymentCaptured) {
          $scope.showPaymentAlert = true;
        }
      }
    $scope.userId = look.verification.user ? look.verification.user.email : "OSC";
      var lookerDueDate = look.dueDates.looker;
      $scope.lookLookerDueDate = new Date(lookerDueDate.year, lookerDueDate.month, lookerDueDate.date);

      var reportDueDate = look.dueDates.report;
      $scope.lookReportDueDate = new Date(reportDueDate.year, reportDueDate.month, reportDueDate.date);

      const formattedJobNumber = JobNumber.format(look.jobNumber);
      $scope.formattedJobNumber = formattedJobNumber;
      $rootScope.title = formattedJobNumber;

      return look;
    }
  });

  Collection('products').attach($scope, 'products');

  const getConversations = () => ConversationsList.get(encodeURIComponent($routeParams.lookId));

  getConversations().then(result => {
    $scope.conversations = result.data;
  });

  $scope.$watch('look', function (look) {
    const snoozeExpiry = _.get(look, "snooze.expireAt");
    if (snoozeExpiry && moment(snoozeExpiry).isBefore(new Date())) {
      Look
        .Field("snooze")
        .delete();
    }
  });

  $scope.ActiveUserNames = function () {
    var names = _.filter(_.uniq(_.pluck($rootScope.pageViews[$scope.look._id], 'name')), function (name) {
      return ($rootScope.user.firstName + ' ' + $rootScope.user.lastName) != name;
    })
    return names;
  };

  $scope.isLate = function () {
    if (!_.get($scope, 'look.dueDates.report')) return false;

    // Modify 'now' and 'dueDate' with America/Chicago timezone
    const now = moment().tz('America/Chicago');
    const dueDate = moment.tz(_.get($scope, 'look.dueDates.report'), 'America/Chicago');

    return now.isAfter(dueDate, 'days');
  };

  $scope.lineItemTotal = function (field) {
    if ($scope.look && $scope.look.lineItems) {
      var total = 0;
      var currency = null;
      for (var i = 0; i < $scope.look.lineItems.length; i++) {
        total += $scope.look.lineItems[i][field];
        var activeCurrency = $scope.look.lineItems[i][field + 'Currency'] || 'USD';

        if (!currency) {
          currency = activeCurrency.trim();
        } else if (currency != activeCurrency.trim()) {
          return 'Multiple Currencies';
        }
      }
      return currency + ' ' + (total / 100).toFixed(2);
    }
    return '0.00';
  };

  $scope.scheduledTimeWithTimeZoneShort = function (time) {
    var look = $scope.look;
    var timezone = _.get(look, 'timezone.timeZoneId', null)
      || _.get(look, 'schedule.time.timezone', null)
      || 'America/Chicago';

    return moment(time).tz(timezone).format('ddd, MMM DD h:mm a z');
  };

  $scope.needsVerification = function () {
    return !_.includes(['Complete', 'Skipped'], _.get($scope, "look.verification.status"));
  }

  var abbrs = {
    AKST: 'Alaska Standard Time',
    AKDT: 'Alaska Daylight Time',
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    HST: 'Hawaii Standard Time',
    HDT: 'Hawaii Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
  };

  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
  };

  $scope.scheduledTimeWithTimeZoneLong = function (time) {
    var look = $scope.look;
    var timezone = _.get(look, 'timezone.timeZoneId', null)
      || _.get(look, schedule.time.timezone, null) || 'America/Chicago';

    return moment(time).tz(timezone).format('dddd, MMMM DD, YYYY h:mm a zz');
  }

  $scope.scheduledTimeWithTimezoneShort = function (time) {
    $scope.timezone = MomentHelper.getTimezone($scope.look);
    return MomentHelper.getTimeWithTimezoneShort(time, $scope.timezone);
  };

  $scope.scheduledTimeWithTimezoneLong = function (time) {
    $scope.timezone = MomentHelper.getTimezone($scope.look);
    return MomentHelper.getTimeWithTimezoneLong(time, $scope.timezone);
  };
  $scope.saveButtonText = "Save";
$scope.hideWarning = function(){
  $scope.showWarning = true;
}
  $scope.sendNote = function () {
    $scope.showWarning = false;
    var nxtPattern = new RegExp('^ne?xt', 'i');

    if (!$scope.noteMessage) {
      $('[ng-model=noteMessage]').focus();
      return false;
    }
    toggleForm(true);
    var message = $scope.noteMessage.replace(/\n\r\v\+/g, '\n');
    Collection('looks').Member($scope.look._id).Collection('notes').create({
      body: $scope.noteMessage,
      isClientVisible: $scope.visibleToClients
    }).success(function (data) {
      getNotes().then(result =>{
        $scope.notes = result.data
      });
      $scope.noteMessage = '';
      $scope.visibleToClients = false;
      toggleForm(false);
      $('[ng-model=noteMessage]').focus();
      var lastNote = $scope.look.notes[$scope.look.notes.length - 1];
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
      toggleForm(false);
    });
    function toggleForm(disabled) {
      $('[ng-model=noteMessage], #add-note-button')
        .attr('disabled', disabled);
      $scope.saveButtonText = disabled ? "Saving..." : "Save";
    }
  };

  $scope.assignUser = function () {
    SidePanel.open('Assign Looker', 'AssignCtrl', 'partial/Look/Assign/template.html');
  };

  $scope.changeFees = function () {
    SidePanel.open('Line Items', 'FeesCtrl', 'partial/Look/Fees/template.html');
  };
  $scope.setLateReason = function () {
    SidePanel.open('Late Reason', 'LateReasonCtrl', 'partial/Look/LateReason/template.html');
  };
  $scope.editDNSReason = function () {
    SidePanel.open('Do Not Send', 'DNSCtrl', 'partial/Look/DNS/template.html');
  };
  $scope.removeDNSHold = function () {
    Look.Field('doNotSend')
      .delete()
      .success(function (data) {
        SuccessMessage.open('Look sucessfully updated.')
      })
      .error(function (res) {
        ErrorMessage.open(res.message || 'Network error, please try again...');
      });
  }

  var timeIsInTheFuture = function timeIsInTheFuture() {

    var scheduledTimeIsOk = true;
    if ($scope.look.schedule && $scope.look.schedule.time) {
      scheduledTimeIsOk = (($scope.look.schedule.time.end && new Date($scope.look.schedule.time.end).getTime() > Date.now())
        || ($scope.look.schedule.time.start && new Date($scope.look.schedule.time.start).getTime() > Date.now()));
    }

    return $scope.lookLookerDueDate.getTime() + 86400000 > Date.now()
      && scheduledTimeIsOk;
  }

  $scope.sendSolicitation = function () {
    if ($scope.look.hold) {
      ErrorMessage.open('Look cannot be solicited while On Hold.')
    }
    else if (timeIsInTheFuture()) {
      SidePanel.open('Solicitation', 'SolicitCtrl', 'partial/Look/Solicit/template.html');
    } else {
      ErrorMessage.open('Looker Due Date & Schedule Date needs to be in the future');
    }
  };
  $scope.editStatus = function () {
    SidePanel.open('Change Status', 'StatusCtrl', 'partial/Look/Status/template.html');
  };
  $scope.showCancellation = function () {
    SidePanel.open('Cancellation Information', 'CancellationInformationCtrl', 'partial/Look/CancellationInformation/template.html');
  };
  $scope.showConversion = function () {
    SidePanel.open('Conversion Information', 'ConversionInformationCtrl', 'partial/Look/ConversionInformation/template.html');
  };
  $scope.editScheduleDate = function () {
    SidePanel.open('Schedule', 'ScheduleCtrl', 'partial/Look/Schedule/template.html');
  };

  $scope.editReportDueDate = function () {
    SidePanel.open('WGL Due Date', 'WGLDueCtrl', 'partial/Look/WGLDue/template.html');
  };

  $scope.editLookerDueDate = function () {
    SidePanel.open('Looker Due Date', 'LookerDueCtrl', 'partial/Look/LookerDue/template.html');
  };

  $scope.unassignLooker = function () {
    SidePanel.open('Unassign Looker', 'UnassignCtrl', 'partial/Look/Unassign/template.html');
  };

  $scope.addTask = function () {
    SidePanel.open('Add Tasks', 'TasksCtrl', 'partial/Look/Tasks/template.html');
  };

  // $scope.startTask = function (task) {
  //   const startedTask = task;
  //   startedTask.status = 'In Progress';

  //   TasksList.put(startedTask);
  // }


  $scope.failTask = function (task) {
    task.status = 'Failed'
   const failedTask = {
   status: task.status
   };
   $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, failedTask).then(function(response){
       if(response.status !== 200) {
         ErrorMessage.open('Something went wrong.');
       } else {
        SuccessMessage.open('Task Failed.')
 }
  })
 }

  $scope.completeTask = function (task) {
     task.status = 'Passed'
    const completedTask = {
    status: task.status
    };
    $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, completedTask).then(function(response){
        if(response.status !== 200) {
          ErrorMessage.open('Something went wrong.');
        } else {
         SuccessMessage.open('Task Completed.')
  }
   })
  }
  $scope.skipTask = function (task) {
    task.status = 'Skipped'
     const skippedTask = {
    status: task.status
    };
   $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, skippedTask).then(function(response){
        if(response.status !== 200) {
          ErrorMessage.open('Something went wrong.');
        } else {
         SuccessMessage.open('Task Skipped.')
  }
   })
  }
  $scope.inProgressTask = function (task) {
    task.status = 'In Progress'
   const inProgressTask = {
   status: task.status
   };
   $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, inProgressTask).then(function(response){
       if(response.status !== 200) {
         ErrorMessage.open('Something went wrong.');
       } else {
        SuccessMessage.open('Task marked In Progress')
 }
  })
 }

  $scope.approveQA = function () {
    Look.Field('status').update({
      status: 'Final Review'
    }).success(function (data) {
      SuccessMessage.open('This Look will appear in Final Review once the reports have been generated.');
      $scope.look = data;
      $location.url('/qa');
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });
  };

  $scope.approveFinalApprove = function () {
    if (!$scope.isAdmin()) return ErrorMessage.open('You do not have permission to do that. Please contact your supervisor.');
    Look.Field('status').update({
      status: 'Final Review Complete'
    }).success(function (data) {
      SuccessMessage.open('This Look report will be sent out to the client shortly.');
      $scope.look = data;
      $location.url('/final');
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });
  };

  $scope.highlightSolicitation = function (look) {
    if (look.status === 'Unassigned' && look.solicitations) {
      return {
        'background-color': '#2591d5',
        'color': '#ffffff'
      };
    }
  };

  $scope.completeVerification = function () {
    $('.verification-button').attr('disabled', true);
    Look.Field('verification').update({
      status: 'Complete'
    }).success(function (data) {
      SuccessMessage.open('Successfully updated Look.')
      $('.verification-button').attr('disabled', false);
      createNote($scope.look.verification.status);
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });


    function createNote(status) {
      Collection('looks').Member($scope.look._id).Collection('notes').create({
        body: status === 'Requested Change' ? `On-Site Contact information has been updated after change request. \n ${$scope.look.verification.body}` : 'Completed order verification.'
      }).success(function () {
        getNotes().then(result =>{
          $scope.notes = result.data
        });
      }).error(function (data) {
        data.message = data.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(data.message);
        toggleForm(false);
      });
    }
  }

  $scope.toggleExternalStep = function (newExternalStepStatus) {
    toggleButtons(true);
    Look.Field('externalStep').update({
      status: newExternalStepStatus
    }).success(function (data) {
      SuccessMessage.open('Successfully updated Look.');
      toggleButtons(false);
      createNote(newExternalStepStatus);
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
      toggleButtons(false);
    });
  }

  function toggleButtons(bool) {
    $('#move-to-external-step').attr('disabled', bool);
    $('#complete-external-step').attr('disabled', bool);
  }

  function createNote(status) {
    Collection('looks').Member($scope.look._id).Collection('notes').create({
      body: status === 'In Progress' ? 'Look sent to external step.' : 'External step completed'
    }).success(function (data) {
      $scope.look.notes = data;
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
      toggleForm(false);
    });
  }

  $scope.$watch('look', function (look) {

    $scope.notifications = $scope.look.notifications || [];
    $scope.isVisible = false;
    $scope.notifications.map((notification => {
      if (notification.triggers && notification.triggers.find(trigger => trigger.includes('client-escalation'))) {
        $scope.isVisible = true;
        $scope.contacts = _.get(notification, '.contacts', null);
        $scope.contactValues = $scope.contacts.map((item=> item.values));
        $scope.contactvalues = $scope.contactValues.map((item => item[0]));
      }
    }))
  })
  $scope.isAdmin = function () {
    return Session.user.roles.indexOf("admin") !== -1;
  }

  $scope.snoozeDialogOpen = false;

  $scope.toggleDialog = function (state) {
    event.preventDefault();
    $scope.snoozeDialogOpen = state;
  }

  $scope.handleSnoozeDialogState = function (option) {
    event.preventDefault();
    $scope.snoozeData.snoozeOption = option;
  }

  $scope.snoozeData = {
    snoozeReason: '',
  }

  var getTimezone = function getTimezone(look, snoozeTime) {
    var base = MomentHelper.getTimezone(look)
    var short = MomentHelper.getTimezoneAbbr(base, new Date(snoozeTime.start));
    var full = MomentHelper.getTimezoneFull(short);

    return {
      base: base,
      short: short,
      full: full
    };
  };

  $scope.snoozeTime = moment().add(5, 'minutes')

  $scope.timezone = getTimezone($scope.snoozeTime, $scope.snoozeTime)

  $scope.snoozeLook = function () {
    const snoozePayload = {
      "category": $scope.snoozeData.snoozeReason,
      "note": $scope.noteMessage,
      "expireAt": $scope.snoozeTime
    }
    if (!_.every(snoozePayload, function (prop) { return !!prop })) {
      ErrorMessage.close();
      ErrorMessage.open("You must fill out the entire form.")
    } else if (moment(snoozePayload.expireAt).diff(moment(new Date())) < 0) {
      ErrorMessage.close();
      ErrorMessage.open("The date must be set in the future.")
    } else if(($scope.date_details.year.toString().length)> 4){
      ErrorMessage.close();
      ErrorMessage.open("Date is not in the correct format.")
    }
     else {
      Look
        .Field("snooze")
        .update(snoozePayload)
        .success(function (res) {
          $scope.snoozeDialogOpen = false;
          $scope.noteMessage = "";
          $scope.snoozeData = {
            snoozeReason: '',
          };
        })
        .error(function (err) {
          ErrorMessage.open(err.message || "Network error.");
          // need to handle the error here.
        })
    }

  }


  $scope.removeSnooze = function () {
    Look.Field("snooze").delete().success(function (res) {
      SuccessMessage.open("Snooze successfully removed");
      $scope.snoozeDialogOpen = false;
    }).error(function (err) {
      ErrorMessage.open(err.message || "Network error.");
    })
  }
});
