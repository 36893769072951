(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .factory('CompaniesList', function($http) {
      return {
        get: function(limit) {
          return $http.get(API_ENDPOINT + "/companies?limit=" + limit);
        }
      }
    })

})();
