(function(){
  'use strict';
 
  angular
      .module('OMSAdmin')
      .directive('formatAssignment', function() {
 
          return {
              restrict: 'E',
              scope: {
                  'assignment': '='
              },
              templateUrl: "/common/directives/templates/FormattedAssignment.html",
              link: function(scope, element, attr) {
 
                  scope.assignment.type = scope.assignment.type;
                  scope.assignment.reason = scope.assignment.reason;
                  scope.assignment.dueTo = scope.assignment.dueTo;
              }
          }  
      })
 
 })();
