app.controller('ReportCtrl', function($scope, Collection, SuccessMessage, ErrorMessage, $timeout, API_ENDPOINT, Session){

  // Get the ID from the parent view TODO: more to service
  var Look = Collection('looks').Member($scope.$parent.$parent.look._id);

  $scope.look = {};
  $scope.fields = [];

  var getAllFields = function getAllFields (fields) {

    var flattenedFields = [];

    fields.forEach(function (field) {
      if(field.type !== 'group' && field.id) {
        field.showClient = (field.reports && field.reports.indexOf('client') >= 0);
        field.showPublic = (field.reports && field.reports.indexOf('public') >= 0);
        field.showInvoive = (field.reports && field.reports.indexOf('invoice') >= 0);
        flattenedFields.push(field);
      }
      else if(!field.multiple && field.fields) {
        var nestedFields = getAllFields(field.fields);
        flattenedFields = flattenedFields.concat(nestedFields);
      }
    });
    return flattenedFields;
  };

  $scope.getLook = function getLook() {
    return Look.get().success(function(look){
      $scope.look = look;
      $scope.fields = getAllFields($scope.look.schema.fields);
    }).error(function (err) {
      //$log.log(err);
    });
  };

  $scope.submitReportField = function (fields) {

    var Reports = Look.Field("schema.fields.reports");

    var fieldReports = _.map(fields, function (field) {
      var clientPublic = [];

      if (field.showClient) clientPublic.push('client');
      if (field.showPublic) clientPublic.push('public');
      if (field.showInvoive) clientPublic.push('invoice');

      return { id: field.id, value: clientPublic};
    });
        
  

    function success() {
      SuccessMessage.open('Successfully updated all reports fields.');
    }

    function fail(data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    }

    Reports.update(fieldReports).then(success, fail);
  };
  $scope.deselectClientReport = function (field) {
    if(!field.showClient){
       field.showPublic = false;      
    }
  }
  $scope.getLook();

});
