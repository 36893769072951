(function() {
'use strict'

    angular
        .module('OMSAdmin')
        .directive('companiesFilter',function($location, CompaniesList) {
            return {
                restrict: 'E',
                scope: {
                    search: "=",
                    filter: "=",
                },
                templateUrl: "/common/directives/templates/CompaniesFilter.html",
                link: function(scope,element,attr) {

                    scope.filteredCompanies = [];
                    scope.companyPartial = "";
                    scope.autocompleteTouched = false;

                    CompaniesList.get().then(function(data) {
                        scope.companies = data.data;
                        if (scope.filter.companies) {
                            scope.selected = _.filter(scope.companies, function(company){
                                return scope.filter.companies.indexOf(company._id) != -1;
                            });
                            scope.selected.forEach(function(company) {
                                _.remove(scope.companies, function(n) {
                                    return n._id === company._id;
                                })
                            })
                        } else {
                            scope.filter.companies = [];
                            scope.selected = [];
                        }
                    }); 

                    scope.genAutocompleteList = function(e) {
                        checkKeyStroke(e);
                        if (scope.companyPartial == "") {
                            scope.filteredCompanies = [];
                            scope.leftovers = 0;
                            return;
                        }
                        scope.autocompleteTouched = true;
                        if (scope.companies && scope.companies.length > 0) {
                            scope.filteredCompanies = scope.companies
                                .filter(function(company) {
                                    return company.name.toLowerCase().startsWith(scope.companyPartial.toLowerCase());
                                })
                                .sort(function(a,b) {
                                    return a.name - b.name;
                                });
                        }
                        scope.leftovers = scope.filteredCompanies.length - 5;
                    }

                    scope.selectCompany = function(id) {
                        var inSelectedArray = scope.selected.findIndex(function(company) {
                            return company._id === id;
                        });
                        if (inSelectedArray === -1) {
                            var selectedCompanyIndex = scope.companies.findIndex(function(company) {
                                return company._id === id;
                            });
                            scope.selected.push(scope.companies.splice(selectedCompanyIndex, 1)[0]);
                        } else {
                            scope.companies.push(scope.selected.splice(inSelectedArray, 1)[0]);
                        }
                        scope.filter.companies = _.map(scope.selected, function(company) { return company._id});
                        resetAutocomplete();
                    }

                    scope.setAutocompleteInputFocus = function() {
                        $('#autocomplete-input').focus();
                    }

                    function resetAutocomplete() {
                        scope.filteredCompanies = [];
                        scope.companyPartial = "";
                        scope.autocompleteTouched = false;
                        scope.setAutocompleteInputFocus();
                    }

                    function checkKeyStroke(e) {
                        if (e.which === 40 || e.keyCode === 40) {
                            $('.autocomplete-item:first-child').focus();
                        }
                        if (e.which === 37 || e.keyCode === 37) {
                            $('.token:last-of-type').focus();
                        }
                        if (e.which === 13) {
                            e.preventDefault();
                            if(scope.filteredCompanies.length == 1) {
                                scope.selectCompany(scope.filteredCompanies[0]._id);
                            }
                        }
                    }

                }
            }
        })

})();
