var app = angular.module('ngTable', []);

app.value('ngTableDefaults', {
  params: {},
  settings: {}
});

app.factory('ngTableParams', ['$q', '$log', 'ngTableDefaults', function($q, $log, ngTableDefaults) {
  var isNumber = function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  var ngTableParams = function(baseParameters, baseSettings) {
    var self = this,
      log = function() {
        if (settings.debugMode && $log.debug) {
          $log.debug.apply(this, arguments);
        }
      };

    this.data = [];

    this.parameters = function(newParameters, parseParamsFromUrl) {
      parseParamsFromUrl = parseParamsFromUrl || false;
      if (angular.isDefined(newParameters)) {
        for (var key in newParameters) {
          var value = newParameters[key];
          if (parseParamsFromUrl && key.indexOf('[') >= 0) {
            var keys = key.split(/\[(.*)\]/).reverse()
            var lastKey = '';
            for (var i = 0, len = keys.length; i < len; i++) {
              var name = keys[i];
              if (name !== '') {
                var v = value;
                value = {};
                value[lastKey = name] = (isNumber(v) ? parseFloat(v) : v);
              }
            }
            if (lastKey === 'sorting') {
              params[lastKey] = {};
            }
            params[lastKey] = angular.extend(params[lastKey] || {}, value[lastKey]);
          } else {
            params[key] = (isNumber(newParameters[key]) ? parseFloat(newParameters[key]) : newParameters[key]);
          }
        }
        log('ngTable: set parameters', params);
        return this;
      }
      return params;
    };


    this.settings = function(newSettings) {
      if (angular.isDefined(newSettings)) {
        settings = angular.extend(settings, newSettings);
        log('ngTable: set settings', settings);
        return this;
      }
      return settings;
    };

    this.sorting = function(sorting) {
      if (arguments.length == 2) {
        var sortArray = {};
        sortArray[sorting] = arguments[1];
        this.parameters({
          'sorting': sortArray
        });
        return this;
      }
      return angular.isDefined(sorting) ? this.parameters({
        'sorting': sorting
      }) : params.sorting;
    };

    this.isSortBy = function(field, direction) {
      return angular.isDefined(params.sorting[field]) && angular.equals(params.sorting[field], direction);
    };

    this.orderBy = function() {
      var sorting = [];
      for (var column in params.sorting) {
        sorting.push((params.sorting[column] === "asc" ? "+" : "-") + column);
      }
      return sorting;
    };

    this.getData = function($defer, params) {
      if (angular.isArray(this.data) && angular.isObject(params)) {
        $defer.resolve(this.data);
      } else {
        $defer.resolve([]);
      }
      return $defer.promise;
    };

    this.getGroups = function($defer, column) {
      var defer = $q.defer();

      defer.promise.then(function(data) {
        var groups = {};
        angular.forEach(data, function(item) {
          var groupName = angular.isFunction(column) ? column(item) : item[column];

          groups[groupName] = groups[groupName] || {
            data: []
          };
          groups[groupName]['value'] = groupName;
          groups[groupName].data.push(item);
        });
        var result = [];
        for (var i in groups) {
          result.push(groups[i]);
        }
        log('ngTable: refresh groups', result);
        $defer.resolve(result);
      });
      return this.getData(defer, self);
    };

    this.reload = function() {
      var $defer = $q.defer(),
        self = this,
        pData = null;

      if (!settings.$scope) {
        return;
      }

      settings.$loading = true;
      if (settings.groupBy) {
        pData = settings.getGroups($defer, settings.groupBy, this);
      } else {
        pData = settings.getData($defer, this);
      }
      log('ngTable: reload data');

      if (!pData) {
        // If getData resolved the $defer, and didn't promise us data,
        //   create a promise from the $defer. We need to return a promise.
        pData = $defer.promise;
      }
      return pData.then(function(data) {
        settings.$loading = false;
        log('ngTable: current scope', settings.$scope);
        if (settings.groupBy) {
          self.data = data;
          if (settings.$scope) settings.$scope.$groups = data;
        } else {
          self.data = data;
          if (settings.$scope) settings.$scope.$data = data;
        }
        return data;
      });
    };

    var params = this.$params = {
      sorting: {},
      group: {},
      groupBy: null
    };
    angular.extend(params, ngTableDefaults.params);

    var settings = {
      $scope: null, // set by ngTable controller
      $loading: false,
      data: null, //allows data to be set when table is initialized
      defaultSort: 'desc',
      getGroups: this.getGroups,
      getData: this.getData
    };
    angular.extend(settings, ngTableDefaults.settings);

    this.settings(baseSettings);
    this.parameters(baseParameters, true);
    return this;
  };
  return ngTableParams;
}]);

var ngTableController = ['$scope', 'ngTableParams', '$timeout', function($scope, ngTableParams, $timeout) {
  var isFirstTimeLoad = true;
  $scope.$loading = false;

  if (!$scope.hasOwnProperty("params")) {
    $scope.params = new ngTableParams();
    $scope.params.isNullInstance = true;
  }
  $scope.params.settings().$scope = $scope;


  $scope.$watch('params.$params', function(newParams, oldParams) {

    if (newParams === oldParams) {
      return;
    }

    $scope.params.settings().$scope = $scope;

    $scope.params.reload();

    if (!$scope.params.isNullInstance) {
      isFirstTimeLoad = false;
    }

  }, true);

  $scope.sortBy = function(column, event) {
    var parsedSortable = $scope.parse(column.sortable);
    if (!parsedSortable) {
      return;
    }
    var defaultSort = $scope.params.settings().defaultSort;
    var inverseSort = (defaultSort === 'asc' ? 'desc' : 'asc');
    var sorting = $scope.params.sorting() && $scope.params.sorting()[parsedSortable] && ($scope.params.sorting()[parsedSortable] === defaultSort);
    var sortingParams = (event.ctrlKey || event.metaKey) ? $scope.params.sorting() : {};
    sortingParams[parsedSortable] = (sorting ? inverseSort : defaultSort);
    $scope.params.parameters({
      sorting: sortingParams
    });
  };
}];

app.directive('ngTable', ['$compile', '$q', '$parse',
  function($compile, $q, $parse) {
    'use strict';

    return {
      restrict: 'A',
      priority: 1001,
      scope: true,
      controller: ngTableController,
      compile: function(element) {
        var columns = [],
          i = 0,
          row = null;

        // custom header
        var thead = element.find('> thead');

        // IE 8 fix :not(.ng-table-group) selector
        angular.forEach(angular.element(element.find('tr')), function(tr) {
          tr = angular.element(tr);
          if (!tr.hasClass('ng-table-group') && !row) {
            row = tr;
          }
        });
        if (!row) {
          return;
        }
        angular.forEach(row.find('td'), function(item) {
          var el = angular.element(item);
          if (el.attr('ignore-cell') && 'true' === el.attr('ignore-cell')) {
            return;
          }
          var parsedAttribute = function(attr, defaultValue) {
            return function(scope) {
              return $parse(el.attr('x-data-' + attr) || el.attr('data-' + attr) || el.attr(attr))(scope, {
                $columns: columns
              }) || defaultValue;
            };
          };

          var parsedTitle = parsedAttribute('title', ' '),
            headerTemplateURL = parsedAttribute('header', false);

          el.attr('data-title-text', parsedTitle()); // this used in responsive table
          columns.push({
            id: i++,
            title: parsedTitle,
            sortable: parsedAttribute('sortable', false),
            'class': el.attr('x-data-header-class') || el.attr('data-header-class') || el.attr('header-class'),
            headerTemplateURL: headerTemplateURL,
            show: (el.attr("ng-show") ? function(scope) {
              return $parse(el.attr("ng-show"))(scope);
            } : function() {
              return true;
            })
          });
        });
        return function(scope, element, attrs) {
          scope.$loading = false;
          scope.$columns = columns;

          scope.$watch(attrs.ngTable, (function(params) {
            if (angular.isUndefined(params)) {
              return;
            }
            scope.paramsModel = $parse(attrs.ngTable);
            scope.params = params;
          }), true);

          scope.parse = function(text) {
            return angular.isDefined(text) ? text(scope) : '';
          };

          if (!element.hasClass('ng-table')) {
            scope.templates = {
              header: (attrs.templateHeader ? attrs.templateHeader : 'ng-table/header.html')
            };
            var headerTemplate = thead.length > 0 ? thead : angular.element(document.createElement('thead')).attr('ng-include', 'templates.header');

            element.find('> thead').remove();

            element.addClass('ng-table')
              .prepend(headerTemplate);

            $compile(headerTemplate)(scope);
          }
        };
      }
    }
  }
]);

angular.module('ngTable').run(['$templateCache', function($templateCache) {
  $templateCache.put('ng-table/header.html', '<tr> <th ng-repeat="column in $columns" ng-class="{ \'sortable\': parse(column.sortable), \'sort-asc\': params.sorting()[parse(column.sortable)]==\'asc\', \'sort-desc\': params.sorting()[parse(column.sortable)]==\'desc\' }" ng-click="sortBy(column, $event)" ng-show="column.show(this)" ng-init="template=column.headerTemplateURL(this)" class="header {{column.class}}"> <div ng-if="!template" ng-show="!template" ng-bind="parse(column.title)"></div> <div ng-if="template" ng-show="template" ng-include="template"></div> </th> ');
}]);
