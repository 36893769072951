(function() {

  function fromDollarsToPennies(text) {
    var num = parseInt(text, 10);

    if (isNaN(num)) {
      return;
    }

    return num * 100;
  }

  function fromPenniesToDollars(num) {
    return num / 100;
  }

  app.directive('pennies', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attr, ngModel) {
        ngModel.$parsers.push(fromDollarsToPennies);
        ngModel.$formatters.push(fromPenniesToDollars);
      }
    };
  });
})();