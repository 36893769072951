(function() {
  'use strict'

  angular
    .module("OMSAdmin")
    .controller('SelfServiceConversionCtrl', function($scope, $http, $routeParams, Collection, SidePanel, SuccessMessage, ErrorMessage, LepReasons) {

      const conversionEndpoint = `${API_ENDPOINT}/looks/${$routeParams.lookId}/selfService/conversion`;
      $scope.selfServiceConversionReasons = LepReasons.selfServiceConversionReasons;

      $scope.saveConversion = function() {
        if(!_.isEmpty($scope.conversionReason)
           && !_.isEmpty($scope.dueTo)
           && confirm("This cannot be undone. Are you sure you want this Self Service Look to become a normal Look?")
          ) {
          $http.put(conversionEndpoint, {
            explanation: $scope.conversionReason,
            dueTo: $scope.dueTo.reason
          })
            .success(function() {
              SuccessMessage.open("Look has been successfully turned into to a normal Look. A new job number has been provided in the notes.")
              $scope.conversionReason = "";
            })
            .error(function(response) {
              ErrorMessage.open(response.messsage || 'Network Error, Please try again...');
            })
        } else {
          ErrorMessage.open("Unable to complete your request. Please try again...");
        }
      }
    });

})();
