
app.controller("AssignCtrl", function($scope, SchemaHelper, Collection, SidePanel, SuccessMessage, ErrorMessage, $routeParams) {
  $scope.SidePanel = SidePanel;

  var Look = Collection('looks').Member($routeParams.lookId);

  Look.get().success(function(look){
    $scope.lookId =  _.get(look, '_id');
    $scope.lookAssigned = !!_.get(look, "actors.looker");
    var requiredLicenses = _.get(look, 'requirements.licenses');
    $scope.isNotary = requiredLicenses ? requiredLicenses.indexOf('notary') !== -1 : false;
    $scope.Address = look.normalizedLookAddress;
    $scope.getNearbyUsers();
  }).error(function(){
    SidePanel.close();
  });
  $scope.isAnyDistance = false;
  $scope.isOldQuery = false;

  $scope.getNearbyUsers = function(){
    const usersUrl = 'users/nearby';
    Collection(usersUrl).filter({
      look: $scope.lookId,
      check_travel_radius: $scope.isAnyDistance?false: true,
      hasBackgroundCheck: $scope.isBackgroundChecked ? true: '',
      q: $scope.searchValue,
    }).get().success(function(users){
      $scope.nearbyUsers = users;
    });
  };

  $scope.hasBackgroundChecked = function(user){
    return user.wglBackgroundCheckStatus==='passed'
  }
  
  $scope.changeAssigned = function(user) {
    if (user){
      Look.Field('actors.looker').update({ '_id': user._id }).success(function(){
        SuccessMessage.open('Changed assigned Looker.');
        SidePanel.close();
      });
    }
    else alert('No looker selected.');
  };

  $scope.wasPreviouslyUnassigned = function(user) {
    if (!user.unassignedLooksHistory) {
      return false;
    }
    return _.findLast(user.unassignedLooksHistory, {look_id: $routeParams.lookId}) ? true : false;
  };

  $scope.previouslyUnassignedReason = function(user) {
    if (!user.unassignedLooksHistory) {
      return '';
    }
    const previouslyUnassigned = _.findLast(user.unassignedLooksHistory, {look_id: $routeParams.lookId});
    let explanation;

    if (previouslyUnassigned) {
      explanation = previouslyUnassigned.dueTo ? '. Due To: ' + previouslyUnassigned.dueTo : '';
      explanation += previouslyUnassigned.explanation ? '. Explanation: ' + previouslyUnassigned.explanation : '';
    } else {
      return '';
    }
    return previouslyUnassigned.reason + explanation;
  };

});
