app.controller('CSRCtrl', function ($scope,$routeParams, TasksList, Looks, $location, Collection, CompaniesList, $http) {

  const anyOption = { label: 'Any', value: undefined };
  
  $scope.statuses = [
    {label: 'Any', value: ''},
    {label: 'Active', value: 'Unassigned,Assigned'},
    {label: 'Unassigned', value: 'Unassigned'},
    {label: 'Soliciting', value: 'Soliciting'},
    {label: 'Assigned', value: 'Assigned'},
    {label: 'On Hold', value: 'Hold'},
    {label: 'QA', value: 'QA'},
    {label: 'Final Review', value: 'Final Review'},
    {label: 'Final Review Complete', value: 'Final Review Complete'},
    {label: 'Canceled', value: 'Canceled'},
    {label: 'Servicing', value: 'Servicing'},
    {label: 'Converted', value: 'Converted'}
  ];

  $scope.scheduledOptions = {
    'Any': undefined,
    'Scheduled': 'true',
    'Not Scheduled': 'false'
  };

  $scope.dateOptions = [
    {label: 'Order Date', value: 'Order Date' },
    {label: 'WGL Due', value: 'WGL Due' },
    {label: 'Looker Due', value: 'Looker Due' },

  ]


  $scope.verificationStatuses = [
    anyOption,
    {label: "Complete", value: 'Complete'},
    {label: "Pending", value: 'Pending'},
    {label: "On-Site Contact Change Request", value: 'Requested Change'},
    {label: "Skipped", value: 'Skipped'},
    {label: 'Skipped and Complete', value: 'Skipped and Complete'}
  ]

  $scope.externalStepStatuses = [
    anyOption,
    {label:"Skipped", value: "Skipped"},
    {label:"Required", value: "Skipped"},
    {label:"In Progress", value: "In Progress"},
    {label:"Complete", value: "Complete"},
  ]

  $scope.doNotSendOptions = [
    anyOption,
    {label: 'Show Only DNS Looks', value: 'Show'},
    {label: 'Hide DNS Looks', value: 'Hide'}
  ]

  $scope.snoozeOptions = [
    anyOption,
    {label: 'Show snoozed Looks', value: 'Show'},
    {label: 'Hide snoozed Looks', value: 'Hide'}
  ]
  $scope.OpenTaskOptions = [
    anyOption,
    {label: 'Hide Recruiting Needed Looks', value: 'hide_recruiting_needed_looks'}
  ]

  $scope.states = [
    anyOption,
    {value: 'AL', label: 'Alabama'},
    {value: 'AK', label: 'Alaska'},
    {value:'AZ',label: 'Arizona'},
    {value:'AR',label: 'Arkansas'},
    {value:'CA',label: 'California'},
    {value:"CO",label: 'Colorado'},
    {value:"CT",label: 'Connecticut'},
    {value:"DE",label: 'Delaware'},
    {value:"DC",label: 'District Of Columbia'},
    {value:"FL",label: 'Florida'},
    {value:"GA",label: 'Georgia'},
    {value:"HI",label: 'Hawaii'},
    {value:"ID",label: 'Idaho'},
    {value:"IL",label: 'Illinois'},
    {value:"IN",label: 'Indiana'},
    {value:"IA",label: 'Iowa'},
    {value:"KS",label: 'Kansas'},
    {value:"KY",label: 'Kentucky'},
    {value:"LA",label: 'Louisiana'},
    {value:"ME",label: 'Maine'},
    {value:"MD",label: 'Maryland'},
    {value:"MA",label: 'Massachusetts'},
    {value:"MI",label: 'Michigan'},
    {value:"MN",label: 'Minnesota'},
    {value:"MS",label: 'Mississippi'},
    {value:"MO",label: 'Missouri'},
    {value:"MT",label: 'Montana'},
    {value:"NE",label: 'Nebraska'},
    {value:"NV",label: 'Nevada'},
    {value:"NH",label: 'New Hampshire'},
    {value:"NJ",label: 'New Jersey'},
    {value:"NM",label: 'New Mexico'},
    {value:"NY",label: 'New York'},
    {value:"NC",label: 'North Carolina'},
    {value:"ND",label: 'North Dakota'},
    {value:"OH",label: 'Ohio'},
    {value:"OK",label: 'Oklahoma'},
    {value:"OR",label: 'Oregon'},
    {value:"PA",label: 'Pennsylvania'},
    {value:"PR",label: 'Puerto Rico'},
    {value:"RI",label: 'Rhode Island'},
    {value:"SC",label: 'South Carolina'},
    {value:"SD",label: 'South Dakota'},
    {value:"TN",label: 'Tennessee'},
    {value:"TX",label: 'Texas'},
    {value:"UT",label: 'Utah'},
    {value:"VT",label: 'Vermont'},
    {value:"VA",label: 'Virginia'},
    {value:"VI",label: 'Virgin Island'},
    {value:"WA",label: 'Washington'},
    {value:"WV",label: 'West Virginia'},
    {value:"WI",label: 'Wisconsin'},
    {value:"WY",label: 'Wyoming'},
    {value:"AB",label: 'Alberta'},
    {value:"BC",label: 'British Colombia'},
    {value:"MB",label: 'Manitoba'},
    {value:"NL",label: 'Newfoundland and Labrador'},
    {value:"NU",label: 'Nunavut'},
    {value:"ON",label: 'Ontario'},
    {value:"PE",label: 'Prince'},
    {value:"QC",label: 'Quebec'},
    {value:"SK",label:	'Saskatchewan'},
    {value:"YT",label:	'Yukon	Territory'},
  ]

  $scope.orderPrepStatuses = [
    anyOption,
    {label: 'Complete', value: 'Complete'},
    {label: 'Required', value: 'Required'},
    {label: 'Skipped', value: 'Skipped'},
    {label: 'Skipped and Complete', value: 'Skipped and Complete'}
  ]

  $scope.filter = $location.search();
  $scope.results = [];
 
  if($scope.filter.origin) delete $scope.filter.origin;
  if($scope.filter.app_guid) delete $scope.filter.app_guid;


 var destoryAttachedCollection
  $scope.page = function(number){
    $scope.filter.page = parseInt($scope.filter.page || 0) + number;
    $scope.search($scope.filter);
  };

  $scope.filter.sortColumn = $scope.filter.sortColumn || 'orderDate';
  $scope.filter.sortDirection = $scope.filter.sortDirection || 'asc';

  $scope.sortReverse = function(column) {

    if ($scope.filter.sortColumn === column) {
      $scope.filter.sortDirection = $scope.filter.sortDirection === "asc" ? "desc" : "asc";
    } else {
      $scope.filter.sortDirection = "asc";
    }

    $scope.filter.sortColumn = column;
    $scope.search($scope.filter, true);
  };

  $scope.lookNumberLimit = $location.search().limit ? $location.search().limit : 50;
  $location.search().limit = $scope.lookNumberLimit;

  $scope.search = function (filter, resetPage) {
  
      if($scope.filterDrawer)  {
        $scope.filterDrawer = false;
      }
  
      var filterCopy = angular.copy(filter) || {};
  
      if($scope.selectedVerificationStatus) {
        filterCopy.verificationStatus = $scope.selectedVerificationStatus.value;
      }
  
      if ($scope.selectedExternalStepStatus) {
        filterCopy.externalStepStatus = $scope.selectedExternalStepStatus.value;
      }
  
      if($scope.selectedDoNotSendOption) {
        filterCopy.doNotSendOption = $scope.selectedDoNotSendOption.value;
      }
  
      if ($scope.selectedSnoozeOption) {
        filterCopy.snoozeOption = $scope.selectedSnoozeOption.value;
      }
      if ($scope.selectedOpenTaskOption) {
        filterCopy.OpenTaskOption = $scope.selectedOpenTaskOption.value;
      }
      if ($scope.selectedState) {
        filterCopy.state = $scope.selectedState.value;
      }
  
      if($scope.selectedOrderPrepStatus) {
        filterCopy.orderPrepStatus = $scope.selectedOrderPrepStatus.value;
      }
  
      if(filterCopy.origin) delete filterCopy.origin;
      if(filterCopy.app_guid) delete filterCopy.app_guid;
  
      $scope.results = undefined;
  
  
      if(angular.isUndefined(filterCopy.status) && filterCopy.held != 'true') {
        filterCopy.status = '';
      }
  
      if(filterCopy.status == 'Hold' || (filterCopy.held == 'true' && angular.isUndefined(filterCopy.status))) {
        filterCopy.status = undefined;
        filterCopy.held = 'true';
      }
      else if (filterCopy.status == '') {
        filterCopy.held = undefined;
      }
      else {
        filterCopy.held = 'false';
      }
  
      if(resetPage){
        filterCopy.page = 0;
      }
  
      if (!filterCopy.solicited) {
        filterCopy.solicited = undefined;
      }
  
      if(!filterCopy.consumerOrders) {
        filterCopy.consumerOrders = undefined;
      }
  
      $location.search();
  
  
      filterCopy.fields = [
      'jobNumber',
      'company.name',
      'schema.name',
      'actors.looker.firstName',
      'actors.looker.lastName',
      'normalizedLookAddress.city',
      'normalizedLookAddress.state',
      'forms.client.createdAt',
      'dueDates',
      'actors.selfService.name',
      'report',
      'rushdelivery',
      'keywords',
      'schedule.time',
      'selfService',
      'notes',
      'status',
      'snooze',
      'verification',
      'orderPrep',
      'hold',
      'solicitations',
      'doNotSend'
      ].join(',');
  
      if (destoryAttachedCollection) destoryAttachedCollection();
      getter = Collection('looks').filter(filterCopy);
      if(filterCopy.OpenTaskOption){
      $http.get(`${API_ENDPOINT}/tasks?type=Recruiting Needed&status=Ready&fields=entity.look._id`)
    .then(result => {
      
      $scope.tasks = result.data;
      $scope.recruitingNeededLookId = $scope.tasks.map(function(task){
        return task.entity.look._id;
      });
    });
  }
      getter.get().success(function(results){
        if(filterCopy.OpenTaskOption == "hide_recruiting_needed_looks"){
          var recruitingNeeded = results.filter(function(res){
            for (var i=0;i < $scope.recruitingNeededLookId.length; i++){
              if ($scope.recruitingNeededLookId[i] == res._id) return false;
            }
            return true
          });
          $scope.results = recruitingNeeded
        }
        else{
          $scope.results = results
        }
        
      });
      

    
  };
 

  if($scope.filter.held == 'true') {
    $scope.filter.status = 'Hold';
  }

  /**
   * TODO: Refactor the below code so it is a bit easier to use
   */

    $scope.selectedVerificationStatus = $scope.verificationStatuses.find(function(verificationStatus) {
      return verificationStatus.value === $location.search().verificationStatus;
    });
 
    $scope.selectedExternalStepStatus = $scope.externalStepStatuses.find(function(status) {
      return status.value === $location.search().externalStepStatus;
    });

    $scope.selectedDoNotSendOption = $scope.doNotSendOptions.find(function(option) {
      return option.value === $location.search().doNotSendOption;
    });

    $scope.selectedSnoozeOption = $scope.snoozeOptions.find(function(option) {
      return option.value === $location.search().snoozeOption;
    });
    $scope.selectedOpenTaskOption = $scope.OpenTaskOptions.find(function(option){
      return option.value === $location.search().selectedOpenTaskOption;
    });
    $scope.selectedState = $scope.states.find(function(option) {
      return option.value === $location.search().state;
    });

    $scope.selectedOrderPrepStatus = $scope.orderPrepStatuses.find(function(status) {
      return status.value === $location.search().orderPrepStatus;
    });

  $scope.filterDrawer = false;

  $scope.toggleFilterBar = function() {
    if ($scope.filterDrawer) {
      $scope.search($scope.filter);
      $scope.filterDrawer = false;
    } else {
      $scope.filterDrawer = true;
      $('#status').focus();
    }
  }

  $scope.consumerOrdersWanted = function () {
    return $location.search().consumerOrders == 'false' || !$location.search().consumerOrders ? false : true;
  }

  CompaniesList
    .get({fields: 'name'})
    .then(function(data) {
      $scope.companies = _.map(data.data, function(company) {
        return {
          id: company._id,
          name: company.name
        }
      });
    });

    $scope.selectedCompanies = [];

    $scope.$watch('companies', function() {
      if(!_.isEmpty($scope.companies)) {
        $scope.selectedCompanies = $scope.companies.filter(function(company) {
          return $scope.filter.companies.indexOf(company.id) !== -1;
        });
      }
    });

    $scope.autocompleteParentControl = {};

    $scope.turnOffConsumerOrders = function() {
      $scope.filter.consumerOrders = false;
      $scope.search($scope.filter);
    }

    $scope.turnOffSolicited = function() {
      $scope.filter.solicited = false;
      $scope.search($scope.filter);
    }

    $scope.openFilterDrawer = function(target)  {
      $scope.filterDrawer = true;
      $(target).focus();
    }

    $scope.closeSideNav = function() {
      if ($scope.filterDrawer) {
        $scope.filterDrawer = false;
      }
    }

    $scope.showCompaniesList = function(bool) {
      $scope.companiesListOpen = bool;
    }

});
