(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .factory('socketService', socketService)

    function socketService(Session, ErrorMessage) {

      var errors;

      try {
        Session.socket.connect();
      } catch(err) {
        errors = err;
      }

      function createChannel(channelName) {
        return Session.socket.channel(channelName);
      }

      function getUserList(presences) {
        var userList = Presence
            .list(presences, listBy)
            .filter(function(user) {
              return user._id !== Session.user._id;
            });

        
        function listBy(user) {
          return {
            name: user,
            _id: presences[user].metas[0]._id
          }
        }
        return userList
      }

      return {
        createChannel: createChannel,
        getUserList: getUserList,
        err: errors
      }
    }
})();
