app.controller('StatusCtrl', function ($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage, LepReasons) {

  $scope.cancelType = false;
  $scope.categories = _.sortBy(LepReasons.late, 'reason');
  $scope.holdReasons = _.sortBy(LepReasons.hold, 'reason');
  $scope.cancelReasons = _.sortBy(LepReasons.cancel, 'reason');

  var Look = Collection('looks').Member($routeParams.lookId);
  Look.get().success(function (look) {
    $scope.look = look;
    $scope.Lookers = look.actors.looker;
    $scope.holdObj = look.hold;
    $scope.lateReason = look.lateReason;
    if (look.lateReason) {
      $scope.lateReason.selected = _.find($scope.categories, 'reason', look.lateReason.category);
      $scope.lateReason.explanation = look.lateReason.explanation;
    }
    $scope.changeCancelType($scope.cancelType);
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
  });

  var lookerFees = {
    USD: {
      notScheduled: 0,
     isScheduled: 5,
    },
    GBP: {
      notScheduled: 0,
      isScheduled: 10,
    },
    CAD: {
      notScheduled: 0,
      isScheduled: 10,
    },
    AUD: {
      notScheduled: 0,
      isScheduled: 10,
    }
  };
  $scope.changeCancelType = function () { 
    var price;
    var priceCurrency;
    // TODO: need to get the correct way of getting this data
    var lookerFeeCurrency = _.get($scope, 'look.lineItems[0].lookerFeeCurrency', 'USD');
    var LookerKey =  ( $scope.look.actors.looker && $scope.look.schedule && $scope.look.schedule.time) ? 'isScheduled' : 'notScheduled';
    var dispatched = _.get($scope,  'look.billing.dispatched', true);
    if ($scope.look.hasOwnProperty('selfService')) {
      price = _.get($scope, 'look.billing.cancellation.feeTypes.selfService.price.value');
      priceCurrency = _.get($scope, 'look.billing.cancellation.feeTypes.selfService.price.currency');
    }
     if(!$scope.look.selfService && $scope.look.billing.cancellation) {
      var dispatched = _.get($scope,  'look.billing.dispatched', true);
      price = dispatched
        ? _.get($scope, 'look.billing.cancellation.feeTypes.withDispatch.price.value')
        : _.get($scope, 'look.billing.cancellation.feeTypes.withoutDispatch.price.value');
      priceCurrency = dispatched
        ? _.get($scope, 'look.billing.cancellation.feeTypes.withDispatch.price.currency')
        : _.get($scope, 'look.billing.cancellation.feeTypes.withoutDispatch.price.currency');
    }
    if(!$scope.look.billing.cancellation){
      price = 0;
      priceCurrency = 'USD';
    } 
    
    $scope.cancelObj = { 
      lookerFee: lookerFees[lookerFeeCurrency][LookerKey],
      lookerFeeCurrency: lookerFeeCurrency,
      price: price/100,
      priceCurrency: priceCurrency,
      itemCode: $scope.look.billing.dispatched ? "400001" : "400002",
      name: $scope.look.billing.dispatched ? "Cancellation Fee (dispatched)" : "Cancellaton Fee (not dispatched)"
    };
  };

  $scope.isLate = function () {
    if (!$scope.look.dueDates.report) return false;

    // Modify 'now' and 'dueDate' with America/Chicago timezone
    const now     = moment().tz('America/Chicago');
    const dueDate = moment.tz(_.get($scope, 'look.dueDates.report'), 'America/Chicago');

    return now.isAfter(dueDate, 'days');
  };

  $scope.cancelLook = function () {
    if(!$scope.Lookers){
      if (($scope.cancelObj.lookerFee > 0)){
          return ErrorMessage.open('Cannot add a cancellation looker fee when no looker assigned.');
      };
      };

    if ($scope.isLate() && !$scope.lateReason) {
      return ErrorMessage.open('You need to provide a late reason for this look.');
    }
    if (!$scope.cancelObj.selected) {
      return ErrorMessage.open('Please give a reason for canceling the look.');
    }
    if (!$scope.cancelObj.dueTo) {
      return ErrorMessage.open('Please select a due to for the cancellation reason.');
    }
      if (!$scope.cancelObj.explanation) {
      return ErrorMessage.open('Please provide an explanation for the cancellation reason.');
    }

    $scope.cancelObj.reason = $scope.cancelObj.selected.reason;

    if ($scope.isLate()) {
      if(!$scope.lateReason.selected) return ErrorMessage.open('Please give a reason for the look being late.');
      if(!$scope.lateReason.dueTo) return ErrorMessage.open('Please select a due to for the late reason.');
      $scope.lateReason.category = $scope.lateReason.selected.reason;
      Look.Field('lateReason').update($scope.lateReason).success(function(response){
        SuccessMessage.open('Saved the the late reason.');

        var cancelClone = angular.copy($scope.cancelObj);
        cancelClone.price *= 100;
        cancelClone.lookerFee *= 100;

        Look.Field('cancellation').update(cancelClone).success(function () {
          SuccessMessage.open('Look has been canceled.');
          SidePanel.close();
        });

      }).error(function(response){
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
      });
    } else {
      var cancelClone = angular.copy($scope.cancelObj);
      cancelClone.price *= 100;
      cancelClone.lookerFee *= 100;
      Look.Field('cancellation').update(cancelClone).success(function () {
        SuccessMessage.open('Look has been canceled.');
        SidePanel.close();
      }).error(function(response){
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
      });
    }
  };

  $scope.holdLook = function () {
    if(!$scope.holdObj.selected) return ErrorMessage.open('Please give a reason for holding the look.');
    if(!$scope.holdObj.dueTo) return ErrorMessage.open('Please select a due to for the hold reason.');
    if(!$scope.holdObj.explanation) return ErrorMessage.open('Explanation is required.');
    var holdClone = angular.copy($scope.holdObj);
    holdClone.reason = $scope.holdObj.selected.reason;
    Look.Field('hold').update(holdClone).success(function () {
      SuccessMessage.open('Look has been put on hold.');
      SidePanel.close();
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });
  };

  $scope.unholdLook = function () {
    Look.Field('hold').delete().success(function () {
      SuccessMessage.open('Look has been put off hold.');
      SidePanel.close();
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });
  };
});
