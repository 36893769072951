app.service("Users", function($http, API_ENDPOINT){
  var usersCache = {}; // Used to make drilled down quicker after search.


  //this.myself = localStorage.getItem('user');
  this.createOrder = function(userId, look, callback){
    $http.post(API_ENDPOINT + 'users/'+userId+'/looks', look).success(callback);
  };

  this.search = function(options, callback) {
    $http.get(API_ENDPOINT + '/users', {
      params: options
    }).success(function(data) {
      usersCache = {};
      _.each(data, function(user){
        usersCache[user._id] = user;
      });
      callback(data);
    });
  };

  this.get = function(id, callback) {
    if (usersCache[id]) {
      $timeout(function(){
        callback(usersCache[id])
      }, 0);
    } else {
      $http.get(API_ENDPOINT + 'users/' + id).success(callback);
    }
  };
});
