app.controller('SolicitCtrl', function($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage){
    var Look = Collection('looks').Member($routeParams.lookId);
    var solicitationCanceled = false;

    Look.get().success(function(look){
      var requiredLicenses = _.get(look, 'requirements.licenses');
      $scope.solicitations = _.get(look, 'solicitations',[]);
      $scope.solicitations.find(element => {
         if(element['status'] === 'Canceled'){
            solicitationCanceled = true;
         }
      })
      $scope.isNotary = requiredLicenses ? requiredLicenses.indexOf('notary') !== -1 : false;
    });

    $scope.createSolicitation = function(){   
      $scope.createClicked = true;
      var skills = $scope.isNotary? ['notary'] : [];  
      if($scope.solicitations.length === 0){
        $scope.solicitations.type = 'Standard' 
     }
     if($scope.solicitations.length > 0){
         $scope.solicitations.type = 'Immediate'
     }
      if(solicitationCanceled) {
        var yesno = confirm("This look has a previously canceled solicitation, which means there may be no lookers available. Are you sure you want to solicit this look again?")
        
        if(yesno) {
            solicit(skills, $scope.solicitations.type);
        } else {
            SidePanel.close();
        }
     } else {
        solicit(skills, $scope.solicitations.type);
    }
    };

var solicit = function(skills , type){
        Look.Collection('solicitations').create({
            type: type,
            scheduledAt: {
              start: (new Date()).toISOString(),
              end:  new Date(new Date().getTime() + (parseInt(30, 10) * 60 * 1000)).toISOString()
            },
            skills: skills,
          }).success(function(response){
            SuccessMessage.open('Sent out solicitation.');
            SidePanel.close();
          }).error(function(response){
              response.message = response.message || 'Network Error, Please Try Again...';
              ErrorMessage.open(response.message);
          });
    }

});
