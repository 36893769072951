app.controller('WGLDueCtrl', function($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage){
  var Look = Collection('looks').Member($routeParams.lookId);
  Look.get().success(function(look){
    $scope.look = angular.copy(look);
    var reportDueDate = $scope.look.dueDates.report;
    $scope.reportDueDate = new Date(reportDueDate.year, reportDueDate.month, reportDueDate.date);
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
    SidePanel.close();
  });
  $scope.saveReportDueDate = function(){
    Look.Field('dueDates.report').update({date:$scope.reportDueDate.getDate(), year: $scope.reportDueDate.getFullYear(), month:$scope.reportDueDate.getMonth()}).success(function(data){
      SuccessMessage.open('Updated WGL Due Date.');
      SidePanel.close();
    }).error(function(response){
      response.message = response.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(response.message);
    });
  };
});
