app.controller('ClientsCtrl', function($scope, Collection, $location){
  var destoryAttachedCollection;
  $location.search({});
  $scope.filter = { role: 'client', limit: 50 };

  $scope.search = function(){
    $scope.results = undefined;
    if(destoryAttachedCollection) destoryAttachedCollection();
    destoryAttachedCollection = Collection('users').filter($scope.filter).attach($scope, 'results');
  };
})
