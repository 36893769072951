app.service('DisplayMessage', function ($http, $compile, $controller, $templateCache, $timeout, $rootScope) {
  $rootScope.MessageIsOpen = false;
  var templateScope;
  var templateCtrl;
  var message = angular.element('#Message');
  var messageContent = angular.element('#Message_Content');
  var closeButton = angular.element('#Message_Close');
  var timedClosed;
  closeButton.on('click', function () {
    $timeout(this.close, 150);
  }.bind(this));
  $rootScope.$on('$routeChangeStart', function (event, toState, toParams, fromState, fromParams) {
    this.close();
  }.bind(this));
  this.open = function (options, content) {
    options = options || {};
    if ($rootScope.MessageIsOpen) this.close();
    $timeout(function () {
      $rootScope.MessageIsOpen = true;
      messageContent.html(content);
      if(options.color){
        message.css({ color: options.color });
      }
      if(options.BGColor){
        message.css({ backgroundColor: options.BGColor });
      }
      if(options.closeColor){
        closeButton.css({ color: options.cxwolor });
      }
      if(options.closeBGColor){
        closeButton.css({ backgroundColor: options.closeBGColor });
      }
      if (angular.isDefined(options.autoclose)) {
        timedClosed = setTimeout(function () {
          this.close();
        }.bind(this), options.autoclose);
      }
    }.bind(this), 150);
  };
  this.close = function () {
    if ($rootScope.MessageIsOpen) {
      if (timedClosed) timedClosed = clearTimeout(timedClosed);
      $rootScope.MessageIsOpen = false;
    };
  };
});
