(function () {
    'use strict'
  
    angular
      .module('OMSAdmin')
      .factory('NotesList', function ($http) {
        var notes = [];
        return {
          get: function (lookId) {
            return $http.get(`${API_ENDPOINT}/notes?look=${encodeURIComponent(lookId)}`).then(function(response){
              
              notes = response.data;
              return response;
            });
          },
        }
      })
  })();
  