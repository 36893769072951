app.controller('CompanyCtrl', function($scope, Collection, $location, $routeParams, API_ENDPOINT, $http, SuccessMessage, ErrorMessage){
  $scope.q = '';
  $scope.schemas = [];

  $http.get(`${API_ENDPOINT}/companies/${encodeURIComponent($routeParams.companyId)}`).then(result => {
    $scope.company = result.data;
  });

  $http.get(`${API_ENDPOINT}/schemas`, { params: {company: 'all', fields: 'name,type'}}).then(result => {
    $scope.schemas = result.data;
  });

  $scope.remove = function(schema) {
    var index = $scope.company.schemas.indexOf(schema);
    $scope.company.schemas.splice(index, 1);
  };

  $scope.moveUp = function(schema) {
    var index = $scope.company.schemas.indexOf(schema);
    if (index > 0) {
      const elementAbove = $scope.company.schemas[index - 1];
      $scope.company.schemas[index - 1] = schema;
      $scope.company.schemas[index] = elementAbove;
    }
  };

  $scope.moveDown = function(schema) {
    var index = $scope.company.schemas.indexOf(schema);
    if (index < $scope.company.schemas.length - 1) {
      const elementBelow = $scope.company.schemas[index + 1];
      $scope.company.schemas[index + 1] = schema;
      $scope.company.schemas[index] = elementBelow;
    }
  };

  $scope.addSchema = function(type) {
    const schema = {
      type: type,
      props: {},
      sms: {
        'onsite-verification': true
      },
      emails: {
        'onsite-contact': true,
        'order-confirmation': true,
        'looker-assignment': true,
        'order-complete': true,
        'onsite-verification': true,
        'order-cancelled': true
      }
    };

    $scope.company.schemas.push(schema);
  };

  $scope.submitSchemas = function(company) {
    $http.put(`${API_ENDPOINT}/companies/${encodeURIComponent($routeParams.companyId)}/schemas`, company.schemas).then(result => {
      if(result.status !== 200) {
        ErrorMessage.open('Something went wrong.');
      } else {
        SuccessMessage.open(`Schemas updated for ${company.name}.`);
      }
    });
  };

});
