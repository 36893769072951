'use strict';

angular
   .module('OMSAdmin')
   .directive('wglNav', mainNav);

function mainNav () {
  var directive = {
    templateUrl: '/app/Navigation/template.html',
    restrict: 'AE',
    controller: 'NavigationCtrl'
  };
  return directive;
}
