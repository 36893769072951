(function(){
  'use strict';
 
  angular
      .module('OMSAdmin')
      .directive('formatTask', function() {
 
          return {
              restrict: 'E',
              scope: {
                  'task': '='
              },
              templateUrl: "/common/directives/templates/FormattedTask.html",
              link: function(scope, element, attr) {
                if (scope.task.history) {
                  angular.forEach(scope.task.history, function(history) {
                    if (history.status === 'Failed') {
                      scope.task.failed = true;
                    }
                  });
                }
                scope.task = scope.task;
              }
          }  
      })
 
 })();
