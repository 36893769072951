
app.controller("FeesCtrl", function($scope, SidePanel, Collection, SuccessMessage, ErrorMessage, $routeParams, Uploads) {
  function filterInPlace(comparator, arr) {
    var newArray = [];
    var item;

    while (arr.length) {
      item = arr.shift();

      if (comparator(item)) {
        newArray.push(item);
      }
    }

    while (newArray.length) {
      arr.push(newArray.shift());
    }
  }

  $scope.currencyMap = wglCurrency.map;

  var Look = Collection('looks').Member($routeParams.lookId);
  Look.get().success(function(look){
    $scope.look = look;
    $scope.lookerFeeCurrency = $scope.currencyMap[look.normalizedLookAddress.country];
  });

  var User = Collection('users').Member($routeParams.user);
  User.get().success(function(user){
    $scope.orderingUser = user;
  });

  var LineItems = Look.Collection('lineItems');
  LineItems.get().success(function(lineItems){
    $scope.lineItems = lineItems;
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
    SidePanel.close();
  });

  Look.get().success(function(data) {
    $scope.jobNumber = data.jobNumber;
  }).error(function(response) {
    ErrorMessage.open(response.message || 'Network Error, Please Try Again...');
  });

  Collection('products').attach($scope, 'products');

  $scope.$watch('selectedFee.lookerFee', function(newValue, oldValue) {
    if($scope.selectedFee && newValue && newValue !== 0) {
      $scope.selectedFee.priceCurrency = $scope.selectedFee.lookerFeeCurrency;
    }
  });
  const getPreviouslyAssignedLookersFromAssignments = (assignments) => {
    const previouslyAssignedLookers = assignments.filter(assignment => assignment.type === 'assignment').map(assignment => assignment.looker).filter(function (looker, pos, lookers) {
      return lookers.indexOf(looker) == pos;
    });
  
    return previouslyAssignedLookers.reverse();
  }

  $scope.populateFee = function(){
    angular.forEach($scope.products, function(item){
      if($scope.selectedFee.itemCode == item.itemCode){
        $scope.selectedFee.price = item.price / 100;
        $scope.selectedFee.priceCurrency =
          item.priceCurrency || $scope.currencyMap[$scope.look.normalizedLookAddress.country] || 'USD';
        $scope.selectedFee.category = item.category;
        $scope.selectedFee.name = item.name;
        $scope.selectedFee.displayName = item.displayName;
        $scope.selectedFee.lookerFee = item.lookerFee / 100 || 0;
        $scope.selectedFee.lookerFeeCurrency =
          item.lookerFeeCurrency || $scope.currencyMap[$scope.look.normalizedLookAddress.country] || 'USD';
          $scope.previouslyAssignedLookers = getPreviouslyAssignedLookersFromAssignments($scope.look.assignments);
          $scope.filteredLooker = $scope.previouslyAssignedLookers[0];
      }
    });
  };
  $scope.editFee = function(fee){
    if (fee.category === 'delivery' || fee.category == 'Options') {
      return false;
    }
    fee = angular.copy(fee);
    fee.lookerFee /= 100;
    fee.price /= 100;
    fee.priceCurrency = fee.priceCurrency || 'USD';
    fee.lookerFeeCurrency = fee.lookerFeeCurrency || 'USD';
    $scope.selectedFee = fee;
    $scope.previouslyAssignedLookers = getPreviouslyAssignedLookersFromAssignments($scope.look.assignments);
    $scope.filteredLooker = $scope.previouslyAssignedLookers.find(function(value){
      return _.isEqual(value, fee.looker);
    })
    
  };
  $scope.cancelFee = function(){
    $scope.selectedFee = null;
  };
  $scope.newFee = function(){
    $scope.selectedFee = {
      lookerFeeCurrency: $scope.lookerFeeCurrency,
      priceCurrency: $scope.lookerFeeCurrency
    };
  };

  $scope.saveFee = function(fee){

    function save(fee) {
      if(fee._id){
        $scope.updateFee(fee);
      }
      else {
        $scope.addFee(fee);
      }
    }

    if (fee.receipt && typeof(fee.receipt) !== 'string') {
      Look.Collection('forms').Member('looker').Collection('uploads').create({fileName: fee.receipt[0].name}).success(function(urls) {
        Uploads.put(urls.signedUrl, fee.receipt).then(function(response) {
          fee.receipt = urls.baseUrl;
          save(fee);
        }).catch(function(error) {
          return ErrorMessage.open('A problem occurred while saving receipt.');
        });
      }).error(function(response) {
        return ErrorMessage.open('A problem occurred while adding receipt.');
      });
    } else {
      save(fee);
    }
  }
  $scope.addFee = function(fee){
    if(angular.isUndefined(fee.name)) {
      return ErrorMessage.open('You must select a line item type.');
    }
    var newFee = angular.copy(fee);
    if ($scope.selectedFee.name === 'Dispatch Fee') {
      newFee.looker = $scope.filteredLooker;
    }

    newFee.lookerFee *= 100;
    newFee.price *= 100;
    LineItems.create(newFee).success(function(data){
      $scope.lineItems.push(data);
      $scope.selectedFee = null;
    }).error(function(response) {
      response.message = response.message || 'Network Error. Please try again.';
      ErrorMessage.open(response.message);
    });
  };
  $scope.updateFee = function(fee){
    if(angular.isUndefined(fee.name)) {
      return ErrorMessage.open('You must select a line item type.');
    }
    var updatedFee = angular.copy(fee);

    if ($scope.selectedFee.name === 'Dispatch Fee') {
      updatedFee.looker = $scope.filteredLooker;
    }
    updatedFee.lookerFee *= 100;
    updatedFee.price *= 100;
    LineItems.Member(fee._id).update(updatedFee).success(function(data){
      angular.forEach($scope.lineItems, function(lineItem, index){
        if(lineItem._id == fee._id) $scope.lineItems[index] = data;
      });
      $scope.selectedFee = null;
    }).error(function(response){
      response.message = response.message || 'Network Error. Please try again.';
      ErrorMessage.open(response.message);
    });;
  };

  $scope.deleteFee = function(fee){
    LineItems.Member(fee._id).delete().success(function() {
      SuccessMessage.open('Fee removed');
      filterInPlace(function(item) {
        return item._id !== fee._id;
      }, $scope.lineItems);
      $scope.selectedFee = null;
    }).catch(function(response) {
      ErrorMessage.open(response.data.message || 'Network Error. Please try again.');
    });
  };

});
