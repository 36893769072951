(function(){
    'use strict';

    angular
        .module('OMSAdmin')
        .directive('formatNote', function() {

            return {
                restrict: 'E',
                scope: {
                    'note': '='
                },
                templateUrl: "/common/directives/templates/FormattedNote.html",
                link: function(scope, element, attr) {
                    var options = {
                        simpleLineBreaks: true,
                        disableForced4SpacesIndentedSublists: true,
                        literalMidWordAsterisks: true,
                        literalMidWordUnderscores: true,
                        excludeTrailingPunctuationFromURLs: true,
                        simplifiedAutoLink: true,
                        strikethrough: true,
                        headerLevelStart: 1
                    }
                    scope.isLookerNote = _.startsWith(scope.note.body, '(Looker)');

                    //TODO: after the update note api has been created
                    scope.updateNote = function (noteId) {
                      //console.log('update note id is', noteId);
                    }

                    var converter = new showdown.Converter(options);
                    scope.note.body = converter.makeHtml(scope.note.body);
                }
            }
        })

})();
