(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .controller('DNSCtrl', dnsCtrl);

  function dnsCtrl(
    $scope, 
    Collection, 
    $routeParams, 
    SidePanel, 
    SuccessMessage, 
    ErrorMessage
  ) {

    $scope.dnsReasons = [
      {id: 'DNS-01', reason: 'Client Request not met'},
      {id: 'DNS-02', reason: 'Schema Request not met'},
      {id: 'DNS-03', reason: 'Incorrect or Missing measurements'},
      {id: 'DNS-04', reason: 'Incorrect Location Due to Client'},
      {id: 'DNS-05', reason: 'Incorrect Location Due to Looker'},
      {id: 'DNS-06', reason: 'Incorrect Location Due to WeGoLook'},
      {id: 'DNS-07', reason: 'Incorrect Subject'},
      {id: 'DNS-08', reason: 'Poor Image Quality'},
      
    ]

    var Look = Collection('looks').Member($routeParams.lookId);    

    Look.get().success(function(look) {
      if (!look.doNotSend) {
        return;
      }

      $scope.dnsReason = $scope.dnsReasons.find(function(option) {
          return option.reason === look.doNotSend.reason;
        });
    }).error(function(res) {
      res.message = res.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(res.message);
    })

    $scope.saveDNSReason = function() {
      if ($scope.dnsReason) {
        Look.Field('doNotSend').update({
          reason: $scope.dnsReason.reason
        })
        .success(function(res) {
          SuccessMessage.open('Saved DNS reason.');
          SidePanel.close();
          createNote($scope.dnsReason);
        })
        .error(function(res) {
          ErrorMessage.open(res.message || 'Network Error, Please Try Again...');
        })
      }
      function createNote(reason) {
        Collection('looks').Member($routeParams.lookId).Collection('notes').create({
          body: `DNS -  ${reason.reason}`
        }).success(function(data) {
          SuccessMessage.open('Note added successfully');
        }).error(function(err) {
          ErrorMessage.open(err.message || 'Network Error, Please Try Again...');
        })
      }
    }
  }

})();
