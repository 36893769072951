app.factory('Collection', function($http, $rootScope, $timeout, $cacheFactory, API_ENDPOINT) {
  var pollingResources = {};
  // var tempDataResourceCache = $cacheFactory('collectionCache');
  var setScopeVar = function(scope, variableName, options, data){
    if(options && typeof options.once == 'function') {
      options.once(data);
      options.once = null;
    }
    if(options && options.transform) {
      scope[variableName] = options.transform(data);
    }
    else {
      scope[variableName] = data;
    }
  };
  var nextTimeout;
  var poll = function(){
    var requestChain;
    var numberOfResources = Object.keys(pollingResources);
    var updateListeners = function(listeners, APIPath, data){
      // tempDataResourceCache.put(APIPath, data);
      angular.forEach(listeners, function(listener){
        setScopeVar(listener.scope, listener.variableName, listener.options, data)
      });
    };
    angular.forEach(pollingResources, function(listeners, APIPath){
      if(requestChain) {
        requestChain = requestChain.then(function(){
          return $http.get(APIPath).success(updateListeners.bind({}, listeners, APIPath));
        });
      }
      else {
        requestChain = $http.get(APIPath).success(updateListeners.bind({}, listeners, APIPath));;
      }
    });
    if(numberOfResources > 0){
      requestChain.then(function(){
        nextTimeout = setTimeout(poll, 300000);
      });
    }
    else {
      nextTimeout = setTimeout(poll, 300000);
    }
  };
  var forcePoll = function(){
    nextTimeout = clearTimeout(nextTimeout);
    nextTimeout = setTimeout(poll, 200);
  };

  poll();


  var serializeParams = function(obj, prefix) {

    var str = [];
    for(var p in obj) {
      if (_.isArray(obj[p])){
        obj[p].forEach(function(el) {
          str.push( p + "=" + el);
        });
      } else if (obj.hasOwnProperty(p) && angular.isDefined(obj[p])) {
        var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push(
          typeof v == "object"
            ? serializeParams(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };


  var Collection = function(previousAPIPath, CollectionName){
    var newPath = previousAPIPath + '/' + CollectionName;
    return {
      filter: Filter.bind({}, newPath),
      Member: Member.bind({}, newPath),
      get: Get.bind({}, newPath),
      attach: attach.bind({}, newPath),
      create: Create.bind({}, newPath)
    }
  };
  var Member = function(previousAPIPath, MemberID){
    var newPath = previousAPIPath + '/' + MemberID;
    return {
      attach: attach.bind({}, newPath),
      Collection: Collection.bind({}, newPath),
      get: Get.bind({}, newPath),
      update: Update.bind({}, newPath),
      delete: Delete.bind({}, newPath),
      Field: Field.bind({}, newPath)
    }
  };
  var Field = function(previousAPIPath, Field){
    var newPath = previousAPIPath + '/' + Field.replace(/\./g, '/');
    return {
      Collection: Collection.bind({}, newPath),
      attach: attach.bind({}, newPath),
      get: Get.bind({}, newPath),
      update: Update.bind({}, newPath),
      delete: Delete.bind({}, newPath)
  }
  };
  var Create = function(APIPath, data, config){
    if(typeof config === 'undefined') { config = {}; }

    return $http.post(APIPath, data, config).success(forcePoll);
  };
  var Delete = function(APIPath){
    return $http.delete(APIPath).success(forcePoll);
  };
  var Update = function(APIPath, data){
    return $http.put(APIPath, data).success(forcePoll);
  };
  var Get = function(APIPath){
    return $http.get(APIPath)//, {cache: tempDataResourceCache});
  };
  var Filter = function(APIPath, filters){
    var newPath = APIPath + '?' + serializeParams(filters);
    return {
      get: Get.bind({}, newPath),
      attach: attach.bind({}, newPath)
    }
  };

  var attach = function(APIPath, scope, variableName, options){
    if(!pollingResources[APIPath]){
      pollingResources[APIPath] = [{
        scope: scope,
        variableName: variableName,
        options: options
      }];
    }
    else {
      pollingResources[APIPath].push({
        scope: scope,
        variableName: variableName,
        options: options
      });
    }

    // $timeout(function(scope, variableName, options, APIPath){
    //   var dataCache = tempDataResourceCache.get(APIPath);
    //   if(dataCache) setScopeVar(scope, variableName, options, dataCache);
    // }.bind({}, scope, variableName, options, APIPath));

    forcePoll();

    var listener = pollingResources[APIPath][pollingResources[APIPath].length-1];
    var removeListener = function(APIPath, listener){
      if(!pollingResources[APIPath]) return;
      var listenerIndex = pollingResources[APIPath].indexOf(listener);
      if(listenerIndex+1) {
        pollingResources[APIPath].splice(listenerIndex, 1);
      }
      if(!pollingResources[APIPath].length){
        delete pollingResources[APIPath];
        // tempDataResourceCache.remove(APIPath);
      }
    };
    var destroyDestroyListener = scope.$on('$destroy', removeListener.bind({}, APIPath, listener));
    return function(APIPath, listener){
      destroyDestroyListener();
      removeListener(APIPath, listener);
    }.bind({}, APIPath, listener);
  };

  return Collection.bind({}, API_ENDPOINT);
});
