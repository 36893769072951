app
.service('AuthService', function ($http, $location, Session, jwtHelper, ErrorMessage) {
  var authService = {};
  var tokenRenewalTimeout;

  var a0 = new auth0.WebAuth({
    domain: 'wegolook-stage.auth0.com',
    clientID: '6uEzjCefNbX6rhPxZz97A1T35Dlpm9X4',
    redirectUri: `${window.location.origin}/callback`,
    audience: 'https://apistaging.wegolook.com',
    connection: 'prostaging',
    responseType: 'token id_token',
    scope: 'openid',
    prompt: 'none'
  });

  authService.authorize = () => {
    a0.authorize();
  };

  authService.loginSuccessCallback = () => {
    a0.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        console.log('Error parsing auth0 hash', err, err.errorDescription);
        loginFailureCallback();
        return;
      }

      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      const accessToken = authResult.accessToken;

      var sessionData = getSessionDataFromToken(accessToken);
      Session.create(sessionData);

      setTokenAndExpires(accessToken, expiresAt);

      window.location.replace('/');
    });
  }

  function setTokenAndExpires(token, expiresAt) {
    localStorage['stache'] = token;
    localStorage['expires_at'] = expiresAt;

    scheduleRenewal();
  }

  function renewToken() {
    a0.checkSession({}, (err, authResult) => {
        if (err) {
          console.log('Error checking session:', err);
        } else {
          const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
          const accessToken = authResult.accessToken;
          setTokenAndExpires(accessToken, expiresAt);
        }
      }
    );
  }

  function scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      tokenRenewalTimeout = setTimeout(() => {
        renewToken();
      }, delay);
    }
  }

  authService.scheduleRenewal = scheduleRenewal;





  function getSessionDataFromToken(token) {
    var decodedToken = jwtHelper.decodeToken(token);
    var user = _.get(decodedToken, 'https://wegolook.com/user');
    var sessionData = {
      token: token,
      roles: _.get(user, 'roles'),
      user: user,
    };

    return sessionData;
  }


  function loginFailureCallback(response) {
    ErrorMessage.open("Login username or password incorrect.")
  }

  authService.login = function (credentials) {
    $http
    .post(`${API_ENDPOINT}/tokens`, credentials)
    .then(loginSuccessCallback, loginFailureCallback)
  };

  authService.logout = function () {
    clearTimeout(tokenRenewalTimeout);
    localStorage.removeItem('stache');
    localStorage.removeItem('expires_at');
    Session.destroy();
    location.reload();
  };

  authService.verify = function () {
    var token = localStorage.getItem('stache');
    const expiresAt = !!localStorage.getItem('expires_at') && localStorage.getItem('expires_at');

    // For auth0 switch over, once users have been migrated we can remove this
    if(!expiresAt) {
      return;
    }

    if (token) {
      var sessionData = getSessionDataFromToken(token);
      Session.create(sessionData);
    }
  }

  authService.isAuthenticated = function () {
    const validToken = !!localStorage.getItem('stache');
    const expiresAt = !!localStorage.getItem('expires_at') && localStorage.getItem('expires_at');

    // For auth0 switch over, once users have been migrated we can remove this
    if(!expiresAt) {
      return false;
    }

    const notExpired = new Date().getTime() < expiresAt; 
    return validToken && notExpired;
  };

  authService.isAuthorized = function (authorizedRoles) {
    if (!angular.isArray(authorizedRoles)) {
      authorizedRoles = [authorizedRoles];
    }
    return (authService.isAuthenticated() &&
      _.intersection(Session.user.roles, authorizedRoles).length > 0)
  };

  return authService;
})
