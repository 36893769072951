(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .directive('wglAutocomplete', function() {
      return {
        restrict: 'A',
        scope: {
          filter: "=",
          control: "="
        },
        templateUrl: "/common/directives/templates/Autocomplete.html",
        link: function(scope, element, attributes) {
          
          scope.autocompleteOption = attributes.model;
          scope.autocompleteTouched = false;
          scope.autocompletePartial = "";
          scope.selected = [];
          scope.itemsList = [];

          if (!scope.filter[attributes.model]) {
            scope.filter[attributes.model] = [];
          }

          scope.$parent.$watch(attributes.model, function(newList) {
            scope.selected = [];
            scope.itemsList = [];
            if (!_.isEmpty(newList)) {
              newList.forEach(function(item) {
                var check = scope.filter[attributes.model].indexOf(item.id);
                if (check !== -1) {
                  scope.selected.push(item);
                } else {
                  scope.itemsList.push(item);
                }
              });
            }
          });

          scope.setAutocompleteInputFocus = function() {
            $('#'+attributes.model+'-autocomplete-input').focus();
          }

          scope.autocompleteFocusStyle = function(bool) {
              scope.autocompleteFocus = bool;
          }

          scope.genAutocompleteList = function() {
            if (_.isEmpty(scope.autocompletePartial)) {
              reset(scope);
            } else {
              scope.autocompleteItems = scope.itemsList
                .filter(function(item){
                  var lowerCasedItem = item.name.toLowerCase();
                  var partial = scope.autocompletePartial.toLowerCase();
                  return lowerCasedItem.indexOf(partial) === 0;
                })
                .sort(function(a,b) { b.name - a.name});

              scope.autocompleteTouched = true;
              scope.leftovers = scope.autocompleteItems.length - 5;
            }
          }

          scope.selectItem = function(item, parentCheck) {
            var inSelectedArray = scope.selected.findIndex(function(el) {
              return item.id === el.id;
            });
            if (inSelectedArray === -1) {
              var itemIndex = scope.itemsList.findIndex(function(el) {
                return el.id === item.id;
              })
              scope.selected.push(scope.itemsList.splice(itemIndex, 1)[0]);
              
            } else {
              scope.itemsList.push(scope.selected.splice(inSelectedArray, 1)[0]);
            }
            scope.filter[attributes.model] = scope.selected.map(function(item){ 
                return item.id;
            });
            reset(scope);
            if(parentCheck) {
              scope.$parent.search(scope.filter);
            }
          }
      
          function reset(scope) {
            scope.autocompleteItems = [];
            scope.autocompleteTouched = false;
            scope.autocompletePartial = "";
            scope.leftovers = 0;
          }

          scope.control.selectItem = scope.selectItem;
          scope.control.autocompleteUntouched = function() {
            scope.autocompleteTouched = false;
          }
        }
      }
    });

})();
