app.controller('LookerDueCtrl', function($scope, Collection, SidePanel, SuccessMessage, ErrorMessage, $routeParams){
  var Look = Collection('looks').Member($routeParams.lookId);
  Look.get().success(function(look){
    $scope.look = angular.copy(look);
    var lookerDueDate = $scope.look.dueDates.looker;
    $scope.lookerDueDate = new Date(lookerDueDate.year, lookerDueDate.month, lookerDueDate.date);
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
    SidePanel.close();
  });

  $scope.saveLookerDueDate = function(){
    Look.Field('dueDates.looker').update({date:$scope.lookerDueDate.getDate(), year: $scope.lookerDueDate.getFullYear(), month:$scope.lookerDueDate.getMonth()}).success(function(data){
      SidePanel.close();
    }).error(function(response){
      response.message = response.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(response.message);
    });
  };
});
