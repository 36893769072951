(function() {
    'use strict'

    angular
        .module('OMSAdmin')
        .filter('charLimit',charLimit)

    function charLimit() {
        return function(input, charLimit) {
            if (input.length > charLimit) {
                return input.slice(0, charLimit) + "...";
            } else {
                return input;
            }
        }
    }

})();
