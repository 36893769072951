app.controller('TasksPageCtrl', function($scope, Collection, SuccessMessage, ErrorMessage, $location, $http, MomentHelper, CompaniesList, TemplateHelper){
  
  $scope.statusOptions = TaskDefinitions.statuses.map(status => ({label: status, value: status}));
  $scope.statusOptions.unshift({label: "Any", value: undefined});

  $scope.departmentOptions = TaskDefinitions.groupQueues.map(status => ({label: status, value: status}));
  $scope.groupOptions = TaskDefinitions.groupQueues.map(status => ({label: status, value: status})); //for reassigning department
  $scope.departmentOptions.unshift({label: "Any", value: undefined});
  $scope.typeOptions = TaskDefinitions.types.map(type => ({label: type, value: type}));
  $scope.typeOptions.unshift({label: "Any", value: undefined});
  $scope.internalOptions = [
    {label: 'Any', value: undefined},
    {label: 'True', value: 'true'},
    {label: 'False', value: 'false'}
  ]
   $scope.isAssignedOptions = [
    {label: 'Any', value: undefined},
    {label: 'True', value: 'true'},
    {label: 'False', value: 'false'}
  ]
  $scope.activeOptions = [
    {label: 'Active', value: 'true'},
    {label: 'Any', value: 'false'}
  ]

  $scope.openFilterDrawer = function(target)  {
    $scope.filterDrawer = true;
    $(target).focus();
  }
  $scope.toggleFilterBar = function() {
    if ($scope.filterDrawer) {
      $scope.search($scope.filter);
      $scope.filterDrawer = false;
    } else {
      $scope.filterDrawer = true;
      $('#status').focus();
    }
  }

  $scope.filterDrawer = false;

  var destoryAttachedCollection;
  $scope.filter = $location.search() || {};
  $scope.page = function(number){
    $scope.filter.page = parseInt($scope.filter.page || 0) + number;
    $scope.search($scope.filter);
  };
  $scope.filter.sortColumn = $scope.filter.sortColumn || 'createdAt';
  $scope.filter.sortDirection = $scope.filter.sortDirection || 'asc';

  $scope.sortReverse = function(column) {

    if ($scope.filter.sortColumn === column) {
      $scope.filter.sortDirection = $scope.filter.sortDirection === "asc" ? "desc" : "asc";
    } else {
      $scope.filter.sortDirection = "asc";
    }

    $scope.filter.sortColumn = column;
    $scope.search($scope.filter, true);
  };

  $scope.pageTaskLimit = $location.search().limit ? $location.search().limit : 50;
  $location.search().limit = $scope.pageTaskLimit;
  $scope.showStatusOption = false;
  $scope.claimTask = function (task) {
    const userDisplayName = `${$scope.user.firstName + ' ' + $scope.user.lastName}`
    if (task && (task.internal === true || task.type === 'MVR Check' || task.type === 'Looker Schedule Look')) {
      const assignedParties = {
        assignedTo: [
          {
            _id: $scope.user._id,
          }
        ],
        status: "In Progress"
      }
      $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, assignedParties).then(function (response) {
        if (response.status !== 200) {
          ErrorMessage.open('Something went wrong.');
        } else {
          SuccessMessage.open(`Task successfully claimed by ${userDisplayName}`);
          task.status = "In Progress";
          window.location.reload();
        }
      })
        .catch(err => {
          console.error(err);
          ErrorMessage.open('Something went wrong.');
        });
    }
}
  $scope.unclaimTask = function (task, unassignUser) {
    const assignedParties = {
      assignedTo: [
        {
          _id: unassignUser._id,
          isActive: false
        }
      ]
    };
    $http.patch(`${API_ENDPOINT}/tasks/${task._id}`, assignedParties).then(function (response) {
      if (response.status !== 200) {
        ErrorMessage.open('Something went wrong.');
      } else {
        SuccessMessage.open(`Task successfully unclaimed`);
        window.location.reload();
      }
    })
      .catch(err => {
        console.error(err);
        ErrorMessage.open('Something went wrong.');
      });
  }
  $scope.search = function(filter, pageReset){
    var filterCopy = angular.copy(filter) || {};

    $scope.results = undefined;

    if(!filter.status) filterCopy.status = undefined;

    if(pageReset){
      filterCopy.page = 0;
    }

    if ($scope.selectedStatus) {
      filterCopy.status = $scope.selectedStatus.value;
    }
    if ($scope.selectedInternalOption) {
      filterCopy.internal = $scope.selectedInternalOption.value;
    }
    if ($scope.selectedActiveOption) {
      filterCopy.active = $scope.selectedActiveOption.value;
      if(filterCopy.active === 'true'){
        filterCopy.status = '';
      }
    }
    if ($scope.selectedIsAssignedOption) {
      filterCopy.is_assigned = $scope.selectedIsAssignedOption.value;
    }

    if($scope.selectedDepartmentOption) {
      filterCopy.department = $scope.selectedDepartmentOption.value;
    }
      if($scope.selectedTypeOption) {
      filterCopy.type = $scope.selectedTypeOption.value;
    }
    $location.search(filterCopy);

    if(destoryAttachedCollection) destoryAttachedCollection();
    destoryAttachedCollection = Collection('tasks').filter($scope.filter).attach($scope, 'results');
  };

  $scope.search($scope.filter);

$scope.taskRowClass = function(task){
if(task.dueTime){
  const isAlmostDue = (moment(task.dueTime).isBetween(moment() - 1, moment().add(2, 'hours') + 1))
if(moment(task.dueTime).isBefore(moment())){
  return 'overDue-color';
}
if(isAlmostDue){
return 'almostDue-color';
}
return '';
}
}

$scope.visible = false;
$scope.confirm = false;
$scope.setVisible = function(task){
  $scope.taskId = task._id
 $scope.visible = true;
 $scope.confirm = true;
 }

$scope.reassignGroup = function(){
  const groupQueues = {
    groupQueue: $scope.selectedGroupOption.value
  }
  $http.patch(`${API_ENDPOINT}/tasks/${$scope.taskId}`, groupQueues).then(function (response) {
    if (response.status !== 200) {
      ErrorMessage.open('Something went wrong.');
    } else {
      SuccessMessage.open(`Task group reassigned!`);
      setInterval(()=>{
        window.location.reload();
      },2000)
   
    }
  })
    .catch(err => {
      console.error(err);
      ErrorMessage.open('Something went wrong.');
    });
 
}
   $scope.selectedStatus = $scope.statusOptions.find(function(status) {
     return status.value === $location.search().status;
   });

  $scope.selectedDepartmentOption = $scope.departmentOptions.find(function(option) {
    return option.value === $location.search().department;
  })
  $scope.selectedInternalOption = $scope.internalOptions.find(function(option) {
    return option.value === $location.search().internal;
  })
  $scope.selectedActiveOption = $scope.activeOptions.find(function(option) {
    return option.value === $location.search().active;
  })
   $scope.selectedIsAssignedOption = $scope.isAssignedOptions.find(function(option) {
    return option.value === $location.search().is_assigned;
  })

  $scope.selectedTypeOption = $scope.typeOptions.find(function(option) {
    return option.value === $location.search().type;
  })
});
