app.factory('MomentHelper', function() {

  var abbrsToFullName = {
    AKST : 'Alaska Standard Time',
    AKDT : 'Alaska Daylight Time',
    EST : 'Eastern Standard Time',
    EDT : 'Eastern Daylight Time',
    CST : 'Central Standard Time',
    CDT : 'Central Daylight Time',
    HST : 'Hawaii Standard Time',
    HDT : 'Hawaii Daylight Time',
    MST : 'Mountain Standard Time',
    MDT : 'Mountain Daylight Time',
    PST : 'Pacific Standard Time',
    PDT : 'Pacific Daylight Time',
  };

  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrsToFullName[abbr] || abbr;
  };

  /*
  * gets the full name of a timezone
  * @param abbr, a string shorthand for the timezone e.g. ('EST')
  * @return the full name of the timezone
  */
  var getTimezoneFull = function (abbr) {
    return abbrsToFullName[abbr];
  };

  /*
  * gets the timezone of a look object
  * @param look, a look object
  * @return a string representation of the look's timezone
  */
  var getTimezone = function (look) {
    return _.get(look, 'timezone.timeZoneId')
    || _.get(look, 'schedule.time.timezone', null)
    || 'America/Chicago';
  };

  /*
  * gets the short hand timezone abbreviation (PST, PDT)
  * @param timezone, string representation of the timezone e.g. ('America/Los_Angeles')
  * @param timestamp, integer timestamp for determining Daylight vs Standard e.g. (1403465838805)
  * @return string abbreviation of the timezone at a given timestamp
  */
  var getTimezoneAbbr = function (timezone, timestamp) {
    return moment.tz.zone(timezone).abbr(timestamp);
  };

  /*
  * gets a new short time string adjusted with a timezone
  * @param time, a date object
  * @param timezone string of the look e.g. ('america/Chicago')
  * @return date string in a short format
  */
  var getTimeWithTimezoneShort = function  (time, timezone) {
    return moment(time).tz(timezone).format('ddd, MMM DD h:mm a z');
  };

  /*
  * gets a new long formatted time string adjusted with a timezone
  * @param time, a date object
  * @param timezone string of the look e.g. ('america/Chicago')
  * @return date string in a long format
  */
  var getTimeWithTimezoneLong = function (time, timezone) {
    return moment(time).tz(timezone).format('dddd, MMMM DD, YYYY h:mm a zz');
  };

  /*
  * checks validity of a date string
  * @param dateStr, a string representation of a date
  * @return true if dateStr creates a valid date, false if it does not
  */
  var isDate = function (dateStr) {
    var d = new Date(dateStr);
    return Object.prototype.toString.call(d)
      ? !isNaN(d.getTime())
      : false;
  };

  return {
    getTimezone: getTimezone,
    getTimezoneAbbr: getTimezoneAbbr,
    getTimezoneFull: getTimezoneFull,
    getTimeWithTimezoneShort: getTimeWithTimezoneShort,
    getTimeWithTimezoneLong: getTimeWithTimezoneLong,
    isDate: isDate
  };
});
