app.controller('TasksCtrl', function ($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage, TasksList) {

  const Look = Collection('looks').Member($routeParams.lookId);

  Look.attach($scope, 'look', {
    transform: function (look) {
      return {
        jobNumber: look.jobNumber,
        schema: {
          name: look.schema.name
        },
        timezone: look.timezone
      };
    }
  });

  $scope.$watch('look', function (look) {
    $scope.Timezone = moment.tz.guess();
    $scope.currentTime =  moment().tz($scope.Timezone).toDate(); //default dueTime set to current Time
   $scope.dueTime = $scope.currentTime; 
    if (look) {
      $scope.task = {
        groupQueue: '',
        internal: true,
        type: '',
        // description: '',
        entity: {
          look: {
            _id: $routeParams.lookId,
            displayName: `${look.jobNumber} - ${look.schema.name}`
          }
        },
        dueTime: $scope.dueTime
      };
          }
  });
  
  $scope.departments = ["Account Management","CIS","Dispatch", "Document Retrieval", "Order Intake", "Quality Assurance","Vehicle Transport"];
  $scope.types = [/* "Follow-Up", */ "Order Verify", "QA", "Recruiting Needed" ];
  $scope.updateDueTime = function() {
  $scope.task.dueTime = $scope.dueTime
  }
  $scope.createTasks = function() {
    if (!$scope.taskForm.departmentInput.$valid) {
      ErrorMessage.open('Must select department.');
      $('.task-department').focus();
    } else if (!$scope.taskForm.typeInput.$valid) {
      ErrorMessage.open('Must select type.');
      $('.task-type').focus();
    } else {
      TasksList.post($scope.task).then(function(response){
        if(response.status !== 201) {
          ErrorMessage.open('Something went wrong.');
        } else {
          SuccessMessage.open(`"${$scope.task.type}" task created.`);
          SidePanel.close();
        }
      })
      .catch(err => {
        console.error(err);
        ErrorMessage.open('Something went wrong.');
      });
    }

  };
});
