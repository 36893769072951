app.factory('BatchOrder', function () {
  var batchOrder = {};

  // Build list of required fields
  batchOrder.buildRequiredFields = function buildRequiredFields(fields) {
    var aggregate = {
      required: [],
      optional: []
    };

    fields.forEach( function(field) {
      if( field.type !== "group") {
        if(field.forms.client) {
          if(field.forms.client.validations && field.forms.client.validations.required) {
            aggregate.required.push(field);
          } else {
            aggregate.optional.push(field);
          }
        }
      } else {
        var subAggregate = buildRequiredFields(field.fields);
        aggregate.required = aggregate.required.concat(subAggregate.required);
        aggregate.optional = aggregate.optional.concat(subAggregate.optional);
      }
    });

    return aggregate;
  }

  // Save file
  batchOrder.saveAs = function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      document.body.appendChild(link); // Firefox requires the link to be in the body
      link.download = filename;
      link.href = uri;
      link.click();
      document.body.removeChild(link); // remove the link when done
    } else {
      location.replace(uri);
    }
  }

  // Parse the file into an XLSX workbook object, and get it ready for processing
  batchOrder.parseBatchUpload = function parseBatchUpload(file) {
    var rABS = true; // true: readAsBinaryString ; false: readAsArrayBuffer

    function fixdata(data) {
      var o = "", l = 0, w = 10240;
      for(; l<data.byteLength/w; ++l) o+=String.fromCharCode.apply(null,new Uint8Array(data.slice(l*w,l*w+w)));
      o+=String.fromCharCode.apply(null, new Uint8Array(data.slice(l*w)));
      return o;
    }

    return new Promise( function(resolve, reject) {
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;

        var workbook;
        if(rABS) {
          /* if binary string, read with type 'binary' */
          workbook = XLSX.read(data, {type: 'binary'});
        } else {
          /* if array buffer, convert to base64 */
          var arr = fixdata(data);
          workbook = XLSX.read(btoa(arr), {type: 'base64'});
        }
        resolve(workbook);
      };

      reader.readAsBinaryString(file);
    });
  };

  // Convert workbook's first sheet of data into array of JSON objects
  batchOrder.buildLookData = function buildLookData(workbook) {
    return new Promise( function(resolve, reject) {
      // Process first sheet
      var lookArray = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      resolve(lookArray);
    });
  };

  // Validate the uploaded file to ensure it meets requirements:
  batchOrder.validateIndividualLook = function validateIndividualLook(row, index, requiredFields, deliveryOptions) {
    var ROW_INDEX = index + 2;

    for(var i = 0; i < requiredFields.length; i++ ) {
      if(!row.hasOwnProperty(requiredFields[i].id)) {
        return `Row ${ROW_INDEX}: Missing value for required field [${requiredFields[i].id}].<br />`;
      }
    }

    var validDeliveryOptions  = deliveryOptions.map(function(option) { return option.days; });
    var deliveryOptionField   =
      requiredFields.filter(function(field) {
        return _.get(field, 'forms.client.type') === 'delivery-options';
      })[0];

    if(
      requiredFields.map(function(field) { return _.get(field, 'forms.client.type'); }).indexOf("delivery-options") != -1 &&
        validDeliveryOptions.indexOf(parseInt(row[deliveryOptionField.id])) == -1
    ) {
      // If delivery_options is required, but the value isn't valid
      return `Row ${ROW_INDEX}: The value [${row[deliveryOptionField.id]}] for ${deliveryOptionField.id} is invalid. Please refer to the 'More Details' section above.<br />`;
    }

    return "";
  };

  // * Must have required headers as set in the schema
  batchOrder.validateFile = function validateFile(file, requiredFields, deliveryOptions) {
    var validationMessage = null;
    var columns = [];

    function checkCols(workbook) {
      var colValues = [];
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var cells = Object.keys(worksheet);

      // Verify that the first cell has content. If not, return an empty array
      if (!worksheet['A1']) { return []; }

      rowNum = worksheet['A1'].v.match(/^Table /) ? '2' : '1';

      for (var i = 0; i < Object.keys(cells).length; i++) {
        if( cells[i].indexOf(rowNum) > -1)
        {
          colValues.push(worksheet[cells[i]].v); //Contails all column names
        }
      }
      return colValues;
    }

    return new Promise( function(resolve, reject) {
      if(file) {
        batchOrder.parseBatchUpload(file)
          .then(function(workbook) {
            columns = checkCols(workbook);
            if(columns.length === 0) {
              throw new Error("No header row found. Be sure the first row of the file includes field IDs for this look's schema.");
            }

            return batchOrder.buildLookData(workbook);
          }).then(function(lookArray) {

            var reqFieldIds = requiredFields.map( function(field) { return field.id });

            var missingReqFields = _.difference(reqFieldIds, columns)
            if(missingReqFields.length > 0) {
              throw new Error(
                "The following fields are required for this schema, but not " +
                "found in this document: " + missingReqFields.join(", ")
              );
            }

            var errors = "";

            for( var i = 0; i < lookArray.length; i++ ) {
              errors += batchOrder.validateIndividualLook(lookArray[i], i, requiredFields, deliveryOptions);
            }

            if(errors) {
              throw new Error(errors);
            }

            resolve("");
          }).catch(function(error) {
            resolve(error.message);
          });
      } else {
        resolve(null);
      }
    });
  };

  batchOrder.validateBatchOrderErrors = function validateBatchOrderErrors(result) {
    if (result.statusCode && !result.statusCode.toString().match(/^2/)) {
      throw new Error(result.message);
    }

    return true;
  };

  return batchOrder;
});
