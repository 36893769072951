(function() {
  'use strict'

  angular
    .module("OMSAdmin")
    .directive("lookLimit", function($location){
      return {
        restrict: 'E',
        templateUrl: "/common/directives/templates/LookLimit.html",
        link: function(scope, element, attrs) {
          scope.updateLookLimit = function() {
            scope.filter.limit = scope.lookNumberLimit;
            scope.search(scope.filter);
          }
        }
      }
    });
})();
