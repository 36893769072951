(function(){
    'use strict';
   
    angular
        .module('OMSAdmin')
        .directive('formatSolicitation', function() {
   
            return {
                restrict: 'E',
                scope: {
                    'solicitation': '='
                },
                templateUrl: "/common/directives/templates/FormattedSolicitation.html",
                link: function(scope, element, attr) {
                    scope.solicitation.scheduledAt.start = scope.solicitation.scheduledAt.start;
                    scope.solicitation.scheduledAt.end = scope.solicitation.scheduledAt.end
                }
            }  
        })
   
   })();
  