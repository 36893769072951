app.controller('ClientFormCtrl', function($scope, Collection, SuccessMessage, ErrorMessage, InfoMessage, $timeout){

  var lookId = $scope.$parent.$parent.look._id;
  var Look = Collection('looks').Member(lookId);

  Look.get().then(function(look){
    $scope.lookObj = look.data;
    var clientForm = _.get($scope.lookObj, 'forms.client', {});
    _.set($scope.lookObj, 'forms.client', clientForm);
    $timeout(function(){
      $scope.$broadcast('render');
    });
  });

  var uploadFiles = function (callback) {
    var uploadCount = 0;
    var uploadDone = 0;
    InfoMessage.open('Uploading...');
    $scope.$on('uploadStarted', function () {
      uploadCount++;
      InfoMessage.open('Uploaded ' + uploadDone + '/' + uploadCount + '...');
    })
    $scope.$on('uploadDone', function () {
      uploadDone++;
      if (uploadCount == uploadDone) callback();
    })

    $scope.$broadcast('upload');

    if (uploadCount == 0) callback();
  }

  $scope.saveClient = function () {
    uploadFiles(function () {
      Look.Field('forms.client').update($scope.lookObj.forms.client).success(function () {
        SuccessMessage.open('Successfully updated Look.')
      }).error(function (data) {
        data.message = data.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(data.message);
      });
    })
  };
});
