app.controller('LookerDraftsCtrl', function($scope, Collection, SuccessMessage, ErrorMessage, Session, $timeout,$http, $routeParams, $interval, $location){

    var lookId = $routeParams.lookId;
    var Look = Collection('looks').Member(lookId);
    Look.get().then(function(look){
      $scope.isVisible = false;
      $scope.lookObj = look.data;
      $scope.lookerFormDrafts = _.get($scope.lookObj, 'drafts.looker', {});
      $timeout(function(){
        $scope.$broadcast('render');
      });
      $scope.ShowHide = function () {
        $scope.IsVisible = $scope.IsVisible ? false : true;
    }
      $scope.isFinalReviewer = function () {
        return Session.user.roles.indexOf("final reviewer") !== -1;
      }
      $scope.promoteDraft = function () {
        $http.put(`${API_ENDPOINT}/looks/${lookId}/lookerDraft`,{}).success(function () {
          SuccessMessage.open("Draft data promoted.");
          $interval(function () {
            $location.path(`/csr/${lookId}`);
          }, 1500);
          $scope.IsVisible = false;
        }).error(function (data) {
          ErrorMessage.open(`${data.error}: ${data.message}`);
        })
      }
      $scope.showPopUp = function () {
        $scope.IsVisible = true;
      }
    });
  });
