(function() {
  'use strict'

  angular
    .module('OMSAdmin')
    .filter('checkForName', function() {
      return function(input) {
        if (input.name) {
          return input.name;
        }
        return input;
      }
    })

})();
