app.filter("remainingTime", function ($filter) {
  return function (input) {
    if (!input) return '';
    var minutes = Math.ceil(input / 1000 / 60);
    var hours = Math.floor(minutes / 60);
    var timeDisplay = '';

    if (hours) {
      timeDisplay += hours + (hours === 1 ? ' hour and ' : ' hours and ');
    }

    var minutesComponent = minutes % 60;
    return timeDisplay + minutesComponent + (minutesComponent === 1 ? ' minute' : ' minutes');
  };
});
