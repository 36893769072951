/**
 * ES5 Version of Lodash's 'cond' operator. 
 * 
 * Creates a function that iterates over `pairs` and invokes the corresponding
 * function of the first predicate to return truthy. The predicate-function
 * pairs are invoked with the `this` binding and arguments of the created
 * function.
 *
 * @since 4.0.0
 * @category Util
 * @param {Array} pairs The predicate-function pairs.
 * @returns {Function} Returns the new composite function.
 */
(function() {
  'use strict'

  angular
    .module("OMSAdmin")
    .factory("cond", function() {
      
      return function(pairs) {
        var _this = this;
      
        var length = pairs == null ? 0 : pairs.length;
      
        pairs = !length ? [] : pairs.map(function (pair) {
          if (typeof pair[1] != 'function') {
            throw new TypeError('Expected a function');
          }
          return [pair[0], pair[1]];
        });
      
        return function () {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
      
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;
      
          try {
            for (var _iterator = pairs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var pair = _step.value;
      
              if (pair[0].apply(_this, args)) {
                return pair[1].apply(_this, args);
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        };
      }
    })

})();
