app.controller("OrderCtrl", function($scope, Collection, ErrorMessage, InfoMessage, SuccessMessage, $routeParams, $location){
  $scope.currentSchema = [];
  $scope.look = {
    forms: {
      client: {}
    },
    schema: {},
    company: {
      _id: $routeParams.company
    }
  };

  Collection('schemas')
    .filter({company: $routeParams.company})
    .attach($scope, 'schemas');

  $scope.selectSchema = function(schema){
    $scope.currentSchema = schema.fields;
    $scope.look.schema = schema;
    $scope.look.forms.client = _.omit($scope.look.forms.client, function(el, key) {
      return !schema.fields.find(function(prop) {
        return prop.name === key;
      })
    });

    if ($scope.look.schema.isSelfService) {
      $scope.look.forms.selfService = {};
    } else {
      $scope.look.forms.looker = {};
    }

    $scope.$broadcast('render', $scope.currentSchema);
    $scope.$broadcast('orderLookChange', $scope.look);
  };

  Collection('users')
    .Member($routeParams.user)
    .attach($scope, 'orderingUser');

  var uploadFiles = function(callback){
    var uploadCount = 0;
    var uploadDone = 0;
    InfoMessage.open('Uploading...');
    var uploadListener = $scope.$on('uploadStarted', function() {
      uploadCount++;
      InfoMessage.open('Uploaded ' + uploadCount + '/' + uploadCount + '...');
    })
    var uploadFinishListener = $scope.$on('uploadDone', function() {
      uploadDone++;
      if (uploadCount == uploadDone) {
        uploadFinishListener()
        uploadListener()
        callback();
      }
    })

    $scope.$broadcast('upload');

    if (uploadCount == 0) callback();
  };
  $scope.createOrder = function(){
    uploadFiles(function(){
      Collection('users').Member($routeParams.user).Collection('looks').create($scope.look).success(function(data){
        SuccessMessage.open('Successfully created the look.')
        $location.path('/csr/'+data._id)
      }).error(function(response){
        response.message = response.message || 'Network Error, Please Try Again...';
        ErrorMessage.open(response.message);
      });
    });
  }
});
