app.controller('FinalReviewCtrl', function($scope, Collection, $location, MomentHelper, CompaniesList, TemplateHelper,  JobNumber){

  $scope.externalStepStatuses = [
    {label: "Any", value: undefined},
    {label:"External Step Skipped", value: "Skipped"},
    {label:"External Step Required", value: "Skipped"},
    {label:"External Step In Progress", value: "In Progress"},
    {label:"External Step Complete", value: "Complete"},
  ]

  $scope.doNotSendOptions = [
    {label: 'Any', value: undefined},
    {label: 'Show Only DNS Looks', value: 'Show'},
    {label: 'Hide DNS Looks', value: 'Hide'}
  ]

  $scope.toggleFilterBar = function() {
    if ($scope.filterDrawer) {
      $scope.search($scope.filter);
    } else {
      $('#externalStep').focus();
    }
    $scope.filterDrawer = !$scope.filterDrawer;
  }

  $scope.openFilterDrawer = function(target)  {
    $scope.filterDrawer = true;
    $(target).focus();
  }

  var destoryAttachedCollection;
  $scope.filter = $location.search() || {};

  if($scope.filter.origin) delete $scope.filter.origin;
  if($scope.filter.app_guid) delete $scope.filter.app_guid;

  $scope.getLooker = function(look) {
    if (look.selfService) {
      return look.actors.selfService.name;
    }
    return _.get(look, 'actors.looker.firstName', '')  + ' ' + _.get(look, 'actors.looker.lastName', '');
  };

  $scope.getDelivered = function(look) {
    if (look.selfService) {
      return moment(look.forms.selfService.createdAt).format('M/D h:mm A');
    }
    return moment(look.forms.looker.createdAt).format('M/D h:mm A');
  };

  $scope.deliveryOptions = function(look) {
    var isRush = look.keywords && look.keywords.indexOf('rushdelivery') !== -1;

    if (isRush) {
      return 'express';
    }
    return 'standard';
  }

  $scope.dnsCheck = function(look) {
    if (look.doNotSend && look.doNotSend.reason) {
      return 'dns';
    } else {
      return $scope.deliveryOptions(look);
    }
  }

  $scope.page = function(number){
    $scope.filter.page = parseInt($scope.filter.page || 0) + number;
    $scope.search($scope.filter);
  };

  $scope.sortAscending = $scope.filter.sortDirection === 'desc' ? false : true;
  $scope.sortReverse = function() {
    $scope.sortAscending = !$scope.sortAscending;
    $scope.filter.sortDirection = $scope.sortAscending ? 'asc' : 'desc';
    $scope.search($scope.filter, true);
  };
  $scope.getPriorityColor = function(look) {
    return TemplateHelper.getPriorityColor(_.get(look, 'dueDates.report', ''));
  }
  $scope.formatJobNumber = function(jobNumber) {
    return JobNumber.format(jobNumber);
  };
  $scope.lookNumberLimit = $location.search().limit ? $location.search().limit : 50;
  $location.search().limit = $scope.lookNumberLimit;

  $scope.search = function(filter, pageReset){
    var filterCopy = angular.copy(filter) || {};

    if(filterCopy.origin) delete filterCopy.origin;
    if(filterCopy.app_guid) delete filterCopy.app_guid;

    $scope.results = undefined;

    if(!filter.status) filterCopy.status = 'Final Review';
    filterCopy.sortColumn = 'wglDue';

    if(pageReset){
      filterCopy.page = 0;
    }

    if ($scope.selectedExternalStepStatus) {
      filterCopy.externalStepStatus = $scope.selectedExternalStepStatus.value;
    }

    if($scope.selectedDoNotSendOption) {
      filterCopy.doNotSendOption = $scope.selectedDoNotSendOption.value;
    }

    if(!filterCopy.consumerOrders) {
      filterCopy.consumerOrders = undefined;
    }

    $location.search(filterCopy);

    filterCopy.fields = [
      'jobNumber',
      'company.name',
      'schema.name',
      'actors.looker.firstName',
      'actors.looker.lastName',
      'selfService',
      'forms.selfService.createdAt',
      'forms.looker.createdAt',
      'dueDates',
      'actors.selfService.name',
      'selfService',
      'notes',
      'status',
      'snooze',
      'verification',
      'orderPrep',
      'hold',
      'keywords',
      'rushdelivery',
      'solicitations',
      'doNotSend'
      ].join(',');

    if(destoryAttachedCollection) destoryAttachedCollection();
    destoryAttachedCollection = Collection('looks').filter(filterCopy).attach($scope, 'results');
  };

  $scope.search($scope.filter);

  if($location.search().externalStepStatus) {
    $scope.selectedExternalStepStatus = $scope.externalStepStatuses.find(function(status) {
      return status.value === $location.search().externalStepStatus;
    });
  } else {
    $scope.selectedExternalStepStatus = $scope.externalStepStatuses.find(function(status) {
      return status.value === undefined;
    })
  }

  if($location.search().doNotSendOption) {
    $scope.selectedDoNotSendOption = $scope.doNotSendOptions.find(function(option) {
      return option.value === $location.search().doNotSendOption;
    })
  } else {
    $scope.selectedDoNotSendOption = $scope.doNotSendOptions.find(function(option) {
      return option.value === undefined;
    })
  }

  $scope.externalStepClass = function(look) {
    var externalStepStatus = _.get(look, 'externalStep.status');

    if (externalStepStatus === 'Skipped' || externalStepStatus === 'Complete') {
      return 'external-step-complete';
    } else if (externalStepStatus === 'Required') {
      return 'external-step-required';
    } else if (externalStepStatus === 'In Progress') {
      return 'external-step-in-progress';
    }
  }

  $scope.autocompleteParentControl = {};

  CompaniesList
  .get({fields: 'name'})
  .then(function(data) {
    $scope.companies = _.map(data.data, function(company) {
      return {
        id: company._id,
        name: company.name
      }
    });
  });

  $scope.selectedCompanies = [];

  $scope.$watch('companies', function() {
    if(!_.isEmpty($scope.companies)) {
      $scope.selectedCompanies = $scope.companies.filter(function(company) {
        return $scope.filter.companies.indexOf(company.id) !== -1;
      });
    }
  });

  $scope.showCompaniesList = function(bool) {
    $scope.companiesListOpen = bool;
  }

  $scope.turnOffConsumerOrders = function() {
    $scope.filter.consumerOrders = false;
    $scope.search($scope.filter);
  }

  $scope.checkSnooze = function(look) {
    const snooze = _.get(look, "snooze");
    return (snooze && moment(snooze.expireAt).diff(moment()) > 0) ? "snoozed" : "";
  }

});

  