app.controller('CompaniesCtrl', function($scope, Collection, $location){
  var destroyAttachedCollection;
  $location.search({});
  $scope.q = '';
  $scope.fullList = [];
  $scope.results = [];

  Collection('companies').filter({limit: Number.MAX_SAFE_INTEGER}).attach($scope, 'fullList');

  $scope.$watch('fullList', (newValue) => {
    $scope.search();
  });

  $scope.search = function(){
    if ($scope.q) {
      $scope.results = $scope.fullList.filter(c => c.name.toLowerCase().includes($scope.q.toLowerCase()));
    } else {
      $scope.results = $scope.fullList;
    }
  };
})
