(function() {
  'use strict'

  angular
    .module("OMSAdmin")
    .directive("lepLogo", function() {
      return {
        restrict: "E",
        templateUrl: "/common/directives/templates/LepLogo.html",
        replace: true
      }
    });

})();
