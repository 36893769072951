(function () {
  'use strict'

  angular
    .module('OMSAdmin')
    .factory('ConversationsList', function ($http) {
      var conversations = [];
      return {
        get: function (lookId) {
          return $http.get(`${API_ENDPOINT}/looks/${encodeURIComponent(lookId)}/conversations`).then(function(response){
            
            conversations = response.data;
            return response;
          });
        }
      }
    })
})();
