app.controller('CancellationInformationCtrl', function($scope, Collection, $routeParams, SidePanel, SuccessMessage, ErrorMessage){

  var Look = Collection('looks').Member($routeParams.lookId);

  Look.get().success(function(look){
    $scope.CancellationInformation = {
      type: look.cancellation.name,
      lookerFee: look.cancellation.lookerFee / 100,
      lookPrice: look.cancellation.price / 100,
      reason: look.cancellation.reason,
      dueTo: look.cancellation.dueTo,
      explanation: look.cancellation.explanation || 'No explanation given.',
      userName: `${look.cancellation.user.firstName} ${look.cancellation.user.lastName}`,
      userEmail: look.cancellation.user.email,
      cancelDate: look.cancellation.createdAt
    };
  }).error(function(response){
    response.message = response.message || 'Network Error, Please Try Again...';
    ErrorMessage.open(response.message);
  });;
});
