app.service('SidePanel', function($http, $compile, $controller, $templateCache, $timeout, $rootScope) {
  $rootScope.SidePanelIsOpen = false;
  var templateScope;
  var templateCtrl;
  var sidePanel = angular.element('#SidePanel_Content');
  var sidePanelTitle = angular.element('#SidePanel_Title');
  var closeButton = angular.element('#SidePanel_Close');
  closeButton.on('click', function(){
    $timeout(this.close, 150);
  }.bind(this));
  $rootScope.$on('$routeChangeStart', function(event, toState, toParams, fromState, fromParams){
    this.close();
  }.bind(this));
  this.open = function(title, controller, template){
    if($rootScope.SidePanelIsOpen) this.close();
    $timeout(function(){
      $rootScope.SidePanelIsOpen = true;
      $http.get( template, { cache: $templateCache } )
      .then( function( response ) {
        sidePanelTitle.text(title);
        templateScope = $rootScope.$new();
        templateCtrl = $controller( controller, { $scope: templateScope } );
        sidePanel.html( response.data );
        sidePanel.children().data('$ngControllerController', templateCtrl);
        $compile( sidePanel.contents() )( templateScope );
      });
    }, 150);
  };
  this.close = function(){
    if($rootScope.SidePanelIsOpen) {
      $rootScope.SidePanelIsOpen = false;
      templateScope.$destroy();
    };
  };
})
