var bodyEl = $("body");
var signInEl = $(".SignIn");
var loadingEl = $("#loadingMessage");

var app = angular.module('OMSAdmin', [
  'ngAnimate',
  'ngRoute',
  'ngTable',
  'angularFileUpload',
  'angular-sortable-view',
  'ADM-dateTimePicker',
  'chart.js',
  'filearts.dragDrop',
  'checklist-model',
  'angular-jwt',
  'ngSanitize',
  'markdown'
]);

app.constant("VERSION", "1.0.0");
app.constant("API_ENDPOINT", "https://apistaging.wegolook.com");
app.constant("STATIC_ENDPOINT", "/");
app.constant("FORGOT_PASSWORD_URL", "https://apistaging.wegolook.com/password-reset");
app.constant("JWT_ALLOWED_DOMAINS", "apistaging.wegolook.com".split(/\s*,\s*/));
app.constant("ACTIVE_VIEWERS_ENDPOINT", "wss://activeviewersstaging.wegolook.com");

app.config(function ($routeProvider, $locationProvider, $compileProvider, $httpProvider, jwtOptionsProvider, JWT_ALLOWED_DOMAINS) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
  jwtOptionsProvider.config({
    tokenGetter: [
      'options', function(options) {
        var token = localStorage.getItem('stache');
        return token;
      }],
      whiteListedDomains: JWT_ALLOWED_DOMAINS
    });
  $httpProvider.defaults.withCredentials = true;

  $httpProvider.interceptors.push(function($q) {
    return {
      request: function(config) {
        var token = localStorage.getItem('stache');
        config.headers['authorization'] = `Bearer ${token}`;
        config.headers['Accept'] = 'application/json';

        return config;
      },
      responseError: function(rejection) {
        switch (rejection.status) {
            case 401:
              // Redirect to signin page
              window.location = '/login';
              break;
        }
        return $q.reject(rejection);
      }
    };
  });

  $locationProvider.html5Mode(true);

  $routeProvider
    .when('/order', {
      templateUrl: 'partial/Order/template.html',
      controller: 'OrderCtrl',
      title: 'Orders'
    })
    .when('/tasks', {
      templateUrl: 'partial/TasksPage/template.html',
      controller: 'TasksPageCtrl', 
      title: 'Tasks'
    })
    .when('/csr', {
      url: '/',
      templateUrl: 'partial/CSR/template.html',
      controller: 'CSRCtrl',
      title: 'CSR'
    })
    // CIS photo-editing route -- Added by Abhishek Agnihotri | 05/18/2021
    .when('/cis', {
      templateUrl: 'partial/CIS/template.html',
      controller: 'CISCtrl',
      title: 'CIS'
    })
    .when('/csr/:lookId', {
      templateUrl: 'partial/Look/template.html',
      controller: 'LookCtrl',
      title: 'Look'
    })
    .when('/final', {
      templateUrl: 'partial/FinalReview/template.html',
      controller: 'FinalReviewCtrl',
      title: 'Final Review'
    })
    .when('/final/:lookId', {
      templateUrl: 'partial/Look/template.html',
      controller: 'LookCtrl',
      title: 'Look'
    })
    .when('/lookers', {
      templateUrl: 'partial/Lookers/template.html',
      controller: 'LookersCtrl',
      title: 'Lookers'
    })
    .when('/clients', {
      templateUrl: 'partial/Clients/template.html',
      controller: 'ClientsCtrl',
      title: 'Clients'
    })
    .when('/companies', {
      templateUrl: 'partial/Companies/template.html',
      controller: 'CompaniesCtrl',
      title: 'Company'
    })
    .when('/companies/:companyId', {
      templateUrl: 'partial/Company/template.html',
      controller: 'CompanyCtrl',
      title: 'Company'
    })
    .when('/qa', {
      templateUrl: 'partial/QA/template.html',
      controller: 'QACtrl',
      title: 'Quality Assurance'
    })
    .when('/qa/:lookId', {
      templateUrl: 'partial/Look/template.html',
      controller: 'LookCtrl'
    })
    .when('/login', {
      templateUrl: 'app/Login/template.html',
      controller: 'LoginCtrl',
      title: 'Login',
      data: { hideNav: true }
    })
    .when('/callback', {
      templateUrl: 'app/Callback/template.html',
      controller: 'CallbackCtrl',
      title: 'Callback',
      data: { hideNav: true }
    })
    .when('/logout', {
      resolve: {
        redirect: ["AuthService", function(AuthService) {
          AuthService.logout();
        }]
      }
    })
    .when('/csr/:lookId/drafts', {
      templateUrl: 'partial/Look/LookerDrafts/template.html',
      controller: 'LookerDraftsCtrl',
      title: 'Original Draft'
    })
    .when('/qa/:lookId/drafts', {
      templateUrl: 'partial/Look/LookerDrafts/template.html',
      controller: 'LookerDraftsCtrl',
      title: 'Original Draft'
    })
    .when('/final/:lookId/drafts', {
      templateUrl: 'partial/Look/LookerDrafts/template.html',
      controller: 'LookerDraftsCtrl',
      title: 'Original Draft'
    })
    .when('/idle-page', {
      templateUrl: 'app/Idle/template.html',
      controller: 'IdleCtrl',
      title: 'Idle',
      data: { hideNav: true }
    })
    .otherwise("/csr");
});

app.run(function ($location, $rootScope, $compile, authManager, jwtHelper, AuthService, Session, DisplayMessage) {

  // START SILENT FORCE AUTH0
  // This will check for existing tokens issued by the look_api and remove them.
  // The user will then be directed to auth0 to login
  const idToken = localStorage.getItem('id_token');
  if(idToken) {
    localStorage.removeItem('id_token');
  }
  // END SILENT FORCE AITH0

  // JWT auth check
  authManager.checkAuthOnRefresh();
  AuthService.verify();

  if(AuthService.isAuthenticated) {
    AuthService.scheduleRenewal();
  }

  $rootScope.user = Session.user;

  var lastMessageSent;
  $rootScope.pageViews = {};

  $rootScope.contentBackground = function() {
    if ($location.path() === "/login") return "is-login-screen"
  }

  $rootScope.$on('$locationChangeStart', function (event, next, current) {
    if (!AuthService.isAuthenticated()) {
      if(window.location.pathname.indexOf('/callback') === -1) {
        $location.path('/login').search();
      }
    }
    else if (!AuthService.isAuthorized(['admin', 'staff', 'csr', 'god', 'read only'])) {
      $location.path('/logout');
    }

    if ($location.path() !== '/idle-page') {
      setupIdleTimeouts();
    } else {
      teardownIdleTimouts();
      setupReturnListeners();
    }
  });

  var lastPath;
  $rootScope.$on('$routeChangeSuccess', function(event, current) {
    $rootScope.title = (current.$$route && current.$$route.title) || 'The System';
  });

  $rootScope.$on("$locationChangeSuccess", function() {

    const path = $location.path();

    if (path !== lastPath) {
      lastPath = path;
    }
  });

  const IDLE_TIMEOUT_SECS = 60 * 45;
  const IDLE_WARN_TIMEOUT_SECS = 60 * 40;

  const handleIdle = () => {
    $location.url(`/idle-page`);
    window.location = $location.absUrl();
  }

  const handleIdleWarn = () => {
    DisplayMessage.open(
      {
        BGColor: '#ca4040',
      },
      $compile(`<span style=\"color:#fff\">You will be redirected to an idle page in <strong>{{idleWarnTimeDisplay}}</strong> due to inactivity</span>`)($rootScope)
    )
  };

  const restartIdle = _.debounce(handleIdle, IDLE_TIMEOUT_SECS * 1000);
  const restartWarn = _.debounce(handleIdleWarn, IDLE_WARN_TIMEOUT_SECS * 1000);

  clearIdleTimeouts = () => {
    restartIdle.cancel();
    restartWarn.cancel();
  }

  let idleStart;
  const resetIdleTimer = () => {
    idleStart = moment();
    restartIdle();
    restartWarn();
  }

  // update countdown
  setInterval(() => {
    const duration = moment.duration(moment(idleStart).add(IDLE_TIMEOUT_SECS, 'seconds').diff());
    $rootScope.idleWarnTimeDisplay = `${duration.minutes()}:${`0${duration.seconds()}`.slice(-2)}`;

    if ($rootScope.MessageIsOpen) {
      $rootScope.$apply();
    }
  }, 1000);

  setupIdleTimeouts = () => {
    teardownIdleTimouts();
    document.addEventListener('mousedown', resetIdleTimer);
    document.addEventListener('keydown', resetIdleTimer);
    resetIdleTimer();
  }

  teardownIdleTimouts = () => {
    clearIdleTimeouts();
    document.removeEventListener('mousedown', resetIdleTimer);
    document.removeEventListener('keydown', resetIdleTimer);
  }

  setupReturnListeners = () => {
    const goBack = () => {
      history.back();
      document.removeEventListener('mousemove', goBack);
      document.removeEventListener('mousedown', goBack);
      document.removeEventListener('keydown', goBack);
      window.removeEventListener('focus', goBack);
    };
    const start = new Date();

    document.addEventListener('mousemove', goBack);
    document.addEventListener('mousedown', goBack);
    document.addEventListener('keydown', goBack);
    window.addEventListener('focus', goBack);
  }

  setupIdleTimeouts();
});
