(function(){
  'use strict';
 
  angular
      .module('OMSAdmin')
      .directive('formatScheduleAttempt', function() {
          return {
              restrict: 'E',
              scope: {
                'conversation': '=',
                'look': '='
              },
              templateUrl: "/common/directives/templates/FormattedScheduleAttempt.html",
              link: function(scope, element, attr) {
                scope.transcripts = _.get(scope, "conversation.conversation");
                scope.looker = _.get(scope, "conversation.user");
                scope.lookerInitials = `${scope.looker.firstName.substring(0, 1).toUpperCase()}${scope.looker.lastName.substring(0,1).toUpperCase()}`;

                scope.lookerIsCurrent = () => _.get(scope,"looker._id") === _.get(scope, "look.actors.looker._id");
              }
          }  
      })
 
 })();
