app.controller('LookerInstructionsCtrl', function($scope, Collection, SuccessMessage, ErrorMessage, $timeout, SchemaHelper){

  var lookId = $scope.$parent.$parent.look._id;
  var Look = Collection('looks').Member(lookId);

  Look.get().then(function(look){
    $scope.lookerInstructions = SchemaHelper.pluckType(look.data.schema.fields, "instructions", 'looker');
  });

  $scope.saveInstructions = function () {
    Look.Field('schema.instructions').update({
      instructions: $scope.lookerInstructions.forms.looker.content
    }).success(function (data) {
      SuccessMessage.open('Successfully updated Instructions.');
      $scope.look = data;
    }).error(function (data) {
      data.message = data.message || 'Network Error, Please Try Again...';
      ErrorMessage.open(data.message);
    });
  };

});
