app.service('Schemas', function($http, API_ENDPOINT){
  this.list = function(companyId, callback){
    $http.get(API_ENDPOINT+'schemas?company='+companyId, { cache: true }).success(function(data){
      callback(data);
    });
  };
  this.get = function(type, callback){
    this.list(function(schemas){
      callback(_.findWhere(schemas, {type:type}))
    });
  }
})
