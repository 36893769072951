app.directive('wglTableNote', function() {
  return {
    restrict: 'AE',
    scope: {
      note: '='
    },
    templateUrl: '/app/CSR/WglTableNoteTemplate.html',
    link: function (scope, element, attrs) {

      attrs.$observe('note', function(changedValue){
        if (!scope.note) return;
        scope.body = _.get(scope.note, 'body', '');
        scope.date = date(scope.note);
        scope.initials = initials(scope.note);
      });

      function date(note) {
        return !note ? '' : note.createdAt;
      };

      function initials(note) {
        if (!note) {
          return '';
        }
        var first = _.chain(note).get('user.firstName').head().value() || '';
        var last = _.chain(note).get('user.lastName').head().value() || '';

        return `${first}${last}`;
      };
    }
  };
});
